import { getNow } from "../../common/utils/date";
import { fetchAPIWithID } from "../config";

export async function getPurchases(id, family) {
  let userInfo = family;
  let req = {};
  req.Pss = [];
  req.Guids = [];

  let purchases = [];
  purchases.push(userInfo.PersonInfo.Person);
  for (var i in userInfo.RelaInfos) {
    purchases.push(userInfo.RelaInfos[i].Person);
  }

  for (let i in purchases) {
    req.Guids.push(purchases[i].PId);
  }

  req.Type = 2;
  req.SDay = "01/01/1970";
  req.EDay = getNow();
  req.Side = 2;
  req.OrgId = id;

  const data = await fetchAPIWithID("/ssm_api_store/getmypurchases", req);
  return data;
}

export const getInvoiceDetail = async (invoiceid, orgid) => {
  let req = {};
  req.Guid = orgid;
  req.Type = 0;
  req.Id = invoiceid;

  const data = await fetchAPIWithID("/ssm_api_store/getinvoicedetail", req);
  return data;
};
