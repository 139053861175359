import React from "react";
import Spinner from "../../../../common/components/Spinner";
import ssm_valid from "../../../../common/utils/ssm_valid";
import ErrorMsg from "../../../../common/components/ErrorMsg";
import Constant from "../../../../common/constants/define";
import { updateUserPassword } from "../../../../services/account/updateUserPassword";

class EditPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: this.props.person,
      passinfo: {},
      errormsg: "",
      errorflag: 0,
      loading: false,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
  }

  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("confirmpwd");
    this.requireds.push("oldpwd");
    this.requireds.push("newpwd");
  }

  handleErrOkay() {
    if (this.state.errorflag == 1) this.setState({ errormsg: "" });
    else this.props.onBackClick();
  }

  handleBackClick() {
    this.props.onBackClick();
  }

  handleSaveClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.passinfo,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    this.update_userpassword_m();
  }

  async update_userpassword_m() {
    const response = await updateUserPassword(
      this.state.person,
      this.state.passinfo.oldpwd,
      this.state.passinfo.newpwd
    );
    if (response.Id == 0) {
      this.setState({ loading: false });
      this.props.onBackClick();
    } else {
      this.setState({
        loading: false,
        errormsg: "Old password is not correct.",
        errorflag: 1,
      });
    }
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (this.state.passinfo.newpwd !== undefined) {
      if (name === "confirmpwd") {
        if (this.state.passinfo.newpwd !== val) {
          this.errors["confirmpwd"] = "Password does not match";
        } else {
          this.errors["confirmpwd"] = "";
        }
      }
    }

    this.setState((prevState) => ({
      passinfo: {
        ...prevState.passinfo,
        [name]: val,
      },
    }));
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg
            msg={this.state.errormsg}
            flag={this.state.errorflag}
            onErrOkay={this.handleErrOkay}
          />
        ) : (
          <div className="content-area">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="admin-personal-info"
                        role="tabpanel"
                        aria-labelledby="admin-pinfo-tab"
                      >
                        <div id="admin-profile-header" className="row">
                          <div className="col-12">
                            <img
                              width="64"
                              src="img/member-pp.svg"
                              alt="Profile picture"
                            />
                            <p
                              id="family-member-name"
                              className="family-member-pname"
                            >
                              {this.state.person.FName +
                                " " +
                                this.state.person.LName}
                            </p>
                          </div>
                        </div>
                        <div id="edit-admin-pinfo" className="row">
                          <div className="col-12">
                            <form>
                              <p className="info-label">Old Password</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="password"
                                name="oldpwd"
                                maxLength={18}
                                onChange={this.handleChange}
                              />
                              {ssm_valid.showerror("oldpwd", this.errors)}
                              <p className="info-label">New Password</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="password"
                                name="newpwd"
                                onChange={this.handleChange}
                                maxLength={18}
                              />
                              {ssm_valid.showerror("newpwd", this.errors)}
                              <p className="info-label">Confirm new Password</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="password"
                                name="confirmpwd"
                                maxLength={18}
                                onChange={this.handleChange}
                              />
                              {ssm_valid.showerror("confirmpwd", this.errors)}
                              <div className="form-actions-area">
                                <a>
                                  <button
                                    onClick={this.handleSaveClick}
                                    className="filled-button-continue"
                                    type="button"
                                  >
                                    Save
                                  </button>
                                </a>
                                <a>
                                  <button
                                    onClick={this.handleBackClick}
                                    className="border-button"
                                    type="button"
                                  >
                                    Cancel
                                  </button>
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default EditPassword;