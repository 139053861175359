import React from "react";
import PersonDetail from "./person_detail";

class MyFamily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      family: this.props.family,
      isDetail: false,
      member: {},
      isFamily: true,
    };

    this.handleMemberClick = this.handleMemberClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  handleMemberClick(person) {
    this.setState({ isDetail: true, member: person });
  }
  handleBackClick() {
    this.setState({ isDetail: false });
  }

  getMyFamilyMem(person) {
    return (
      <a
        onClick={() => {
          this.handleMemberClick(person);
        }}
      >
        <div className="card d-flex flex-row mb-2 p-2">
          <div className="col-10">{person.FName + " " + person.LName}</div>
          <div className="col-2 ta-right">
            <img width="24" src="img/arrow-right-icon.svg" alt="see more" />
          </div>
        </div>
      </a>
    );
  }

  getMyFamily(family) {
    const items = [];
    items.push(this.getMyFamilyMem(family.PersonInfo.Person));
    for (let i = 0; i < family.RelaInfos.length; i++) {
      items.push(this.getMyFamilyMem(family.RelaInfos[i].Person));
    }

    return <div>{items}</div>;
  }

  render() {
    return (
      <div>
        {this.state.isDetail == true ? (
          <PersonDetail
            person={this.state.member}
            onBackClick={this.handleBackClick}
            onLogout={this.props.onLogout}
            family={this.state.family}
            isFamily={this.state.isFamily}
          />
        ) : (
          <div className="content-area">
            <section id="my-family">
              <div className="container">
                {this.getMyFamily(this.state.family)}
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default MyFamily;
