import React from "react";
import Constant from "../../../common/constants/define";
import Spinner from "../../../common/components/Spinner";
import MyStuff_roster from "./mystuff_roster";
import MyStuff_standing from "./mystuff_standing";
import MyStuff_sche from "./mystuff_schedule";
import SSCalendar from "../../../common/components/SSCalendar";
import BackTitle from "../../../common/components/back/BackTitle";
import { getRegmanObject } from "../../../services/activities/getRegmanObject";
import { getMyPerson } from "../../../common/utils/family";
import { getFces, getTeamFce } from "../../../common/utils/node";

class MyStuffItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      node: this.props.node,
      family: this.props.family,
      fces: [],
      ofces: [],
      mypid: this.props.mypid,
      loading: false,
      tabPos: 1,
      myregmanobj: {},
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
  }

  componentWillMount() {
    this.getMyRegManObj();
  }

  handleEventClick(e) {}

  async getMyRegManObj() {
    this.setState({ loading: true });

    const response = await getRegmanObject(
      this.state.node.Act.AId,
      this.state.node.Act.AType
    );

    if (response.Id == 0) {
      let obj = JSON.parse(response.Data);
      let c_RegManObj = obj.rt;
      let sches = obj.sches;

      this.setState({
        myregmanobj: c_RegManObj,
        fces: getFces(sches),
        ofces: sches,
        loading: false,
      });
    }
  }

  handleBackClick(node) {
    this.props.onBackClick(node);
  }
  handleTabClick(pos) {
    this.setState({ tabPos: pos });
  }

  render() {
    let lnode = this.state.node.Act;
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div
            className={`content-area ${!this.props.isDesktop && "header-top-80"}`}
          >
            <div className="row pb-1 pt-1 mb-4">
              <div className="col-12">
                <BackTitle
                  title={lnode.Name}
                  onBack={() => {
                    this.handleBackClick(this.state.node);
                  }}
                />
              </div>
            </div>

            <div id="home-session-tabs">
              <div
                className="container-fluid"
                style={{ marginTop: this.state.height + 5 }}
              >
                <div className="teamtitle">
                  Name:{" "}
                  {getMyPerson(this.state.mypid, this.state.family).FName +
                    " " +
                    getMyPerson(this.state.mypid, this.state.family).LName}
                </div>
                {this.state.node.Act.AType === Constant.TP_SPORT && (
                  <div className="teamtitle">
                    Team: {this.state.node.Group?.Name}
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <ul
                      className="nav nav-pills nav-justified"
                      id="myTab"
                      role="tablist"
                    >
                      {this.state.ofces.length > 0 &&
                      this.state.ofces[0].App === Constant.APP_PUBED ? (
                        <li className="nav-item">
                          <a
                            className="nav-link active rounded-circle mr-2"
                            onClick={() => {
                              this.handleTabClick(1);
                            }}
                            id="info-tab"
                            data-toggle="tab"
                            href="#schedule-info-tab"
                            role="tab"
                            aria-controls="info"
                            aria-selected="true"
                          >
                            Schedule
                          </a>
                        </li>
                      ) : null}

                      {this.state.ofces.length > 0 &&
                      this.state.ofces[0].App === Constant.APP_PUBED ? (
                        <li className="nav-item">
                          <a
                            className="nav-link rounded-circle mr-2"
                            onClick={() => {
                              this.handleTabClick(2);
                            }}
                            id="info-tab"
                            data-toggle="tab"
                            href="#calendar-info-tab"
                            role="tab"
                            aria-controls="info"
                            aria-selected="true"
                          >
                            Calendar
                          </a>
                        </li>
                      ) : null}
                      <li className="nav-item">
                        <a
                          className={`nav-link rounded-circle mr-2 ${
                            this.state.ofces.length > 0 &&
                            this.state.ofces[0].App === Constant.APP_PUBED
                              ? ""
                              : "active"
                          }`}
                          onClick={() => {
                            this.handleTabClick(4);
                          }}
                          id="participant-tab"
                          data-toggle="tab"
                          href="#roster-info-tab"
                          role="tab"
                          aria-controls="participant"
                          aria-selected="false"
                        >
                          Roster
                        </a>
                      </li>
                      {lnode.AType == Constant.TP_SPORT && (
                        <li className="nav-item">
                          <a
                            className="nav-link rounded-circle"
                            onClick={() => {
                              this.handleTabClick(3);
                            }}
                            id="schedule-tab"
                            data-toggle="tab"
                            href="#standing-info-tab"
                            role="tab"
                            aria-controls="schedule"
                            aria-selected="false"
                          >
                            Standings
                          </a>
                        </li>
                      )}
                    </ul>
                    <div className="tab-content tab-0 mb-3" id="myTabContent">
                      {this.state.ofces.length > 0 &&
                      this.state.ofces[0].App === Constant.APP_PUBED ? (
                        <div
                          className="tab-pane fade show active"
                          id="schedule-info-tab"
                          role="tabpanel"
                          aria-labelledby="info-tab"
                        >
                          <MyStuff_sche
                            node={this.state.node}
                            ofces={this.state.ofces}
                            oneventclick={this.handleEventClick}
                          />
                        </div>
                      ) : (
                        <div
                          className="tab-pane fade show active"
                          id="roster-info-tab"
                          role="tabpanel"
                          aria-labelledby="participant-tab"
                        >
                          <MyStuff_roster
                            node={this.state.node}
                            mypid={this.state.mypid}
                            orginfo={this.props.orginfo}
                          />
                        </div>
                      )}

                      <div
                        id="calendar-info-tab"
                        role="tabpanel"
                        aria-labelledby="info-tab"
                      >
                        {this.state.tabPos === 2 && (
                          <SSCalendar
                            fces={getTeamFce(this.state.ofces, this.state.node)}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="standing-info-tab"
                        role="tabpanel"
                        aria-labelledby="schedule-tab"
                      >
                        {this.state.tabPos === 3 && (
                          <MyStuff_standing
                            node={this.state.node}
                            myregmanobj={this.state.myregmanobj}
                          />
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="roster-info-tab"
                        role="tabpanel"
                        aria-labelledby="participant-tab"
                      >
                        {this.state.tabPos === 4 && (
                          <MyStuff_roster
                            node={this.state.node}
                            mypid={this.state.mypid}
                            orginfo={this.props.orginfo}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MyStuffItem;