import React from "react";
import { ssm_getcookie } from "../../../common/utils/ssm_util";
import Spinner from "../../../common/components/Spinner";
import {
  getInvoiceDetail,
  getPurchases,
} from "../../../services/account/getPurchases";
import { getDateByValue } from "../../../common/utils/date";
import { parseTotal } from "../../../common/utils/parse";
import { htmlToText } from "../../../common/utils/utils";

class MyPurchases extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pcs: [] };
  }

  orgid = 0;

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
    this.getMyPurchases();
  }

  getInvoice2(pc) {
    return (
      <div className="card row p-2 mb-2">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <p id="purchase-date" className="purchase-date-label">
                {getDateByValue(pc.CTime)}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-10 purchased-class-container">
              <p className="cart-item-name in-block">
                <span id="activity">{pc.Desc.replace(/<[^>]+>/g, "")}</span>
              </p>
              <p className="cart-item-info">
                <span id="class-type">Invoice # - {pc.INVId}</span>
              </p>
            </div>
          </div>
          <div className="row">
            <p className="col-6 ta-left purchased-amount">
              TOTAL: <span id="purchase-amount">${parseTotal(pc.Amt)}</span>
            </p>
            <div className="col-6 ta-right mt-1">
              <button
                class="btn mb-1"
                data-toggle="collapse"
                data-target={`#${pc.INVId}`}
                aria-expanded="false"
                aria-controls={`${pc.INVId}`}
              >
                Detail
              </button>
            </div>
          </div>
          <div className="collapse" id={`${pc.INVId}`}>
            {pc.Items.map((item, index) => {
              return (
                <div key={index} className="items-bg mt-2 p-2 rounded">
                  <div id="cart-item" className="row pl-2">
                    <div className="col-10">
                      <p className="cart-item-name">
                        {item.Type === 1
                          ? "Registration"
                          : htmlToText(item.Desc)}
                      </p>
                    </div>
                  </div>
                  <div id="item-total" className="row pl-2">
                    <div className="col-3">
                      <span className="pre-total-label">Rate</span>
                      <br />
                      <span className="pre-total-amount">
                        ${parseTotal(item.Rate)}
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="pre-total-label">Subtot.</span>
                      <br />
                      <span className="pre-total-amount">
                        ${parseTotal(item.Count * item.Rate)}
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="pre-total-label">Disc.</span>
                      <br />
                      <span className="pre-total-disc">
                        ${parseTotal(item.Disc)}
                      </span>
                    </div>
                    <div className="col-3">
                      <br />
                      <span className="item-total-list">
                        ${parseTotal(item.Amt)}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  getInvoices2() {
    const items = [];
    const newest = this.state.pcs.sort((a, b) => {
      return new Date(b.CTime) - new Date(a.CTime);
    });
    for (let i = 0; i < newest.length; i++) {
      items.push(this.getInvoice2(newest[i]));
    }
    return <div>{items}</div>;
  }

  async getMyPurchases() {
    this.setState({ loading: true });
    const response = await getPurchases(this.orgid, this.props.family);
    if (response.Id == 0) {
      let mypurs = JSON.parse(response.Data);

      for (let i = 0; i < mypurs.length; i++) {
        let inv = await this.getInvoiceDetail(mypurs[i].INVId);
        inv.Items = inv.Items.filter((item) => item.Type === 1 || item.Type === 2 || item.Type === 3);
        mypurs[i].Items = inv.Items;
      }

      this.setState({ pcs: mypurs, loading: false });
    } else if (response.Id == 5) {
      let err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    }
  }

  async getInvoiceDetail(invoiceid) {
    const response = await getInvoiceDetail(invoiceid, this.orgid);
    if (response.Id == 0) {
      let inv = JSON.parse(response.Data);
      return inv.Cart;
    } else if (response.Id == 5) {
      let err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? <Spinner /> : <div>{this.getInvoices2()}</div>}
      </div>
    );
  }
}

export default MyPurchases;
