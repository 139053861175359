import { fetchAPI } from "../config";

export async function resetPassword(newPassword, sid) {
  let req = {};
  req.Str = newPassword;
  req.Type = 0;

  let jsonstr = JSON.stringify(req);
  jsonstr = new Buffer(jsonstr).toString("base64");
  const para = { jsonstr, sid };
  const data = await fetchAPI("/ssm_api_user/resetpassword", para);
  return data;
}
