import React, { useEffect, useState } from "react";
import "./style.css";
import {
  AccountCircle,
  ShoppingCart,
  Login,
  AppRegistration,
} from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";
import { useLocation, Link } from "react-router-dom";

const Header = ({ logo, logined, center }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 850px)",
  });

  const [currentTab, setCurrentTab] = useState("");

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setCurrentTab("home");
        break;
      case "/myhome":
        setCurrentTab("activities");
        break;
      case "/store":
        setCurrentTab("cart");
        break;
      case "/myaccount":
        setCurrentTab("profile");
        break;
      case "/contact":
        setCurrentTab("contact");
        break;
      case "/login":
        setCurrentTab("login");
        break;
      case "/registeraccount":
        setCurrentTab("register");
        break;
      default:
        setCurrentTab("/");
    }
  }, [location.pathname]);

  let imageUrl = "https://s3-us-west-2.amazonaws.com/sportsites-filer";
  return isDesktopOrLaptop ? (
    <div className="fixed-top">
      <div className="header-bg">
        <div className="container">
          <img className="header-logo-web" src={imageUrl + logo} alt="Logo" />
        </div>
      </div>
      <nav className="navbar navbar-light navbar-height bg-light">
        <div className="container navbar-height">
          <div className="navbar-nav d-flex flex-row align-items-center navbar-height">
            <Link
              className={`d-flex flex-row mr-2 navbar-height ${
                currentTab === "home" ? "header-item-active" : "header-item"
              }`}
              to="/"
            >
              <span
                className={`header-menu-item-text align-self-center ${
                  currentTab === "home" && "header-text-active"
                }`}
                onClick={() => {
                  setCurrentTab("home");
                }}
              >
                Home
              </span>
            </Link>
            {logined == 1 && (
              <Link
                className={`d-flex flex-row mr-2 navbar-height ${
                  currentTab === "activities"
                    ? "header-item-active "
                    : "header-item"
                }`}
                to="/myhome"
              >
                <span
                  className={`header-menu-item-text align-self-center ${
                    currentTab === "activities" && "header-text-active"
                  }`}
                  onClick={() => {
                    setCurrentTab("activities");
                  }}
                >
                  Activities
                </span>
              </Link>
            )}
            <Link
              className={`d-flex flex-row mr-2 navbar-height ${
                currentTab === "contact" ? "header-item-active" : "header-item"
              }`}
              to="/contact"
            >
              <span
                className={`header-menu-item-text align-self-center ${
                  currentTab === "contact" && "header-text-active"
                }`}
                onClick={() => {
                  setCurrentTab("contact");
                }}
              >
                Contact us
              </span>
            </Link>
          </div>

          {logined == 1 ? (
            <div className="d-flex flex-row align-items-center navbar-height">
              <Link
                className={`d-flex flex-row mr-2 navbar-height ${
                  currentTab === "cart" ? "header-item-active" : "header-item"
                }`}
                to="/store"
              >
                <div
                  className="d-flex align-self-center"
                  onClick={() => {
                    setCurrentTab("cart");
                  }}
                >
                  <ShoppingCart
                    htmlColor="#292929"
                    className="mr-1 align-self-center"
                    sx={{ fontSize: 18 }}
                  />
                  <span
                    className={`header-menu-item-text mt-1 align-self-center ${
                      currentTab === "cart" && "header-text-active"
                    }`}
                  >
                    Shopping Cart
                  </span>
                </div>
              </Link>
              <Link
                className={`d-flex flex-row navbar-height ${
                  currentTab === "profile"
                    ? "header-item-active "
                    : "header-item"
                }`}
                to="/myaccount"
              >
                <div
                  className="d-flex align-self-center"
                  onClick={() => {
                    setCurrentTab("profile");
                  }}
                >
                  <AccountCircle
                    htmlColor="#292929"
                    className="mr-1 align-self-center"
                    sx={{ fontSize: 18 }}
                  />
                  <span
                    className={`header-menu-item-text mt-1 align-self-center ${
                      currentTab === "profile" && "header-text-active"
                    }`}
                  >
                    Profile
                  </span>
                </div>
              </Link>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center navbar-height">
              <Link
                className={`d-flex flex-row mr-2 navbar-height ${
                  currentTab === "register"
                    ? "header-item-active"
                    : "header-item"
                }`}
                to="/registeraccount"
              >
                <div
                  className="d-flex align-self-center"
                  onClick={() => {
                    setCurrentTab("register");
                  }}
                >
                  <AppRegistration
                    htmlColor="#292929"
                    className="mr-1 align-self-center"
                    sx={{ fontSize: 18 }}
                  />
                  <span
                    className={`header-menu-item-text mt-1 align-self-center ${
                      currentTab === "register" && "header-text-active"
                    }`}
                  >
                    Register
                  </span>
                </div>
              </Link>
              <Link
                className={`d-flex flex-row navbar-height ${
                  currentTab === "login" ? "header-item-active" : "header-item"
                }`}
                to="/login"
              >
                <div
                  className="d-flex align-self-center"
                  onClick={() => {
                    setCurrentTab("login");
                  }}
                >
                  <Login
                    htmlColor="#292929"
                    className="mr-1 align-self-center"
                    sx={{ fontSize: 18 }}
                  />
                  <span
                    className={`header-menu-item-text mt-1 align-self-center ${
                      currentTab === "login" && "header-text-active"
                    }`}
                  >
                    Login
                  </span>
                </div>
              </Link>
            </div>
          )}
        </div>
      </nav>
    </div>
  ) : (
    <div
      className={`d-flex ${
        center && logined !== 1
          ? "justify-content-center"
          : "justify-content-between"
      } align-items-center fixed-top header border-bottom`}
    >
      <div className="d-flex align-items-start flex-column">
        {logo ? (
          <img className="logo" src={imageUrl + logo} alt="Logo" />
        ) : (
          <div className="empty-logo" />
        )}
      </div>
      {logined == 1 && (
        <Link
          className="mt-2"
          to="/myaccount"
        >
          <AccountCircle htmlColor="#292929" fontSize="large" />
        </Link>
      )}
    </div>
  );
};

export default Header;
