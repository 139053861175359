import { fetchAPIWithID } from "../config";

export async function getRosters(GId, AId, AType) {
  let req = {};
  req.Guid = GId;
  req.Guid2 = AId;
  req.Type = AType;
  const data = await fetchAPIWithID("/ssm_api_user/getmyroster", req);
  let rosters = JSON.parse(data.Data);
  return rosters;
}
