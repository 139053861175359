import { fetchAPIWithID } from "../config";

export async function checkRegister(id, oid) {
  let req = {};
  req.Guid = oid;
  req.Type = id;
  const data = await fetchAPIWithID("/ssm_api_node/checkreged", req);
  return data;
}

export async function checkMaxTeamMembers(id) {
  let req = {};
  req.Guid = id;
  req.Type = 0;
  const data = await fetchAPIWithID("/ssm_api_node/checkteammem", req);
  return data;
}
