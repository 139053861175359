import React from "react";
import Spinner from "../../common/components/Spinner";
import CartList from "./cart_list";
import CCPay from "./ccpay";
import EmptySC from "./empty";
import Constant from "../../common/constants/define";
import ssm_valid from "../../common/utils/ssm_valid";
import ErrorMsg from "../../common/components/ErrorMsg";
import { ssm_getcookie } from "../../common/utils/ssm_util";
import { useCode } from "../../services/store/useCode";
import { finishCart } from "../../services/store/finishCart";
import {
  parseAmount,
  parseFloatAmount,
  parseTotal,
} from "../../common/utils/parse";
import { getCurrentDate } from "../../common/utils/date";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../common/components/modal";
import { deleteStoreItem } from "../../services/store/deleteStoreItem";

class Store extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pcds: this.props.pcds,
      errormsg: "",
      errorflag: 0,
      store: {},
      coupon: "",
      loading: true,
      toGo: 0,
      activesUsers: [],
      mainpcds: [],
      showEmpty: false,
      modal: false,
      selectedItem: null,
    };

    this.handleCheckoutClick = this.handleCheckoutClick.bind(this);
    this.handlePayClick = this.handlePayClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
    this.handleErr = this.handleErr.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleApplyCodeClick = this.handleApplyCodeClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpCountClick = this.handleUpCountClick.bind(this);
    this.handleStoreData = this.handleStoreData.bind(this);
    this.confirm = this.confirm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  orgid = 0;
  requireds = [];
  errors = {};

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  handleStoreData() {
    let usersID = [];
    let storePcds = [];
    this.setState({ pcds: this.props.pcds });
  
    if (this.props.family.RelaInfos?.length > 0) {
      for (const relaInfo of this.props.family.RelaInfos) {
        usersID.push(relaInfo.Person.PId);
      }
    }
  
    usersID.push(this.props.family.PersonInfo.Person.PId);
    this.setState({ activesUsers: usersID });
  
    if (this.state.pcds.length > 0) {
      let map = {};
      for (const userID of usersID) {
        map[userID] = true;
      }
  
      for (const pcd of this.state.pcds) {
        if (map[pcd.PId] === true) {
          storePcds.push(pcd);
        }
      }
  
      this.dealunpays(storePcds);
    }
  
    const currentPcds = this.props.pcds
      .filter(propPcd => propPcd.Type === 6)
      .map(currentPcd => {
        currentPcd.Items = this.props.pcds
          .filter(propPcd => currentPcd.PCDId === propPcd.PPCDId && (propPcd.Type === 1 || propPcd.Type === 2 || propPcd.Type === 3));
        return currentPcd;
      });
  
    this.setState({ mainpcds: currentPcds });
  }

  componentDidMount() {
    this.handleStoreData();
    if (document.hasFocus()) {
      this.props.onRefresh();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mainpcds !== this.state.mainpcds) {
      this.dealunpays(this.props.pcds);
      if (this.state.mainpcds.length === 0) {
        this.setState({ showEmpty: true, loading: false });
      }
    }

    if (prevProps.pcds !== this.props.pcds) {
      this.handleStoreData();
    }
  }

  handleUpCountClick(pcdid, count, type, selcnt, newpcds) {
    this.props.onUpCountClick(pcdid, count, type, selcnt, newpcds);
  }

  clr_Item_m(citem) {
    citem.Disc = 0;
    citem.Tax = 0;
    citem.Amt = 0;
    citem.DiscType = 0;
    citem.DiscId = Constant.SSM_ROOTID;
    citem.DiscName = "";
    citem.Code = "";
  }

  f_Pcds_m2(unpays, ppcdid) {
    var pcds = [];
    for (var i in unpays) {
      if (ppcdid == unpays[i].PPCDId) {
        pcds.push(unpays[i]);
      }
    }
    return pcds;
  }

  f_Pcd_m2(cart, ppcdid) {
    for (var i in cart.Items) {
      if (ppcdid == cart.Items[i].PCDId) return cart.Items[i];
    }
    return null;
  }

  f_Pcds_m(cart, ppcdid) {
    var pcds = [];
    for (var i in cart.Items) {
      if (ppcdid == cart.Items[i].PPCDId) {
        pcds.push(cart.Items[i]);
      }
    }
    return pcds;
  }

  cal_SubMoney_m(citem) {
    var c_Cart = this.state.store;

    var pcd = this.f_Pcd_m2(c_Cart, citem.PPCDId);
    this.clr_Item_m(pcd);

    var pcds = this.f_Pcds_m(c_Cart, citem.PPCDId);
    for (var i = 0; i < pcds.length; i++) {
      pcd.Disc += parseFloatAmount(pcds[i].Disc);
      pcd.Tax += parseFloatAmount(pcds[i].Tax);
      pcd.Amt += parseFloatAmount(pcds[i].Amt);
    }
    pcd.Amt = parseFloatAmount(pcd.Amt);
    pcd.Tax = parseFloatAmount(pcd.Tax);
    pcd.Disc = parseFloatAmount(pcd.Disc);
    for (var i = 0; i < pcds.length; i++) {
      if (pcds[i].Disc > 0) {
        pcd.DiscType = pcds[i].DiscType;
        pcd.DiscId = pcds[i].DiscId;
        pcd.DiscName = pcds[i].DiscName;
        pcd.Code = pcds[i].Code;
        break;
      }
    }
  }

  cal_SubMoney_m3(unpays, citem) {
    this.clr_Item_m(citem);

    var pcds = this.f_Pcds_m2(unpays, citem.PCDId);
    for (var i = 0; i < pcds.length; i++) {
      citem.Disc += parseFloatAmount(pcds[i].Disc);
      citem.Tax += parseFloatAmount(pcds[i].Tax);
      citem.Amt += parseFloatAmount(pcds[i].Amt);
    }
    for (var i = 0; i < pcds.length; i++) {
      if (pcds[i].Disc > 0) {
        citem.DiscType = pcds[i].DiscType;
        citem.DiscId = pcds[i].DiscId;
        citem.DiscName = pcds[i].DiscName;
        citem.Code = pcds[i].Code;
        break;
      }
    }
  }

  ex_OIds2(oid, oids) {
    for (var i in oids) {
      if (oid == oids[i].TaxId) return oids[i];
    }
    return null;
  }

  cal_AllMoney_m(cart, code, type) {
    cart.Price = 0;
    cart.Disc = 0;
    cart.Tax = 0;
    cart.Amt = 0;
    cart.Code = "";
    for (var i in cart.Items) {
      if (cart.Items[i].Type == Constant.PCD_TOT) {
        cart.Price += parseFloatAmount(
          cart.Items[i].Rate * cart.Items[i].Count
        );
        cart.Disc += parseFloatAmount(cart.Items[i].Disc);
        cart.Amt += cart.Items[i].Amt;
      }
    }
    cart.Pcts = [];
    for (var i in cart.Items) {
      if (cart.Items[i].Type == Constant.PCD_TOT) {
        for (var j in cart.Items[i].Pcdts) {
          var pct = this.ex_OIds2(cart.Items[i].Pcdts[j].TaxId, cart.Pcts);
          if (pct == null) {
            var obj = new Object();
            obj.Amt = cart.Items[i].Amt;
            obj.Paid = 0;
            obj.TaxId = cart.Items[i].Pcdts[j].TaxId;
            obj.TaxName = cart.Items[i].Pcdts[j].TaxName;
            obj.TaxRate = cart.Items[i].Pcdts[j].TaxRate;
            obj.Tax = 0;
            obj.PaidTax = 0;

            cart.Pcts.push(obj);
          } else {
            pct.Amt += cart.Items[i].Amt;
          }
        }
      }
    }
    for (var i in cart.Pcts) {
      cart.Pcts[i].Tax = parseFloatAmount(
        (cart.Pcts[i].Amt * cart.Pcts[i].TaxRate) / 100
      );
      cart.Tax += cart.Pcts[i].Tax;
    }

    cart.Amt = parseFloatAmount(cart.Amt + cart.Tax);
    cart.Tax = parseFloatAmount(cart.Tax);
  }

  dealunpays(pcds) {
    var unpays = pcds;
    var cart = new Object();
    cart.CId = Constant.SSM_ROOTID;
    cart.Items = [];
    for (var i in unpays) {
      this.cal_Money_m(unpays[i], unpays[i].Code);
      if (
        unpays[i].RType == Constant.REGTP_NRITEM &&
        unpays[i].Type == Constant.PCD_TOT
      )
        this.cal_SubMoney_m3(unpays, unpays[i]);
      cart.Items.push(unpays[i]);
      this.cal_AllMoney_m(cart, null, 0);
    }

    this.setState({ store: cart, loading: false });
  }

  up_ItemTaxAmt_m(citem) {
    var amt =
      citem.Rate * citem.Count - citem.Disc <= 0
        ? 0
        : citem.Rate * citem.Count - citem.Disc;
    citem.Tax = 0;
    citem.Amt = parseFloatAmount(amt);
  }

  cal_Money_m(citem, code) {
    if (code == "" || code == null) code = "$0";
    citem.Code = code;
    if (citem.Code.substr(0, 1) == "$") {
      citem.Disc = parseFloatAmount(
        parseFloat(citem.Code.substr(1, citem.Code.length - 1))
      );
    } else if (citem.Code.substr(citem.Code.length - 1, 1) == "%") {
      citem.Disc = parseFloatAmount(
        (parseFloat(citem.Code.substr(0, citem.Code.length - 1)) / 100) *
          citem.Rate *
          citem.Count
      );
    }
    this.up_ItemTaxAmt_m(citem);
  }

  g_ssTime2() {
    return new Intl.DateTimeFormat("en", {
      timeStyle: "short",
    }).format(new Date());
  }

  pr_Invoice_v(invoid, cart, paytp, tend, full40, check) {
    var li = "";
    if (full40 == 0)
      li +=
        '<table id="reptcnt" class="receiptmargins fontsizesmall receipt40column">';
    else
      li +=
        '<table id="reptcnt" class="receiptmargins fontsizesmall receiptfullpage">';
    li += '<tr><td width="40%"></td><td width="60%"></td></tr>';
    li +=
      '<tr><td colspan="2" align="center" class="centeralignedreceiptheader"><h4>' +
      this.props.orginfo.Name +
      "<br />Payment Receipt</h4></td></tr>";
    li +=
      '<tr><td align="left">' +
      getCurrentDate() +
      '</td><td align="right">' +
      this.g_ssTime2() +
      "</td></tr>";

    li +=
      '<tr><td align="left">Invoice #:</td><td align="right" style="font-size:36px;"><div style="margin-top:15px;">' +
      invoid +
      "</div></td></tr>";
    li += '<tr><td colspan="2">&nbsp;</td></tr>    ';
    li += '<tr><td colspan="2" class="tdreceiptheader">';
    li += '	<table id="CartItems" width="100%" cellpadding="0" cellspacing="0">';
    li +=
      '		<thead><tr><th width="48%" class="leftalignedreceiptheader">Product</th><th width="10%" class="rightalignedreceiptheader">Qty</th><th width="22%" class="rightalignedreceiptheader">Price</th><th width="20%" class="rightalignedreceiptheader">Amt</th></tr></thead>';
    li += "		<tbody>";
    for (var m in cart.Items) {
      if (cart.Items[m].Type == Constant.PCD_TOT) {
        var price = parseAmount(cart.Items[m].Count * cart.Items[m].Rate);
        var rate = parseAmount(cart.Items[m].Rate);
        li +=
          '	<tr><td align="left">' +
          cart.Items[m].Desc +
          '</td><td align="right">' +
          cart.Items[m].Count +
          '</td><td align="right">$' +
          rate +
          '</td><td align="right">$' +
          price +
          "</td></tr>";
      }
    }
    li += "		</tbody>";
    li += "	</table>";
    li += "</td></tr>";
    li +=
      '<tr><td class="fontweightbold">Sub-Total:</td><td align="right" class="fontweightbold">$' +
      parseAmount(cart.Price) +
      "</td></tr>";
    if (cart.Disc > 0) {
      li += "<!--discount-->";
      li += '<tr><td colspan="2">&nbsp;</td></tr>';
      li += '<tr><td colspan="2" class="tdreceiptheader">';
      li +=
        '	<table id="tblDiscount" width="100%" cellpadding="0" cellspacing="0">';
      li +=
        '		<thead><tr><th width="60%" class="leftalignedreceiptheader">Discount/Coupon</th><th width="20%" class="rightalignedreceiptheader">Rate</th><th width="20%" class="rightalignedreceiptheader">Amt</th></tr></thead>';
      li += "		<tbody>";
      for (var m in cart.Items) {
        if (
          (cart.Items[m].Type == Constant.PCD_TOT &&
            cart.Items[m].RType != Constant.REGTP_NRITEM) ||
          (cart.Items[m].Type == Constant.PCD_MAIN &&
            cart.Items[m].RType == Constant.REGTP_NRITEM)
        ) {
          if (cart.Items[m].Disc > 0)
            li +=
              '	<tr><td align="left">' +
              cart.Items[m].Desc +
              '</td><td align="right">' +
              cart.Items[m].Code +
              '</td><td align="right">$' +
              parseAmount(cart.Items[m].Disc) +
              "</td></tr>";
        }
      }
      li += "		</tbody>";
      li += "	</table>";
      li += "</td></tr>";
      li +=
        '<tr><td align="left" class="fontweightbold">Sub-Total:</td><td align="right" class="fontweightbold">$' +
        parseAmount(cart.Disc) +
        "</td></tr>";
      li += "<!--discount-->";
    }
    li += "<!--tax-->";
    li += '<tr><td colspan="2">&nbsp;</td></tr>';
    li += '<tr><td colspan="2" class="tdreceiptheader">';
    li += '	<table id="tblTax" width="100%" cellpadding="0" cellspacing="0">';
    li +=
      '		<thead><tr><th width="60%" class="leftalignedreceiptheader">Tax</th><th width="20%" class="rightalignedreceiptheader">Rate</th><th width="20%" class="rightalignedreceiptheader">Amt</th></tr></thead>';
    li += "		<tbody>";
    for (var m in cart.Pcts) {
      //total tax
      li +=
        '	<tr><td align="left">' +
        cart.Pcts[m].TaxName +
        '</td><td align="right">' +
        parseAmount(cart.Pcts[m].TaxRate) +
        '%</td><td align="right">$' +
        parseAmount(cart.Pcts[m].Tax) +
        "</td></tr>";
    }
    li += "		</tbody>";
    li += "	</table>";
    li += "</td></tr>";
    li +=
      '<tr><td align="left" class="fontweightbold tdreceiptheader">Sub-Total:</td><td align="right" class="fontweightbold tdreceiptheader">$' +
      parseAmount(cart.Tax) +
      "</td></tr>";
    li += "<!--tax-->";
    li +=
      '<tr><td align="left" class="fontweightbold">Grand-Total:</td><td align="right" class="fontweightbold">$' +
      parseAmount(cart.Amt) +
      "</td></tr>";
    li += "<!--partialpay-->";

    li += "<!--partialpay-->";
    li += "<!--addpay-->";

    li += "<!--addpay-->";
    li += "<!--refund-->";

    li += "<!--refund-->";
    li += "<!--payment-->";
    li += '<tr><td colspan="2">&nbsp;</td></tr>';
    li += '<tr><td colspan="2" class="tdreceiptheader">';
    li +=
      '	<table id="tblPayment" width="100%" cellpadding="0" cellspacing="0">';
    li +=
      '		<thead><tr><th width="80%" class="leftalignedreceiptheader">Payment</th><th width="20%" class="rightalignedreceiptheader">Amt</th></tr></thead>';
    li += "		<tbody>";
    li +=
      '		<tr><td align="left">Credit Card</td><td align="right">$' +
      parseAmount(cart.Amt) +
      "</td></tr>";

    li += "		</tbody>";
    li += "	</table>";
    li += "</td></tr>";
    li +=
      '<tr><td align="left" class="fontweightbold">Total:</td><td align="right" class="fontweightbold">$' +
      parseAmount(cart.Amt) +
      "</td></tr>";

    li += "<!--payment-->";
    li += "<!--balance-->";

    li += "<!--balance-->";
    li += "<!--feeinfo-->";
    li += "TFEEINFO";
    li += "<!--feeinfo-->";
    li += "<!--billing-->";
    li += "BILLINGINFO";
    li += "<!--billing-->";
    li += "<!--note-->";
    li += "NOTEINFO";
    li += "<!--note-->";
    li += "</table>";
    return li;
  }

  cmb_Desc() {
    var desc = "";
    var c_Cart = this.state.store;
    for (var i in c_Cart.Items) {
      if (c_Cart.Items[i].Type == Constant.PCD_TOT) {
        desc += c_Cart.Items[i].Desc + "; ";
      }
    }
    //c_Cart.Desc=desc;

    return desc;
  }

  async do_finish_store() {
    this.setState({ loading: true });

    var ispr = 0;
    var iser = 1;
    var full40 = 0;
    var c_Cart = this.state.store;

    c_Cart.CId = Constant.SSM_ROOTID;
    c_Cart.OrgId = this.orgid;
    c_Cart.Type = Constant.PAY_FISH;
    if (c_Cart.Desc == null || c_Cart.Desc == "") c_Cart.Desc = this.cmb_Desc();
    c_Cart.RecEm = this.props.orginfo.RecEm;
    c_Cart.PurId = this.props.family.PersonInfo.Person.PId;

    c_Cart.Title = "";
    c_Cart.Flag = iser;
    c_Cart.Invo = this.pr_Invoice_v("SPORTSITESINVO", c_Cart, "", "", 0, "");
    c_Cart.Loc = Constant.PG_PLAYER;
    c_Cart.TZId = this.props.orginfo.TZId;
    c_Cart.Memo = "";
    const response = await finishCart(c_Cart);
    if (response.Id == 0) {
      this.setState({
        loading: false,
        errorflag: 0,
        errormsg: "A copy of your receipt has been emailed to you.",
      });
    } else if (response.Id == 5) {
      var err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    } else {
      this.setState({
        loading: false,
        errormsg: response.Description,
        errorflag: 1,
      });
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal, selectedItem: null });
  }

  confirm(msg) {
    return window.confirm(msg);
  }

  set_Paidnum_m(cart) {
    for (var i in cart.Items) {
      cart.Items[i].Paid = cart.Items[i].Amt;
    }
    for (var i in cart.Pcts) {
      cart.Pcts[i].PaidTax = cart.Pcts[i].Tax;
    }
    var hmtopay = cart.Amt;

    if (this.orgid == 177) {
      var add =
        parseAmount(hmtopay * 0.025) > 2 ? parseAmount(hmtopay * 0.025) : 2;
      var rt = this.confirm(
        "To pay by credit card, you will need to pay additional $" +
          add +
          " for transaction fee, do you still want to continue?"
      );
      if (rt == false) {
        return;
      } else {
        hmtopay = parseFloat(hmtopay) + parseFloat(add);
        this.setState({ toGo: 1 });
      }
    }
    cart.Paid = hmtopay;
    cart.PaidTax = cart.Tax;
  }

  handleCheckoutClick() {
    if (this.state.store.Amt == 0) {
      this.set_Paidnum_m(this.state.store);
      this.do_finish_store();
    } else {
      this.set_Paidnum_m(this.state.store);
      this.orgid != 177 && this.setState({ toGo: 1 });
    }
  }

  handleSelectItem(item) {
    this.setState({ modal: true });
    this.setState({ selectedItem: item });
  }

  async handleDeleteClick(item) {
    this.setState({ loading: true });
    await deleteStoreItem(item);
    const items2 = this.state?.mainpcds?.filter(
      (pcd) => pcd.PCDId !== item.PCDId
    );
    const items = items2?.filter((pcd) => pcd.PPCDId !== item.PCDId);
    this.setState({ mainpcds: items, loading: false });
    this.props.onDeleteClick(item);
  }

  f_Pcd_m(cart, pcdid) {
    for (var i in cart.Items) {
      if (pcdid == cart.Items[i].PCDId) return cart.Items[i];
    }
    return null;
  }

  async use_Code_m2(val) {
    this.setState({ loading: true });

    var c_Cart = this.state.store;

    var o = new Object();
    o.Code = val;
    o.Type = Constant.TP_COUPON;
    o.orgId = this.orgid;
    o.Items = c_Cart.Items;
    o.TZId = this.props.orginfo.TZId;

    const response = await useCode(o);
    if (response.Id == 0) {
      var codeifs = JSON.parse(response.Data);
      if (codeifs.length > 0) {
        for (var i in codeifs) {
          var pcd = this.f_Pcd_m(c_Cart, codeifs[i].PCDId);
          var dis = codeifs[i].Discnt;
          if (codeifs[i].Type == 0) dis = dis + "%";
          else dis = "$" + dis;

          pcd.DiscId = codeifs[i].DiscntId;
          pcd.DiscType = codeifs[i].DiscntType;
          pcd.DiscName = val;
          this.cal_Money_m(pcd, dis);
          if (
            pcd.RType == Constant.REGTP_NRITEM &&
            pcd.Type == Constant.PCD_MAIN
          )
            this.cal_SubMoney_m(pcd);
        }
        this.cal_AllMoney_m(c_Cart, null, 1);
        this.setState({ store: c_Cart, loading: false });
      } else {
        this.errors["Coup"] = "Invalid Code!";
        this.setState({ loading: false });
      }
    } else if (response.Id == 5) {
      var err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    } else {
      this.errors["Coup"] = response.Description;
      this.setState({ loading: false });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);

    this.setState({ coupon: value });
  };

  handleApplyCodeClick() {
    this.use_Code_m2(this.state.coupon);
  }

  handleErrOkay() {
    if (this.state.errorflag == 0) this.handlePayClick();
    else this.setState({ errormsg: "", toGo: 0 });
  }

  handleErr(err) {
    this.props.onErr(err);
  }

  handleBackClick() {
    this.setState({ toGo: 0 });
  }

  handlePayClick() {
    var c_Cart = this.state.store;
    c_Cart.Items.length = 0;
    c_Cart.Price = "0.00";
    c_Cart.Disc = "0.00";
    c_Cart.Tax = "0.00";
    c_Cart.Amt = "0.00";
    c_Cart.Code = "";
    var mainpcds = this.state.pcds;
    mainpcds.length = 0;
    this.setState({ store: c_Cart, pcds: mainpcds });

    this.props.onPayClick();
  }

  render() {
    if (this.state.mainpcds.length === 0) {
      return this.state.showEmpty ? <EmptySC /> : <Spinner />;
    }
    return (
      <div>
        {this.state.loading ? (
          <div className="d-flex align-items-center loading-screen">
            <Spinner />
          </div>
        ) : this.state.errormsg != "" ? (
          <ErrorMsg
            msg={this.state.errormsg}
            flag={this.state.errorflag}
            onErrOkay={this.handleErrOkay}
          />
        ) : this.state.toGo == 1 ? (
          <CCPay
            onErr={this.handleErr}
            orginfo={this.props.orginfo}
            family={this.props.family}
            store={this.state.store}
            invo={this.pr_Invoice_v(
              "SPORTSITESINVO",
              this.state.store,
              "",
              "",
              0,
              ""
            )}
            pcds={this.state.mainpcds}
            onErrOkay={this.handleErrOkay}
            onPayClick={this.handlePayClick}
            onBackClick={this.handleBackClick}
          />
        ) : (
          <div className="content-area">
            {!this.props.isDesktop && (
              <section id="titles-section">
                <div className="container-fluid">
                  <div className="row section-title-container">
                    <div className="col-12">
                      <span id="section-title" className="section-title">
                        Shopping Cart
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section
              id="cart-container"
              className={this.props.isDesktop && "container mt-5"}
            >
              <div className="container-fluid">
                <CartList
                  pcds={this.state.mainpcds}
                  onDeleteClick={this.handleSelectItem}
                  onUpCountClick={this.handleUpCountClick}
                  handleStoreData={this.handleStoreData}
                />
                <div id="cart-promo-code" className="row">
                  <div className="col-8">
                    <input
                      className="form-control coup-inputs"
                      type="text"
                      name="Coup"
                      onChange={this.handleChange}
                      value={this.state.coupon}
                      maxLength={32}
                      placeholder="Discount code or Gift card"
                    />
                    {ssm_valid.showerror("Coup", this.errors)}
                  </div>
                  <div className="col-4">
                    <button
                      className="apply-button"
                      onClick={this.handleApplyCodeClick}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <div id="cart-totals" className="row mt-3">
                  <div id="cart-totals-labels" className="col-6">
                    <p className="cart-totals-labels">Subtotal</p>
                    <p className="cart-totals-labels">Discount</p>
                    <p className="cart-totals-labels">Tax</p>
                    <p className="cart-totals-total">Total</p>
                  </div>
                  <div id="cart-totals-amounts" className="col-6">
                    <p className="cart-totals-labels">
                      ${parseTotal(this.state.store.Amt - this.state.store.Tax)}
                    </p>
                    <p className="cart-totals-labels">
                      ${parseTotal(this.state.store.Disc)}
                    </p>
                    <p className="cart-totals-labels">
                      ${parseTotal(this.state.store.Tax)}
                    </p>
                    <p className="cart-totals-total">
                      ${parseTotal(this.state.store.Amt)}
                    </p>
                  </div>
                </div>
                <div id="cart-actions">
                  <a>
                    <button
                      onClick={this.handleCheckoutClick}
                      className="filled-button-continue"
                    >
                      Checkout
                    </button>
                  </a>
                  <br />
                  <a>
                    <button
                      onClick={() => this.props.onNavigate("/")}
                      className="border-button"
                    >
                      Continue browsing
                    </button>
                  </a>
                </div>
              </div>
            </section>
            <Modal isOpen={this.state.modal}>
              <ModalHeader>
                <h5
                  className="modal-title text-danger"
                  id="addMembersModalTitle"
                ></h5>
                <button type="button" className="close" onClick={this.toggle}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody scroll={false}>
                <div className="d-flex justify-content-center mt-3 mb-3">
                  <span className="text-danger">
                    Are you sure you want to delete this item?
                  </span>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => {
                    this.handleDeleteClick(this.state.selectedItem);
                    this.toggle();
                  }}
                >
                  Delete
                </button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default Store;
