import { fetchAPIWithID } from "../config";
import Constant from "../../common/constants/define";

export async function updateUserPassword(person, oldpwd, newpwd) {
  let passwordInfo = {};
  passwordInfo.person = person;
  passwordInfo.oldpwd = oldpwd;
  passwordInfo.newpwd = newpwd;
  passwordInfo.flag = Constant.PG_PLAYER;

  const data = await fetchAPIWithID(
    "/ssm_api_user/updateuserpassword",
    passwordInfo
  );
  return data;
}
