import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Spinner from "../../common/components/Spinner";
import NodeListBox from "./node-listbox";
import NodeList from "./node-list";
import CampSite from "./campsite";
import NodeDetail from "./node-detail";
import Constant from "../../common/constants/define";
import { getAnnouncementDetail } from "../../services/common/getAnnouncementMessage";
import Modal, { ModalBody, ModalHeader } from "../../common/components/modal";
import { filterMxPos } from "../../common/utils/node";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rootnodes: this.props.rootnodes,
      upcomings: this.props.upcomings,
      loading: false,
      tabPos: 1,
      announcements: this.props.announcements,
      annmsg: "",
      modal: false,
    };

    this.handleHomeClick = this.handleHomeClick.bind(this);
    this.handleUpcomingClick = this.handleUpcomingClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleAnnouncementClick = this.handleAnnouncementClick.bind(this);
    this.handleErr = this.handleErr.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  
  componentDidMount() {
    if (document.hasFocus()) {
      this.props.onRefresh();
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.annmsg !== this.state.annmsg) {
      if (this.state.annmsg !== "") {
        this.setState({ modal: true });
      }
    }
  }

  handleErr(err) {
    this.props.onErr(err);
  }

  handleHomeClick() {
    this.setState({ tabPos: 1 });
  }
  handleUpcomingClick() {
    this.setState({ tabPos: 2 });
  }

  handleItemClick(item) {
    if ((item.App & Constant.APP_MAPDEF) == Constant.APP_MAPDEF) {
      this.setState({ node: item });
      this.props.onNavigate(`/campsite#${item.OId}`);
      return;
    }

    if (item.OType == 1) {
      this.props.onNavigate(`/folder#${item.OId}`);
    } else {
      this.props.onNavigate(`/node#${item.OId}`);
    }
  }

  cache = {};

  getchildnode(oid) {
    if (this.cache[oid]) {
      return this.cache[oid];
    }

    const childs = [];
    for (const i in this.props.smalltree) {
      if (this.props.smalltree[i].PId === oid) {
        childs.push(this.props.smalltree[i]);
      }
    }

    this.cache[oid] = childs;

    return childs;
  }

  has_Obj2(node) {
    if (
      (node.App & Constant.APP_ARCHIVED) != 32 &&
      (node.App & Constant.APP_PUBED) == 64
    )
      return true;
    return false;
  }

  has_Obj(node, visitedNodes = new Set()) {
    if (visitedNodes.has(node.OId)) {
      // This node has already been visited, so return false to avoid infinite recursion.
      return false;
    }

    visitedNodes.add(node.OId);

    if (node.OType == Constant.TP_FOLDER) {
      var nodes = this.getchildnode(node.OId);
      if (nodes.length > 0) {
        for (var i in nodes) {
          if (nodes[i].OType == Constant.TP_FOLDER) {
            if (this.has_Obj(nodes[i], visitedNodes)) return true;
          } else {
            if (this.has_Obj2(nodes[i])) return true;
          }
        }
      }
    } else {
      if (this.has_Obj2(node)) return true;
    }

    return false;
  }

  handleRegisterClick(node, mulslots) {
    this.props.onRegisterClick(node, mulslots);
  }

  isActive(val) {
    return val === this.state.tabPos ? "nav-link active" : "nav-link";
  }

  toggle() {
    this.setState({ modal: !this.state.modal, annmsg: "" });
  }

  handleAnnouncementClick(id) {
    getAnnouncementDetail(id).then((response) => {
      this.setState({ annmsg: response });
    });
  }

  getAnnouncementItem(obj) {
    return (
      <div
        className="ann-msg"
        key={(_, index) => index.toString()}
        onClick={() => this.handleAnnouncementClick(obj.AId)}
      >
        <a className="card p-2 d-flex flex-row align-items-center mb-2 anou2">
          <img className="annimg" src="/img/mess.gif" />
          <div className="announcement-text">{obj.Name}</div>
        </a>
      </div>
    );
  }

  renderAnnouncements(announcements, message) {
    if (announcements?.length == 0) return "";
    const msg = [];
    msg.push(
      <h6 key={(_, index) => index.toString()} className="ann-label">
        ANNOUNCEMENTS
      </h6>
    );

    for (var i = 0; i < announcements?.length; i++) {
      announcements[i].DFlag = 0;
    }

    for (var m in announcements) {
      var obj = filterMxPos(announcements);
      msg.push(this.getAnnouncementItem(obj));
    }

    return (
      <div
        key={(_, index) => index.toString()}
        className={!this.props.isDesktop && "ansbox"}
      >
        {msg}
      </div>
    );
  }

  render() {
    let listnodes;
    let isDesktop = this.props.isDesktop;
    if (this.state.tabPos == 1) listnodes = this.state.rootnodes;
    else listnodes = this.state.upcomings;
    var rootnodes = [];
    for (var i in listnodes) {
      if (this.has_Obj(listnodes[i])) rootnodes.push(listnodes[i]);
    }

    let listSmallTree = this.props.smalltree;
    let noEmptySmallTree = [];
    for (var i in listSmallTree) {
      if (this.has_Obj(listSmallTree[i]))
        noEmptySmallTree.push(listSmallTree[i]);
    }

    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <Routes>
            <Route
              path="/campsite"
              element={
                <div className="footer-bottom">
                  <CampSite
                    smalltree={this.props.smalltree}
                    node={this.state.node}
                    onRegisterClick={this.handleRegisterClick}
                    announcements={this.state.announcements}
                    isDesktop={this.props.isDesktop}
                    onNavigate={this.props.onNavigate}
                    maxCampsiteReservations={this.props.maxCampsiteReservations}
                  />
                </div>
              }
            />
            <Route
              path="/folder"
              element={
                <div>
                  <NodeListBox
                    smalltree={noEmptySmallTree}
                    onItemClick={this.handleItemClick}
                    announcements={this.state.announcements}
                    isDesktop={this.props.isDesktop}
                    onNavigate={this.props.onNavigate}
                  />
                </div>
              }
            />
            <Route
              path="/node"
              element={
                <div className={!isDesktop && "header-top-80"}>
                  <NodeDetail
                    onErr={this.handleErr}
                    smalltree={this.props.smalltree}
                    onRegisterClick={this.handleRegisterClick}
                    onNavigate={this.props.onNavigate}
                    node={this.state.node}
                  />
                </div>
              }
            />
            <Route
              path="/"
              element={
                <div
                  className={
                    isDesktop ? "header-top-base-30" : "header-top-base-90"
                  }
                >
                  <div className={isDesktop && "container"}>
                    {this.renderAnnouncements(
                      this.state.announcements,
                      this.state.annmsg
                    )}
                  </div>
                  <div className="content-area">
                    <div id="home-session-tabs">
                      <div
                        className={isDesktop ? "container" : "container-fluid"}
                      >
                        <div className={!isDesktop && "row"}>
                          <div className={!isDesktop && "col-md-12"}>
                            <div
                              className={`${
                                !isDesktop && "tab-content-main"
                              } mt-3`}
                              id="myTabContent"
                            >
                              {this.props.orgMessage ? (
                                <div className="card p-3 rounded mb-4">
                                  <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    dangerouslySetInnerHTML={{
                                      __html: this.props.orgMessage,
                                    }}
                                  />
                                </div>
                              ) : null}
                              <NodeList
                                nodes={rootnodes}
                                onItemClick={this.handleItemClick}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal isOpen={this.state.modal}>
                    <ModalHeader>
                      <h5>Announcement</h5>
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={this.toggle}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </ModalHeader>
                    <ModalBody>
                      <div className="anbox">
                        <div
                          className="orgwel"
                          dangerouslySetInnerHTML={{
                            __html: this.state.annmsg,
                          }}
                        ></div>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              }
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </>
    );
  }
}

export default Home;
