import React from "react";
import { formatDateEN, formatDateENGB, getCurrentDate } from "../../../common/utils/date";

class MySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fces: this.props.fces };
  }

  getScheduleItem_v(fce) {
    let d = formatDateENGB(fce.Start);
    let start = formatDateEN(fce.Start);

    fce.Name = fce.Name.replace("<br>", "");
    return (
      <div className="row purchases-item">
        <div className="col-12">
          <div className="row">
            <div className="col-3 purchased-class-container pl-4">
              <p className="cart-item-info">
                <span id="class-type">{d} </span>
              </p>
            </div>
            <div className="col-3 purchased-class-container schelisttime">
              <p className="cart-item-info">
                <span id="class-type">{start} </span>
              </p>
            </div>
            <div className="col-6 purchased-className-container">
              <p className="sclisttit">{fce.Desc.replace(/<[^>]+>/g, "")} </p>
              <p className="cart-item-info actgschname">
                <span id="class-type">
                  <span dangerouslySetInnerHTML={{ __html: fce.Name }} />{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getScheduleList_v() {
    const items = [];
    let now = getCurrentDate();
    let nowdate = new Date(now);
    for (let i = 0; i < this.state.fces.length; i++) {
      let fce = this.state.fces[i];
      let fcedate = new Date(fce.Start);
      if (fcedate > nowdate) {
        items.push(this.getScheduleItem_v(fce));
      }
    }

    return (
      <>
        <div className="d-flex justify-content-between align-items-center schedule-header rounded">
          <span className="schedule-title-date d-flex justify-content-center">
            Date
          </span>
          <span className="schedule-title-time d-flex justify-content-center">
            Time
          </span>
          <span className="schedule-title-detail d-flex justify-content-center pl-4">
            Detail
          </span>
        </div>
        <div className="pl-2 pr-2">{items}</div>
      </>
    );
  }

  render() {
    return <div>{this.getScheduleList_v()}</div>;
  }
}

export default MySchedule;