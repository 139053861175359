import React from "react";
import Spinner from "../../../../common/components/Spinner";
import BackTitle from "../../../../common/components/back/BackTitle";
import MyProfile from "../../profile";

class PersonDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { person: this.props.person, loading: false };

    this.handleBackClick = this.handleBackClick.bind(this);
  }

  handleBackClick() {
    this.props.onBackClick();
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="content-area">
            <section id="titles-section">
              <div className="container-fluid">
                <div className="row section-title-container sspdbox">
                  <div
                    ref={(divElement) => {
                      this.divElement = divElement;
                    }}
                    className="col-12"
                  >
                    <BackTitle
                      title={
                        this.state.person.FName + " " + this.state.person.LName
                      }
                      onBack={() => {
                        this.handleBackClick(this.state.node);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
            <MyProfile
              person={this.state.person}
              onLogout={this.props.onLogout}
              family={this.props.family}
              isFamily={this.props.isFamily}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PersonDetail;
