export const htmlToText = (html) => {
  //remove code brakes and tabs
  html = html.replace(/\n/g, "");
  html = html.replace(/\t/g, "");

  //keep html brakes and tabs
  html = html.replace(/<\/td>/g, "\t");
  html = html.replace(/<\/table>/g, "\n");
  html = html.replace(/<\/tr>/g, "\n");
  html = html.replace(/<\/p>/g, "\n");
  html = html.replace(/<\/div>/g, "\n");
  html = html.replace(/<\/h>/g, "\n");
  html = html.replace(/<br>/g, "\n");
  html = html.replace(/<br( )*\/>/g, "\n");

  //parse html into text
  var dom = new DOMParser().parseFromString(
    "<!doctype html><body>" + html,
    "text/html"
  );
  let arr = dom.body.textContent.split("\n");
  return arr;
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const removeItem = (arr, index) => {
  const unwantendElements = arr.slice(0, index);
  const wantedElements = arr.slice(index + 1);
  const newArray = unwantendElements.concat(wantedElements);
  return newArray;
};


