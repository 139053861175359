import React from "react";

const SportsitesMessage = ({ top = 0, bottom = 0 }) => {
  let year = new Date().getFullYear();
  return (
    <div
      className={`d-flex justify-content-center`}
      style={{ marginTop: top, marginBottom: bottom, bottom: 0 }}
    >
      <span className="sportsite-message">
        Powered by SportSites.com © {year}
      </span>
    </div>
  );
};

export default SportsitesMessage;
