import React from "react";
import Spinner from "../../../../common/components/Spinner";
import Radiobtn from "../../../../common/components/radiobtn";
import InputMask from "react-input-mask";
import ssm_valid from "../../../../common/utils/ssm_valid";
import ErrorMsg from "../../../../common/components/ErrorMsg";
import Checkbox from "../../../../common/components/register-checkbox";
import { updateUser } from "../../../../services/common/updateUser";
import {
  getCarrierOptions,
  getStateOptions,
} from "../../../../common/utils/inputs";
import { formatBDay } from "../../../../common/utils/date";
import { ssm_getcookie } from "../../../../common/utils/ssm_util";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: this.props.person,
      family: this.props.family,
      loading: false,
      showSms: false,
      errormsg: "",
      errorNum: "",
      isFamily: this.props.isFamily,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  requireds = [];
  errors = {};
  orgid = 0;

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
    this.state.person.BDay = formatBDay(this.state.person.BDay);
    if (
      this.state.isFamily &&
      this.state.family.PersonInfo.Person.PId !== this.state.person.PId
    ) {
      const currentFamilyMember = this.state.family.RelaInfos.find(
        (element) => element.Person.PId === this.state.person.PId
      );
      this.setState((prevState) => ({
        person: {
          ...prevState.person,
          Gend: this.state.person.Gend,
          Regi: this.state.person.Regi,
          Gate: this.state.person.Gate,
          Sms: currentFamilyMember?.Phones[0]?.Sms ?? 0,
        },
      }));
    } else {
      this.setState((prevState) => ({
        person: {
          ...prevState.person,
          Gend: this.state.person.Gend,
          Regi: this.state.person.Regi,
          Gate: this.state.person.Gate,
          Sms: this.state.family.PersonInfo?.Phones[0]?.Sms ?? 0,
        },
      }));
    }
  }

  get_personphones(person) {
    let personphones = [];
    let phone = {};
    phone.OId = person.PId;
    phone.Num = person.Num;
    phone.PId = 0;
    phone.Type = 1;
    phone.Prov = person.Gate;
    phone.Prio = 1;
    phone.Sms = person?.Sms;
    personphones.push(phone);
    return personphones;
  }

  componentDidMount() {
    this.requireds.push("FName");
    this.requireds.push("LName");
    this.requireds.push("Emai");
  }

  handleBackClick() {
    this.props.onBackClick();
  }

  handleSaveClick() {
    if (!this.state.person.Num.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)) {
      this.errors["Num"] = "Please enter a valid phone number.";
      this.setState({ errorNum: "Please enter a valid phone number." });
      return;
    }
    if (
      this.state.person?.ENumber?.length > 0 &&
      !this.state.person?.ENumber?.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)
    ) {
      this.errors["ENumber"] = "Please enter a valid phone number.";
      this.setState({ errorNum: "Please enter a valid phone number." });
      return;
    }

    this.errors["Num"] = "";
    this.errors["ENumber"] = "";
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.person,
      this.errors
    );
    if (!rqrt) {
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      return;
    }
    this.update_user_m();
  }

  handleRadioChange = (event) => {
    let id = event.target.id;
    ssm_valid.ck_radvalid(
      this.requireds,
      "Gend",
      this.state.person.Gend,
      this.errors
    );

    this.setState((prevState) => ({
      person: {
        ...prevState.person,
        Gend: id,
      },
    }));
  };

  handleSelChange = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);
    let val = event.target.value;
    let oname = event.target.name;
    this.setState((prevState) => ({
      person: {
        ...prevState.person,
        [oname]: val,
      },
    }));
  };

  get_personaddr(person) {
    var personaddr = new Object();
    var selectedPersonInfo = null;

    const family = [
      ...this.state.family.RelaInfos,
      ...[this.state.family.PersonInfo],
    ];

    for (var i in family) {
      if (family[i].Person.PId == person.PId) {
        selectedPersonInfo = family[i];
        break;
      }
    }

    if (
      selectedPersonInfo?.Addrs[0]?.AId == null ||
      selectedPersonInfo?.Addrs.length == 0
    ) {
      personaddr.AId = 0;
    } else {
      personaddr.AId = selectedPersonInfo?.Addrs[0]?.AId;
    }

    personaddr.OrgId = parseInt(this.orgid);
    personaddr.Addr1 = this.state.person.Addr;
    personaddr.Addr2 = "";
    personaddr.App = 0;
    personaddr.Otype = 0;
    personaddr.City = this.state.person.City;
    personaddr.Regi = this.state.person.Regi;
    personaddr.PCode = this.state.person.PCode;
    personaddr.Long = "";
    personaddr.Lati = "";
    return personaddr;
  }

  async update_user_m() {
    this.setState({ loading: true });
    let userInfo = {};
    userInfo.person = this.state.person;
    var personphones = this.get_personphones(this.state.person);
    userInfo.personaddr = this.get_personaddr(this.state.person);
    userInfo.personphones = this.state.person.Sms === 1 ? personphones : [];
    const response = await updateUser(userInfo);
    if (response.Id == 0) {
      this.setState({ loading: false });
      this.props.onSaveClick(this.state.person);
    } else {
      this.setState({ loading: false, errormsg: response.Description });
    }
  }

  getState() {
    return (
      <select
        className="form-control login-inputs"
        type="text"
        name="Regi"
        value={this.state.person.Regi}
        onChange={this.handleSelChange}
      >
        {getStateOptions()}
      </select>
    );
  }

  handleSelectChange = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);
    this.setState((prevState) => ({
      person: {
        ...prevState.person,
        [name]: value,
      },
    }));
  };

  getCarrier() {
    return (
      <select
        name="Gate"
        className="form-control login-inputs"
        value={this.state.person.Gate}
        onChange={this.handleSelectChange}
      >
        {getCarrierOptions()}
      </select>
    );
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;
    if (name == "Num") {
      if (val != "") {
        if (!val.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)) {
          this.errors["Num"] = "Please enter a valid phone number.";
        } else {
          this.errors["Num"] = "";
        }
      }
    }

    if (name == "ENumber") {
      if (val != "") {
        if (!val.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/)) {
          this.errors["ENumber"] = "Please enter a valid phone number.";
        } else {
          this.errors["ENumber"] = "";
        }
      }
    }

    this.setState((prevState) => ({
      person: {
        ...prevState.person,
        [name]: val,
      },
    }));
  };

  handleCheckboxChange = (event) => {
    let name = event.target.id;
    this.setState((prevState) => ({
      person: {
        ...prevState.person,
        [name]: prevState.person[name] ? 0 : 1,
      },
    }));
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg msg={this.state.errormsg} />
        ) : (
          <div className="content-area">
            <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="admin-personal-info"
                        role="tabpanel"
                        aria-labelledby="admin-pinfo-tab"
                      >
                        <div id="admin-profile-header" className="row">
                          <div className="col-12">
                            <img
                              width="64"
                              src="img/member-pp.svg"
                              alt="Profile picture"
                            />
                            <p
                              id="family-member-name"
                              className="family-member-pname"
                            >
                              {this.state.person.FName +
                                " " +
                                this.state.person.LName}
                            </p>
                          </div>
                        </div>
                        <div id="edit-admin-pinfo" className="row">
                          <div className="col-12">
                            <form>
                              <p className="list-title-tag">
                                Personal Information
                              </p>
                              <p className="info-label">First Name</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="text"
                                name="FName"
                                value={this.state.person.FName}
                                onChange={this.handleChange}
                                maxLength={64}
                              />
                              {ssm_valid.showerror("FName", this.errors)}
                              <p className="info-label">Last Name</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="text"
                                name="LName"
                                value={this.state.person.LName}
                                onChange={this.handleChange}
                                maxLength={64}
                              />
                              {ssm_valid.showerror("LName", this.errors)}
                              <p className="info-label">Email</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="email"
                                name="Emai"
                                value={this.state.person.Emai}
                                onChange={this.handleChange}
                                maxLength={50}
                              />
                              {ssm_valid.showerror("Emai", this.errors)}
                              <hr />
                              <p className="info-label">Date of Birth</p>
                              <InputMask
                                className="form-control edit-fm-inputs"
                                mask="99/99/9999"
                                name="BDay"
                                placeholder="MM/DD/YYYY"
                                type="text"
                                value={this.state.person.BDay}
                                onChange={this.handleChange}
                              />
                              {ssm_valid.showerror("BDay", this.errors)}
                              <p className="info-label">Gender</p>
                              <Radiobtn
                                label1="Male"
                                label2="Female"
                                req=""
                                value={this.state.person.Gend}
                                onRadioChange={this.handleRadioChange}
                              />
                              {ssm_valid.showerror("Gend", this.errors)}
                              <p className="list-title-tag">
                                Address Information
                              </p>
                              <p className="info-label">Address</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="text"
                                name="Addr"
                                value={this.state.person.Addr}
                                onChange={this.handleChange}
                                maxLength={128}
                              />
                              {ssm_valid.showerror("Addr", this.errors)}
                              <p className="info-label">City</p>
                              <input
                                className="form-control edit-fm-inputs"
                                type="text"
                                name="City"
                                value={this.state.person.City}
                                onChange={this.handleChange}
                                maxLength={64}
                              />
                              {ssm_valid.showerror("City", this.errors)}
                              <p className="info-label">State</p>
                              {this.getState()}
                              {ssm_valid.showerror("Regi", this.errors)}
                              <p className="info-label">Postal Code</p>
                              <InputMask
                                className="form-control edit-fm-inputs"
                                mask="99999"
                                type="text"
                                name="PCode"
                                value={this.state.person.PCode}
                                onChange={this.handleChange}
                              />
                              {ssm_valid.showerror("PCode", this.errors)}
                              <hr />
                              <p className="info-label">Phone</p>
                              <InputMask
                                className="form-control login-inputs"
                                mask="(999)999-9999"
                                type="text"
                                name="Num"
                                placeholder="Phone"
                                value={this.state.person.Num}
                                onChange={this.handleChange}
                              />
                              {ssm_valid.showerror("Num", this.errors)}
                              <Checkbox
                                id="Sms"
                                label="Allow text message"
                                isSelected={this.state.person.Sms === 1}
                                onCheckboxChange={this.handleCheckboxChange}
                                key="Sms"
                              />
                              {this.state.person.Sms == 1 && (
                                <div>
                                  {this.getCarrier()}
                                  {ssm_valid.showerror("Gate", this.errors)}
                                </div>
                              )}
                              <p className="info-label">Emergency Contact</p>
                              <input
                                className="form-control login-inputs"
                                type="text"
                                name="EName"
                                placeholder="Emergency Contact"
                                value={this.state.person.EName}
                                onChange={this.handleChange}
                                maxLength={100}
                              />
                              {ssm_valid.showerror("EName", this.errors)}
                              <p className="info-label">Emergency Phone</p>
                              <InputMask
                                className="form-control login-inputs"
                                mask="(999)999-9999"
                                type="text"
                                name="ENumber"
                                placeholder="Emergency Phone"
                                value={this.state.person.ENumber}
                                onChange={this.handleChange}
                              />
                              {ssm_valid.showerror("ENumber", this.errors)}
                              <div className="form-actions-area">
                                <a>
                                  <button
                                    onClick={this.handleSaveClick}
                                    className="filled-button-continue"
                                    type="button"
                                  >
                                    Save changes
                                  </button>
                                </a>
                                <a>
                                  <button
                                    onClick={this.handleBackClick}
                                    className="border-button"
                                    type="button"
                                  >
                                    Cancel
                                  </button>
                                </a>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default EditProfile;
