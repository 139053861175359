import React from "react";
import InputMask from "react-input-mask";

import Spinner from "../../../common/components/Spinner";
import ErrorMsg from "../../../common/components/ErrorMsg";
import Constant from "../../../common/constants/define";
import ssm_valid from "../../../common/utils/ssm_valid";

import { ssm_getcookie } from "../../../common/utils/ssm_util";
import { cardPay } from "../../../services/store/cardPay";
import { parseAmount } from "../../../common/utils/parse";
import {
  getCanadaProvinceOptions,
  getStateOptions,
} from "../../../common/utils/inputs";

class CCPay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      store: this.props.store,
      errormsg: "",
      isexpcc: false,
      readysub: false,
      toGo: 0,
      card: {},
      pcds: this.props.pcds,
      loading: false,
      errorid: 0,
      mask: "9999-9999-9999-9999",
    };

    this.handlePayClick = this.handlePayClick.bind(this);
    this.handleOkClick = this.handleOkClick.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
  }

  orgid = 0;
  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("CCNumber");
    this.requireds.push("CCFName");

    this.requireds.push("CCVDate");
    this.requireds.push("CCVVT");
    this.requireds.push("Addr");
    this.requireds.push("City");
    this.requireds.push("Regi");
    this.requireds.push("PCode");
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
    this.state.card.Addr = this.props.family.PersonInfo.Person.Addr;
    this.state.card.City = this.props.family.PersonInfo.Person.City;
    this.state.card.Regi = this.props.family.PersonInfo.Person.Regi;
    this.state.card.PCode = this.props.family.PersonInfo.Person.PCode;
  }

  handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);

    this.setState((prevState) => ({
      card: {
        ...prevState.card,
        [name]: value,
      },
    }));

    if (
      ssm_valid.ck_required2(this.requireds, this.state.card, this.errors) &&
      ssm_valid.ck_allvalid(this.errors)
    ) {
      this.setState({ readysub: true });
    }

    if (name == "CCNumber") {
      if (/^3[47]/.test(value)) {
        this.setState({ isexpcc: true });
      } else {
        this.setState({ isexpcc: false });
      }
    }
  };

  handleSelChange = (event) => {
    const { name, value } = event.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);

    let val = event.target.value;
    let oname = event.target.name;
    this.setState((prevState) => ({
      card: {
        ...prevState.card,
        [oname]: val,
      },
    }));
  };

  set_Paidnum_m(cart) {
    for (var i in cart.Items) {
      cart.Items[i].Paid = cart.Items[i].Amt;
    }
    for (var i in cart.Pcts) {
      cart.Pcts[i].PaidTax = cart.Pcts[i].Tax;
    }
    cart.Paid = cart.Amt;
    cart.PaidTax = cart.Tax;
  }

  cmb_Desc(c_Cart) {
    var desc = "";
    for (var i in c_Cart.Items) {
      if (c_Cart.Items[i].Type == Constant.PCD_TOT) {
        desc += c_Cart.Items[i].Desc + "; ";
      }
    }
    return desc;
  }

  get_PurId() {
    return this.props.family.PersonInfo.Person.PId;
  }

  g_CartPayIf(c_Cart, type) {
    var iser = 1;

    c_Cart.CId = Constant.SSM_ROOTID;
    c_Cart.OrgId = this.orgid;
    c_Cart.Type = type;
    c_Cart.Loc = Constant.PG_PLAYER;
    if (c_Cart.Desc == null || c_Cart.Desc == "")
      c_Cart.Desc = this.cmb_Desc(c_Cart);
    c_Cart.RecEm = this.props.orginfo.RecEm;
    c_Cart.PurId = this.get_PurId(c_Cart);
    c_Cart.Card = null;

    c_Cart.TZId = this.props.orginfo.TZId;

    c_Cart.Title = "";
    c_Cart.Flag = iser;
    c_Cart.Invo = this.props.invo;
    c_Cart.Memo = "";
    return c_Cart;
  }

  async do_card_pay() {
    var c_Cart = this.state.store;

    var o = new Object();
    this.state.card.CCLName = "";
    o.Card = this.state.card;
    o.Amt = c_Cart.Amt;
    o.TAmt =
      parseAmount(c_Cart.Amt * 0.0245) > 1.95
        ? parseAmount(c_Cart.Amt * 0.0245)
        : 1.95;
    o.Tax = c_Cart.Tax;
    o.PayType = Constant.PAY_CARD;
    o.Type = Constant.PG_PLAYER;
    o.Desc = this.cmb_Desc(c_Cart);
    o.PurId = this.get_PurId(c_Cart);
    o.TransType = Constant.TRANSTP_PAY;

    o.Cart = this.g_CartPayIf(c_Cart, Constant.PAY_CARD);
    o.TZId = this.props.orginfo.TZId;

    const response = await cardPay(o);
    if (response.Id == 0) {
      this.setState({ loading: false, toGo: 1 });
    } else if (response.Id == 5) {
      var err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    } else {
      this.setState({
        loading: false,
        errormsg: response.Description,
        errorid: response.Id,
      });
    }
  }

  handlePayClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.card,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.set_Paidnum_m(this.state.store);

    this.do_card_pay();
  }

  handleOkClick() {
    this.props.onPayClick();
  }

  handleErrOkay() {
    if (this.state.errorid == 1001) this.setState({ errormsg: "" });
    else this.props.onErrOkay();
  }

  getCountry() {
    return (
      <select
        className="form-control login-inputs"
        type="text"
        name="Coun"
        value={this.state.card.Coun}
        onChange={this.handleSelChange}
      >
        <option value="US" selected="selected">
          USA
        </option>
        <option value="CAN">Canada</option>
      </select>
    );
  }

  getState() {
    return this.state.card.Coun == "US" || this.state.card.Coun == undefined
      ? this.getusState()
      : this.getcanState();
  }

  getusState() {
    return (
      <select
        className="form-control login-inputs"
        type="text"
        name="Regi"
        value={this.state.card.Regi}
        onChange={this.handleSelChange}
      >
        {getStateOptions()}
      </select>
    );
  }
  getcanState() {
    return (
      <select
        className="form-control login-inputs"
        type="text"
        name="Regi"
        value={this.state.card.Regi}
        onChange={this.handleSelChange}
      >
        {getCanadaProvinceOptions()}
      </select>
    );
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg msg={this.state.errormsg} onErrOkay={this.handleErrOkay} />
        ) : this.state.toGo == 0 ? (
          <div className="content-area mt-3">
            <section id="cart-container">
              <div className="container-fluid">
                <div id="registration-step-bar" className="row">
                  Credit Card info
                </div>
                <div className="cart-paid">Need to pay: ${this.state.store.Paid}</div>
                <div id="step-one" className="row">
                  <div className="col-12">
                    <form>
                      <InputMask
                        className="form-control login-inputs"
                        mask={
                          this.state.isexpcc
                            ? "9999-999999-99999"
                            : "9999-9999-9999-9999"
                        }
                        name="CCNumber"
                        placeholder="Card number"
                        value={this.state.card.CCNumber}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("CCNumber", this.errors)}
                      <input
                        className="form-control login-inputs"
                        type="text"
                        name="CCFName"
                        maxLength={64}
                        placeholder="Name as it appears on card"
                        value={this.state.card.CCFName}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("CCFName", this.errors)}

                      <InputMask
                        mask="99/99"
                        className="form-control login-inputs"
                        type="text"
                        name="CCVDate"
                        placeholder="Expiration date (MM/YY)"
                        value={this.state.card.CCVDate}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("CCVDate", this.errors)}
                      <InputMask
                        mask={this.state.isexpcc ? "9999" : "999"}
                        className="form-control login-inputs"
                        type="text"
                        name="CCVVT"
                        placeholder="Security Code"
                        value={this.state.card.CCVVT}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("CCVVT", this.errors)}
                      <input
                        className="form-control login-inputs"
                        type="text"
                        name="Addr"
                        placeholder="Address"
                        value={this.state.card.Addr}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("Addr", this.errors)}
                      <input
                        className="form-control login-inputs"
                        type="text"
                        name="City"
                        placeholder="City"
                        maxLength={64}
                        value={this.state.card.City}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("City", this.errors)}
                      {this.getState()}
                      {ssm_valid.showerror("Regi", this.errors)}
                      <InputMask
                        className="form-control login-inputs"
                        type="text"
                        name="PCode"
                        placeholder="Zip Code"
                        value={this.state.card.PCode}
                        onChange={this.handleChange}
                      />
                      {ssm_valid.showerror("PCode", this.errors)}
                      {this.getCountry()}
                      {ssm_valid.showerror("Coun", this.errors)}
                    </form>
                  </div>
                </div>
              </div>
              <div id="cart-actions">
                <button
                  onClick={this.handlePayClick}
                  className={
                    this.state.readysub
                      ? "filled-button-continue"
                      : "gray-button"
                  }
                >
                  Pay
                </button>
                <button
                  onClick={this.props.onBackClick}
                  className="border-button"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </section>
          </div>
        ) : (
          <div className="container content-area p-4">
            <section id="successful-payment" className="card pb-4 pt-4">
              <div>
                <div className="row">
                  <div className="col-12 tacenter">
                    <img
                      width="64"
                      src="img/success-icon.svg"
                      alt="successful payment"
                    />
                    <p className="success-message">Success!</p>
                    <p>
                      Your payment for{" "}
                      <span id="payment-amount">${this.state.store.Amt}</span>
                      <br />
                      was successfully completed
                    </p>
                    <p>A copy of your receipt has been emailed to you.</p>
                  </div>
                </div>
              </div>
            </section>
            <div id="cart-actions">
              <button
                onClick={this.handleOkClick}
                className="filled-button top-button-mbottom"
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CCPay;
