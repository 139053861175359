import { fetchAPI } from "../config";

export async function getTrees(id) {
  var req = {};
  req.Guid = id;
  req.Type = 0;
  req.Flag = 0;

  let Allinfo = {};
  Allinfo.TR = new Buffer(JSON.stringify(req)).toString("base64");
  req.Type = 1;
  Allinfo.STR = new Buffer(JSON.stringify(req)).toString("base64");

  let jsonstr = JSON.stringify(Allinfo);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = "00000000-0000-0000-0000-000000000000";
  const para = { jsonstr, sid };

  const data = await fetchAPI("/ssm_api_tree/gettrees", para);
  return data;
}


export async function getTreesAndPerson(para) {
  const data = await fetchAPI("/ssm_api_tree/gettreesandperson", para);
  return data;
}