import React from "react";
import ssm_valid from "../../../common/utils/ssm_valid";

class CustomQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      node: this.props.node,
      CustomQuestions: this.props.CustomQuestions,
      errors: this.props.errors,
    };
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
  }

  requireds = [];

  componentWillReceiveProps(props) {
    this.setState({ errors: props.errors });
  }

  handleAnswerChange(e) {
    const { id, value } = e.target;
    ssm_valid.ck_cqvalid(this.requireds, id, value, this.state.errors);
    this.update_CQs(e.target.id, e.target.value);
    this.props.onAnswerChange(this.state.CustomQuestions, this.state.errors);
  }

  f_CQA(id, val) {
    for (var i in this.state.node.Questions) {
      if (id == this.state.node.Questions[i].QId) {
        for (var j in this.state.node.Questions[i].Responses) {
          if (this.state.node.Questions[i].Responses[j].Body == val) {
            return this.state.node.Questions[i].Responses[j];
          }
        }
      }
    }
  }

  getAnsw(id) {
    for (var i in this.state.CustomQuestions) {
      if (this.state.CustomQuestions[i].QId == id) {
        return this.state.CustomQuestions[i].Answ;
      }
    }
  }

  update_CQs(id, val) {
    for (var i in this.state.CustomQuestions) {
      if (this.state.CustomQuestions[i].QId == id) {
        if (this.state.CustomQuestions[i].Type == 1) {
          this.state.CustomQuestions[i].Answ = val;
        } else {
          var cqa = this.f_CQA(id, val);
          this.state.CustomQuestions[i].Answ = val;
          if (val != -1) {
            this.state.CustomQuestions[i].AnswId = cqa.AnswId;
            this.state.CustomQuestions[i].FId = cqa.QRId;
            this.state.CustomQuestions[i].Fee = cqa.Fee;
          }
        }
      }
    }
  }

  getMCQBody(CQ) {
    const items = [];

    items.push(
      <option id="-1" value="-1" name="-1">
        Please Select...
      </option>
    );

    for (var i in CQ.Responses) {
      items.push(
        <option id={CQ.Responses[i].QRId} value={CQ.Responses[i].Body}>
          {CQ.Responses[i].Body}
        </option>
      );
    }

    var star = CQ.Requ ? "*" : "";
    return (
      <>
        <div className="row">
          <div id="empty-cart-title" className="col-12 ta-left">
            <p className="question-title">{CQ.Body + star}</p>
          </div>
        </div>

        <div id="login-form-container" className="row">
          <div className="col-12  ta-left">
            <select
              id={CQ.QId}
              value={this.getAnsw(CQ.QId)}
              className="form-control login-inputs mb-3"
              onChange={this.handleAnswerChange}
            >
              {items}
            </select>
            {ssm_valid.showerror(CQ.QId, this.state.errors)}
          </div>
        </div>
      </>
    );
  }

  getTCQBody(CQ) {
    var star = CQ.Requ ? "*" : "";
    return (
      <>
        <div className="row">
          <div id="empty-cart-title" className="col-12  ta-left">
            <p className="question-title">{CQ.Body + star}</p>
          </div>
        </div>

        <div id="login-form-container" className="row">
          <div className="col-12  ta-left">
            <input
              id={CQ.QId}
              value={this.getAnsw(CQ.QId)}
              onChange={this.handleAnswerChange}
              className="form-control login-inputs"
              type="text"
            />
            {ssm_valid.showerror(CQ.QId, this.state.errors)}
          </div>
        </div>
      </>
    );
  }

  getCQContent() {
    const items = [];
    for (var i in this.state.node.Questions) {
      if (this.state.node.Questions[i].Requ)
        this.requireds.push(this.state.node.Questions[i].QId);
      if (this.state.node.Questions[i].Type == 0)
        items.push(this.getMCQBody(this.state.node.Questions[i]));
      else items.push(this.getTCQBody(this.state.node.Questions[i]));
    }

    return <div>{items}</div>;
  }

  render() {
    return <div>{this.getCQContent()}</div>;
  }
}

export default CustomQuestion;
