import React from "react";

const Checkbox = ({ id, label, isSelected, onCheckboxChange, fontsize }) => {
  var img =
    isSelected == true
      ? "/img/CampIcon/icon_campsite_selected.png"
      : "/img/CampIcon/icon_campsite_available.png";
  return (
    <div class={fontsize}>
      <label class="sscheckarea d-flex  align-items-center" id={id} onClick={onCheckboxChange}>
        <img id={id} class="sscheckbox" src={img} /> {label}
      </label>
    </div>
  );
};

export default Checkbox;
