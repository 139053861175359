import React from "react";
import { formatDateEN, formatDateENGB } from "../../../../common/utils/date";

class ActivitySchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ofces: this.props.ofces,
      A_Fces: this.props.A_Fces,
      node: this.props.node,
    };
  }

  getActScheduleItem_v(fce) {
    let d = formatDateENGB(fce.Start);
    let start = formatDateEN(fce.Start);
    let end = formatDateEN(fce.End);

    return (
      <div class="row purchases-item mx-1">
        <div class="col-12">
          <div class="row">
            <div class="col-3 purchased-class-container">
              <p class="cart-item-info">
                <span id="class-type">{d} </span>
              </p>
            </div>
            <div class="col-4 purchased-class-container schelisttime">
              <p class="cart-item-info">
                <span id="class-type">{start} - {end} </span>
              </p>
            </div>
            <div class="col-5 purchased-class-container">
              <p class="cart-item-info actschname">
                <span id="class-type">
                  <span dangerouslySetInnerHTML={{ __html: fce.Name }} />{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getActScheduleList_v() {
    const items = [];
    for (let i = 0; i < this.state.ofces.length; i++) {
      items.push(this.getActScheduleItem_v(this.state.ofces[i]));
    }

    return (
      <>
        <div className="d-flex justify-content-between align-items-center schedule-header rounded mr-1">
          <span class="schedule-title-date d-flex justify-content-center">
            Date
          </span>
          <span class="schedule-title-time d-flex justify-content-center">
            Time
          </span>
          <span class="schedule-title-detail d-flex justify-content-center pl-4">
            Detail
          </span>
        </div>
        {items}
      </>
    );
  }

  render() {
    return <div>{this.getActScheduleList_v()}</div>;
  }
}

export default ActivitySchedule;
