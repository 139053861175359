import React from "react";
import Modal, { ModalBody, ModalHeader, ModalFooter } from "./index";

function Alert({ show, message, onClose }) {
  return (
    <Modal isOpen={show}>
      <ModalHeader>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={onClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody scroll={false}>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <span>{message}</span>
        </div>
      </ModalBody>
      <ModalFooter>
        <a
          className={
            "d-flex justify-content-center modal-buttom"
          }
          >
          <button
            onClick={onClose}
            className="filled-button"
          >
            Okay
          </button>
        </a>
      </ModalFooter>
    </Modal>
  );
}

export default Alert;
