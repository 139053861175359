import moment from "moment";

export const getNow = () => {
  let now = new Date();
  let nd = now.getDate();
  let nm = now.getMonth();
  let ny = now.getFullYear();
  let eday = nm + 1 + "/" + nd + "/" + ny + " " + "11:59 pm";
  return eday;
};

export const formatBDay = (val) => {
  let date = new Date(val);
  let dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  let MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  let yy = date.getFullYear();
  return MM + "/" + dd + "/" + yy;
};

export const formatDefaultBDay = (val) => {
  let date = new Date(val);
  let dd = (date.getDate() < 10 ? "0" : "") + date.getDate();
  let MM = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1);
  let yy = date.getFullYear();
  var str = MM + "/" + dd + "/" + yy;
  if (str == "01/01/1970") str = "";
  return str;
};

export const getDateByValue = (val) => {
  let dd = new Date(val);
  let m = dd.getMonth() + 1;
  let d = dd.getDate();
  let y = dd.getFullYear();
  return m + "/" + d + "/" + y;
};

export const getCurrentDate = () => {
  let now = new Date();
  let nd = now.getDate();
  let nm = now.getMonth();
  let ny = now.getFullYear();
  let eday = nm + 1 + "/" + nd + "/" + ny;
  return eday;
};

export const formatDateENGB = (val) => {
  return new Intl.DateTimeFormat("en-GB", {
    month: "short",
    day: "2-digit",
  }).format(new Date(val));
};

export const formatDateFullENGB = (val) => {
  return new Intl.DateTimeFormat("en-GB", {
    dateStyle: "full",
    timeStyle: "long",
  }).format(new Date(val));
};

export const formatDateMoment = (val) => {
  return moment(val).format("MM/DD/YYYY");
};

export const formatDateGB = (val) => {
  return new Intl.DateTimeFormat("GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(val);
};

export const formatDateEN = (val) => {
  return new Intl.DateTimeFormat("en", {
    timeStyle: "short",
  }).format(new Date(val));
};

export const formatCompleteDateENGB = (val) => {
  if (!val) return null;
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(val));
};

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const filterWeek = (i, days) => {
  if (days != null && days.length > 0) {
    for (var m = 0; m < days.length; m++) {
      if (i == days[m]) return true;
    }
  }
  return false;
};
