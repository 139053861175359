import { fetchAPI } from "../config";

export async function searchCampsite(request) {
  let jsonstr = JSON.stringify(request);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = "00000000-0000-0000-0000-000000000000";
  const para = { jsonstr, sid };
  const data = await fetchAPI("/ssm_api_node/searchcampsite", para);
  let campsiteInfo = JSON.parse(data.Data);
  return campsiteInfo;
}
