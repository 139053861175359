export const filterVens = (vid, vens) => {
  if (vens.length > 0) {
    for (var m in vens) {
      if (vens[m].VId == vid) {
        return vens[m];
      }
    }
  }
  return null;
};

export const filterDaysSchedules = (date, DaysSches) => {
  for (var k in DaysSches) {
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    var d2 = DaysSches[k].Date.getDate();
    var m2 = DaysSches[k].Date.getMonth();
    var y2 = DaysSches[k].Date.getFullYear();

    if (y == y2 && m == m2 && d == d2) {
      return DaysSches[k];
    }
  }
  return null;
};

export const getRegsCinC = () => {
  var cnt = 0;
  return cnt;
};
