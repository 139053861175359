import { ssm_getcookie } from "../../common/utils/ssm_util";
import { fetchAPI } from "../config";

export async function getCampsiteInformation(campsiteId) {
  let jsonstr = campsiteId;
  let type = 2;
  let sid = ssm_getcookie("ssm_sid");
  const para = { jsonstr, type, sid };
  const data = await fetchAPI("/ssm_api_node/getcampinfo", para);
  return data;
}
