import React from "react";

class TeamsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { node: this.props.node };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    var team = new Object();
    team.GId = e.target.value;
    team.Name = e.target.options[e.target.selectedIndex].text;
    this.props.onHandleTeamChange(team);
  }

  getFindTeamContent() {
    const items = [];
    for (var i in this.state.node.Groups) {
      if (this.state.node.Groups[i].Type === 9) {
        items.push(
          <option
            key={this.state.node.Groups[i].GId}
            value={this.state.node.Groups[i].GId}
          >
            {this.state.node.Groups[i].Name}
          </option>
        );
      }
    }

    return (
      <select
        className="form-control login-inputs"
        onChange={this.handleChange}
      >
        <option value="0" selected>
          Please select team
        </option>
        {items}
      </select>
    );
  }
  render() {
    return <div>{this.getFindTeamContent()}</div>;
  }
}

export default TeamsSelect;
