import React from "react";
import { ssm_getcookie } from "../../../common/utils/ssm_util";
import Spinner from "../../../common/components/Spinner";
import ErrorMsg from "../../../common/components/ErrorMsg";
import ssm_valid from "../../../common/utils/ssm_valid";
import { forgetPassword } from "../../../services/auth/forgetPassword";

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false, errormsg: "", errorflag: 0, logininfo: {} };
    this.handleSendClick = this.handleSendClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
  }

  orgid = 0;
  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("Emai");
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  handleSendClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.logininfo,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.do_forget();
  }
  handleCancelClick() {
    this.props.onNavigate("/login");
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;
    this.setState((prevState) => ({
      logininfo: {
        ...prevState.logininfo,
        [name]: val,
      },
    }));
  };

  handleErrOkay(event) {
    this.props.onNavigate("/login");
  }

  async do_forget() {
    const response = await forgetPassword(this.state.logininfo.Emai);
    if (response.Id == 0) {
      this.setState({
        loading: false,
        errormsg:
          "We've sent a link to your email address to reset your password.",
        errorflag: 0,
      });
    } else {
      this.setState({ loading: false, errormsg: response.Description, errorflag: 1 });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg
            msg={this.state.errormsg}
            flag={this.state.errorflag}
            onErrOkay={this.handleErrOkay}
          />
        ) : (
          <div className="content-area">
            <section id="login-container">
              <div className="container-fluid">
                <div id="login-title" className="row">
                  <div id="empty-cart-title" className="col-12">
                    <p className="login-title">Forgot Password</p>
                  </div>
                </div>
                <div id="login-form-container" className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <input
                      onChange={this.handleChange}
                      className="form-control login-inputs"
                      type="text"
                      name="Emai"
                      placeholder="Email"
                      maxLength={50}
                    />
                    {ssm_valid.showerror("Emai", this.errors)}

                    <div className="form-actions-area">
                      <a>
                        <button
                          onClick={this.handleSendClick}
                          className="filled-button-continue"
                          type="button"
                        >
                          Send
                        </button>
                      </a>
                      <a>
                        <button
                          onClick={this.handleCancelClick}
                          className="border-button"
                          type="button"
                        >
                          Cancel
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default ForgetPassword;