import React from "react";

import Constant from "../../../../common/constants/define";
import Spinner from "../../../../common/components/Spinner";
import Alert from "../../../../common/components/modal/alert";
import { htmlToText } from "../../../../common/utils/utils";
import { newPcds } from "../../../../services/store/newPcds";
import { parseTotal } from "../../../../common/utils/parse";

class CartItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pcd: this.props.pcd,
      loading: false,
      showAlert: false,
      alertMessage: "",
      Count: this.props.pcd.Count,
      items: this.props.items,
      key: this.props.key,
      collapse: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCountChange = this.handleCountChange.bind(this);
    this.handleAlertClose = this.handleAlertClose(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  handleCountChange(e) {
    this.setState({ Count: e.target.value });
    this.up_Cnt2(this.state.pcd.Count, e.target.value, this.state.pcd.PCDId);
  }

  up_Cnt2(old, newval, pcdid) {
    if (parseInt(old) < parseInt(newval)) {
      this.up_NRCount_m(old, pcdid, parseInt(newval) - parseInt(old), 1);
    } else if (parseInt(old) > parseInt(newval)) {
      this.up_NRCount_m(old, pcdid, parseInt(old) - parseInt(newval), 0);
    }
  }

  handleAlertClose() {
    this.setState({ showAlert: false, alertMessage: "" });
  }

  async up_NRCount_m(old, pcdid, val, type) {
    var pcd = this.state.pcd;
    var diff = 0;
    if (type == 0) diff = parseInt(old) - parseInt(val);
    else diff = parseInt(old) + parseInt(val);

    if (diff > parseInt(pcd.InvCnt) && parseInt(pcd.InvCnt) != -100) {
      this.setState({
        showAlert: true,
        alertMessage:
          "Sold out. There is/are only" + pcd.InvCnt + " left in the store.",
      });
      return;
    }

    let req = {};
    req.Guid = pcdid;
    req.Type = type;
    req.Val = val;
    req.Amt = pcd.Amt;
    req.Tax = pcd.Tax;
    const response = await newPcds(req);

    this.setState({ loading: false });
    if (response.Id == 0) {
      if (type == 1) {
        var cnt = old + val;
        var newpcds = JSON.parse(response.Data);
        this.props.OnUpCountClick(pcdid, cnt, type, 0, newpcds);
        this.props.handleStoreData();
      } else {
        var cnt = old - val;
        this.props.OnUpCountClick(pcdid, cnt, type, val, null);
        this.props.handleStoreData();
      }
    }
  }

  handleDeleteClick(item) {
    this.props.onDeleteClick(item);
  }

  getItemName() {
    var name = this.state.pcd.Desc;
    var pos = name.indexOf("(Team : undefined)");
    if (pos > 0) {
      name = name.substring(0, pos);
    }
    return name;
  }

  toggleCollapse() {
    this.setState((prevState) => ({ collapse: !prevState.collapse }));
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="card p-2 mt-2">
            <div id="cart-item" className="row">
              <div className="col-10">
                <p className="cart-item-name">
                  {htmlToText(this.getItemName())}
                </p>
              </div>
              <div className="col-2 ta-right cart-item-delete">
                <img
                  onClick={() => {
                    this.handleDeleteClick(this.state.pcd);
                  }}
                  width="24"
                  src="img/close-icon.svg"
                  alt="delete item"
                />
              </div>
            </div>
            {this.props.pcd.RType == Constant.REGTP_NRITEM ? (
              <div id="amount-selector" className="row">
                <div className="col-12">
                  <label className="select-label">QTY</label>
                  <select
                    className="select-qty"
                    name="Count"
                    value={this.state.Count}
                    onChange={this.handleCountChange}
                  >
                    <option value="1" selected>
                      1
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div id="item-total" className="row">
              <div className="col-6 d-flex align-items-center">
                <span className="item-total-main">
                  ${parseTotal(this.state.pcd.Amt)}
                </span>
              </div>
              <div className="col-6 ta-right">
                <button
                  class="btn mb-1"
                  onClick={this.toggleCollapse}
                  aria-expanded={this.state.collapse}
                  aria-controls={`${this.state.pcd.PCDId}`}
                >
                  Detail
                </button>
              </div>
            </div>
            <div
              className={this.state.collapse ? "collapse show" : "collapse"}
              id={`${this.state.pcd.PCDId}`}
            >
              {this.state.items?.map((item, index) => {
                return (
                  <div key={index} className="items-bg mt-2 p-2 rounded">
                    <div id="cart-item" className="row pl-2">
                      <div className="col-10">
                        <p className="cart-item-name">
                          {item.Type === 1
                            ? "Registration"
                            : htmlToText(item.Desc)}
                        </p>
                      </div>
                    </div>
                    <div id="item-total" className="row pl-2">
                      <div className="col-3">
                        <span className="pre-total-label">Rate</span>
                        <br />
                        <span className="pre-total-amount">
                          ${parseTotal(item.Rate)}
                        </span>
                      </div>
                      <div className="col-3">
                        <span className="pre-total-label">Subtot.</span>
                        <br />
                        <span className="pre-total-amount">
                          ${parseTotal(item.Count * item.Rate)}
                        </span>
                      </div>
                      <div className="col-3">
                        <span className="pre-total-label">Disc.</span>
                        <br />
                        <span className="pre-total-disc">
                          ${parseTotal(item.Disc)}
                        </span>
                      </div>
                      <div className="col-3">
                        <br />
                        <span className="item-total-list">
                          ${parseTotal(item.Amt)}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Alert
          message={this.state.alertMessage}
          show={this.state.showAlert}
          onClose={this.handleAlertClose}
        />
      </div>
    );
  }
}

export default CartItem;
