import React from "react";
import { ssm_getcookie, ssm_setcookie } from "../../../common/utils/ssm_util";
import Spinner from "../../../common/components/Spinner";
import ErrorMsg from "../../../common/components/ErrorMsg";
import ssm_valid from "../../../common/utils/ssm_valid";
import Constant from "../../../common/constants/define";
import { checkEmailExist } from "../../../services/common/checkEmailExist";
import { userRegister } from "../../../services/auth/userRegister";

class RegisterAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, errormsg: "", reginfo: {}, registered: 0 };
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
    this.handleForget = this.handleForget.bind(this);
  }

  orgid = 0;
  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("FName");
    this.requireds.push("LName");
    this.requireds.push("Emai");
    this.requireds.push("Emai2");
    this.requireds.push("Passwd");
    this.requireds.push("Passwd2");
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  handleRegisterClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.reginfo,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.do_register();
  }

  handleLoginClick() {
    this.props.onNavigate("/login");
  }

  handleChange = async (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (this.state.reginfo.Emai !== undefined) {
      if (name === "Emai") {
        const response = await checkEmailExist(val);
        this.setState({ registered: response.Data });
        if (val.length > 0) {
          if (this.state.registered.toString() === "0") {
            this.errors["Emai"] = "";
          } else {
            this.errors["Emai"] = "Email is already registered";
          }
        }
      }
    }

    if (this.state.reginfo.Emai2 !== undefined)
      if (name == "Emai2") {
        if (this.state.reginfo.Emai.toLowerCase() === val.toLowerCase()) {
          this.errors["Emai2"] = "";
        } else {
          this.errors["Emai2"] = "Email does not match";
        }
      } else if (name == "Emai") {
        if (this.state.reginfo.Emai2.toLowerCase() != val.toLowerCase()) {
          this.errors["Emai2"] = "Email does not match";
        } else {
          this.errors["Emai2"] = "";
        }
      }

    if (this.state.reginfo.Passwd2 != undefined)
      if (name == "Passwd2") {
        if (this.state.reginfo.Passwd != val) {
          this.errors["Passwd2"] = "Password does not match";
        } else {
          this.errors["Passwd2"] = "";
        }
      } else if (name == "Passwd") {
        if (this.state.reginfo.Passwd2 != val) {
          this.errors["Passwd2"] = "Password does not match";
        } else {
          this.errors["Passwd2"] = "";
        }
      }

    this.setState((prevState) => ({
      reginfo: {
        ...prevState.reginfo,
        [name]: val,
      },
    }));
  };

  handleForget(event) {
    this.props.onNavigate("/forget");
  }

  handleErrOkay(event) {
    this.setState({ errormsg: "" });
  }

  async do_register() {
    let person = {};
    person.PId = this.orgid;
    person.OrgId = this.orgid;
    person.FName = this.state.reginfo.FName;
    person.LName = this.state.reginfo.LName;
    person.Emai = this.state.reginfo.Emai;
    person.Pass = this.state.reginfo.Passwd;
    person.Type = Constant.TP_PERSON;
    person.BDay = "01/01/1970";

    const response = await userRegister(person);
    if (response.Id == 0) {
      let data = JSON.parse(response.Data);

      this.setState({ loading: false });
      ssm_setcookie("ssm_sid", data.SId, 1);
      this.props.onLoginClick(data);
    } else {
      this.setState({ loading: false, errormsg: response.Description });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg msg={this.state.errormsg} onErrOkay={this.handleErrOkay} />
        ) : (
          <div className="content-area">
            <section id="login-container">
              <div className="container-fluid">
                <div id="login-title" className="row">
                  <div id="empty-cart-title" className="col-12">
                    <p className="login-title">Create an account</p>
                  </div>
                </div>
                <div id="login-form-container" className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <input
                      className="form-control login-inputs"
                      type="text"
                      name="FName"
                      placeholder="First Name"
                      onChange={this.handleChange}
                      maxLength={64}
                    />
                    {ssm_valid.showerror("FName", this.errors)}
                    <input
                      className="form-control login-inputs"
                      type="text"
                      name="LName"
                      placeholder="Last Name"
                      onChange={this.handleChange}
                      maxLength={64}
                    />
                    {ssm_valid.showerror("LName", this.errors)}
                    <input
                      className="form-control login-inputs"
                      type="text"
                      name="Emai"
                      placeholder="Email"
                      onChange={this.handleChange}
                      maxLength={50}
                    />
                    {ssm_valid.showerror("Emai", this.errors)}
                    <input
                      className="form-control login-inputs"
                      type="text"
                      name="Emai2"
                      placeholder="Confirm Email"
                      onChange={this.handleChange}
                      maxLength={50}
                    />
                    {ssm_valid.showerror("Emai2", this.errors)}
                    <input
                      className="form-control login-inputs"
                      type="password"
                      name="Passwd"
                      maxLength={18}
                      placeholder="Password"
                      onChange={this.handleChange}
                    />
                    {ssm_valid.showerror("Passwd", this.errors)}
                    <input
                      className="form-control login-inputs"
                      type="password"
                      name="Passwd2"
                      maxLength={18}
                      placeholder="Confirm Password"
                      onChange={this.handleChange}
                    />
                    {ssm_valid.showerror("Passwd2", this.errors)}
                    <div className="form-actions-area">
                      <a>
                        <button
                          onClick={this.handleRegisterClick}
                          className="filled-button-continue"
                          type="button"
                        >
                          Register
                        </button>
                      </a>
                    </div>
                    {this.state.registered.toString() === "1" ? (
                      <div id="not-member" classNameName="row">
                        <div className="col-12">
                          <p>
                            <a
                              onClick={this.handleForget}
                              id="forgotten-password"
                            >
                              Forgot password?
                            </a>
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-1"></div>
                </div>
                <div id="not-member" className="row">
                  <div className="col-12">
                    <p>
                      Have an account?{" "}
                      <a onClick={this.handleLoginClick} className="text-link">
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default RegisterAccount;