import { fetchAPIWithID } from "../config";

export async function getRegmanObject(AId, AType) {
  let req = {};
  req.Guid = AId;
  req.Type = AType + 500;

  const data = await fetchAPIWithID("/ssm_api_user/getmyregmanobj", req);
  return data;
}
