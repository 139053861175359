import React from "react";

import { ssm_getcookie } from "../../common/utils/ssm_util";
import Spinner from "../../common/components/Spinner";
import { getContactInformation } from "../../services/contact/getContactInformation";
import { getMainPhone } from "../../common/utils/organization";

class ContactUS extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orginfo: {} };
  }

  orgid = 0;

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
    this.getOrgContactinfo();
  }

  async getOrgContactinfo() {
    this.setState({ loading: true });
    const response = await getContactInformation(this.orgid);
    this.setState({ orginfo: response, loading: false });
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <section className="container" id="successful-payment">
            <div className="card m-4 pb-4">
              <div className="row">
                <div className="col-12 tacenter">
                  <p className="success-message">CONTACT US</p>
                  <p className="contactbold conape">Address:</p>
                  <p className="conape">{this.state.orginfo.Addrs[0].Addr1}</p>
                  <p className="conape">
                    {this.state.orginfo.Addrs[0].City +
                      " " +
                      this.state.orginfo.Addrs[0].Regi +
                      " " +
                      this.state.orginfo.Addrs[0].PCode}
                  </p>
                  <br></br>
                  <p className="conape">
                    <span className="contactbold ">Phone</span>:
                    {getMainPhone(this.state.orginfo.Phones).Num}
                  </p>
                  <p className="conape">
                    <span className="contactbold">Email</span>:
                    <a href={"mailto:" + this.state.orginfo.Org.Emai}>
                      {this.state.orginfo.Org.Emai}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default ContactUS;