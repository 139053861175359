import { fetchAPI } from "../config";

export async function getAnnouncementsOrg(body) {
  const data = await fetchAPI("/ssm_api_tree/getannoucements2org", body);
  return data;
}

export async function getAnnouncementsAct(body) {
  const data = await fetchAPI("/ssm_api_tree/getannoucements2act", body);
  return data;
}
