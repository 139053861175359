import { fetchAPINoID } from "../config";

export async function checkEmailExist(value) {
  let req = {};
  req.Str = value.toLowerCase();
  req.Type = 0;

  const data = await fetchAPINoID("/ssm_api_user/checkemailexist", req);
  return data;
}
