import Constant from "../../common/constants/define";
import { fetchAPIWithID } from "../config";

export async function deleteStoreItem(pcd) {
    let req = {};
    req.Guid = pcd.PCDId;
    req.Pcd = pcd;
    req.Items = [];
    req.Type = 0;
    req.PGuid = Constant.SSM_ROOTID;  
    const data = await fetchAPIWithID("/ssm_api_store/deletestoreitem", req);
    return data;
}