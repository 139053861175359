import Constant from "../../common/constants/define";
import { fetchAPIWithID } from "../config";

export async function checkCode(code, id, oid) {
  let codeObject = {};
  codeObject.Code = code;
  codeObject.Type = Constant.TP_PRER;
  codeObject.orgId = id;
  codeObject.OId = oid;
  const data = fetchAPIWithID("/ssm_api_store/checkcode", codeObject);
  return data;
}
