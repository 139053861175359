export const checkCampsiteFilter = (campsite, filter) => {
  if (campsite == null) return false;
  let len = filter.Length;
  let pow = filter.Power;
  let wat = filter.Water;
  let sew = filter.Sewer;
  let tab = filter.Table;
  let fir = filter.Fire;
  if (campsite.Len < len) {
    return false;
  } else if (campsite.Pow < pow) {
    return false;
  } else if (campsite.Wat < wat) {
    return false;
  } else if (campsite.Sew < sew) {
    return false;
  } else if (campsite.Tab < tab) {
    return false;
  } else if (campsite.Fir < fir) {
    return false;
  }
  return true;
};
