import React from "react";
import Constant from "../../../../common/constants/define";
import { formatDateEN, formatDateENGB } from "../../../../common/utils/date";

class MyStuffSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ofces: this.props.ofces, node: this.props.node };
  }

  getScheduleItem_v(fce) {
    if (
      fce.HmId != this.state.node.Group?.GId &&
      fce.GuId != this.state.node.Group?.GId &&
      this.state.node.Act.AType != Constant.TP_CLASS &&
      this.state.node.Act.AType != Constant.TP_EVENT
    ) {
      return;
    }

    let d = formatDateENGB(fce.Start);
    let start = formatDateEN(fce.Start);

    return (
      <div className="row purchases-item mx-1">
        <div className="col-12">
          <div className="row">
            <div className="col-3 purchased-class-container pl-4">
              <p className="cart-item-info">
                <span id="class-type">{d} </span>
              </p>
            </div>
            <div className="col-4 purchased-class-container schelisttime">
              <p className="cart-item-info">
                <span id="class-type">{start} </span>
              </p>
            </div>
            <div className="col-5 purchased-class-container">
              <p className="cart-item-info actschname">
                <span id="class-type">
                  <span dangerouslySetInnerHTML={{ __html: fce.Name }} />{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getScheduleList_v() {
    const items = [];
    let nowdate = new Date().toJSON().slice(0,10).split('-').reverse().join('/');
    for (let i = 0; i < this.state.ofces.length; i++) {
      let fce = this.state.ofces[i];
      let fcedate = new Date(fce.Start).toJSON().slice(0,10).split('-').reverse().join('/');
      if (fcedate >= nowdate) {
        items.push(this.getScheduleItem_v(this.state.ofces[i]));
      }
    }

    return (
      <>
        <div className="d-flex justify-content-between align-items-center schedule-header rounded">
          <span className="schedule-title-date d-flex justify-content-center">
            Date
          </span>
          <span className="schedule-title-time d-flex justify-content-center">
            Time
          </span>
          <span className="schedule-title-detail d-flex justify-content-center pl-4">
            Detail
          </span>
        </div>
        {items}
      </>
    );
  }

  render() {
    return <div>{this.getScheduleList_v()}</div>;
  }
}

export default MyStuffSchedule;