import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { useMediaQuery } from "react-responsive";

const BackTitle = ({ title, onBack }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 850px)",
  });

  return (
    <div  className={`d-flex flex-row pb-2 ${isDesktopOrLaptop && "container"}`}>
      <span onClick={onBack} className="d-flex align-items-start mr-3 mt-1">
        <ArrowBack sx={{ fontSize: 22 }}/>
      </span>
      <span className="text-title">{title}</span>
    </div>
  );
};

export default BackTitle;
