import React from "react";
import Spinner from "../../../common/components/Spinner";
import EditProfile from "./editprofile";
import EditPassword from "./editpassword";
import Constant from "../../../common/constants/define";
import { Logout, AccountCircle } from "@mui/icons-material";

class PersonDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: this.props.person,
      family: this.props.family,
      loading: false,
      toGo: 0,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handlePasswordClick = this.handlePasswordClick.bind(this);
  }

  handleBackClick() {
    this.setState({ toGo: 0 });
  }
  handleSaveClick(person) {
    this.setState({ person: person, toGo: 0 });
  }
  handleEditClick() {
    this.setState({ toGo: 1 });
  }
  handlePasswordClick() {
    this.setState({ toGo: 2 });
  }

  renderOnlyDate(date) {
    if (date) {
      return date.split(" ")[0];
    } else {
      return "";
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.toGo == 1 ? (
          <EditProfile
            person={this.state.person}
            family={this.state.family}
            onBackClick={this.handleBackClick}
            onSaveClick={this.handleSaveClick}
            isFamily={this.props.isFamily}
          />
        ) : this.state.toGo == 2 ? (
          <EditPassword
            person={this.state.person}
            onBackClick={this.handleBackClick}
          />
        ) : (
          <div className="content-area">
            <section id="family-member-profile">
              <div className="card pb-2 container-fluid">
                <div id="member-profile-header" className="row">
                  <div className="col-12">
                    {this.state.person.Img ? (
                      <img
                        src={`https://s3-us-west-2.amazonaws.com/sportsites-filer${this.state.person.Img}`}
                        className="profile-img"
                      />
                    ) : (
                      <AccountCircle
                        htmlColor="#393939"
                        sx={{ fontSize: 64 }}
                      />
                    )}
                    <p id="family-member-name" className="family-member-pname">
                      {this.state.person.FName + " " + this.state.person.LName}
                    </p>
                  </div>
                  <a>
                    <button
                      onClick={this.props.onLogout}
                      className="d-flex justify-content-center align-items-center btn logout-btn"
                    >
                      <Logout
                        htmlColor="#fff"
                        sx={{ fontSize: 18 }}
                        className="icon-logout"
                      />
                    </button>
                  </a>
                </div>
                <div
                  id="family-member-pinfo"
                  className="row family-member-pinfo"
                >
                  <div className="col-12">
                    <p className="list-title-tag">Personal Information</p>
                    <p className="info-label">Full Name</p>
                    <p className="sline-item-name">
                      {this.state.person.FName + " " + this.state.person.LName}
                    </p>
                    <p className="info-label">Email</p>
                    <p className="sline-item-name">{this.state.person.Emai}</p>
                    <p className="info-label">Date of Birth</p>
                    <p className="sline-item-name">
                      {this.renderOnlyDate(this.state.person.BDay)}
                    </p>
                    <p className="info-label">Gender</p>
                    {this.state.person.Gend == "1" ? (
                      <>
                        <img width="24" src="img/cg-male.svg" alt="male" />
                        <span className="class-gender">Male</span>
                      </>
                    ) : (
                      <>
                        <img width="24" src="img/cg-female.svg" alt="female" />
                        <span className="class-gender">Female</span>
                      </>
                    )}
                  </div>
                </div>
                <div
                  id="family-member-addressinfo"
                  className="row family-member-addressinfo"
                >
                  <div className="col-12">
                    <p className="list-title-tag">Address Information</p>
                    <p className="info-label">Address</p>
                    <p className="sline-item-name">{this.state.person.Addr}</p>
                    <p className="info-label">City</p>
                    <p className="sline-item-name">{this.state.person.City}</p>
                    <p className="info-label">State</p>
                    <p className="sline-item-name">{this.state.person.Regi}</p>
                    <p className="info-label">Postal Code</p>
                    <p className="sline-item-name">{this.state.person.PCode}</p>
                  </div>
                </div>
                <div
                  id="family-member-addressinfo"
                  className="row family-member-addressinfo"
                >
                  <div className="col-12">
                    <p className="list-title-tag">Phone Information</p>
                    <p className="sline-item-name">{this.state.person.Num}</p>
                  </div>
                </div>
                <p className="info-label">Emergency Contact</p>
                <p className="sline-item-name">{this.state.person.EName}</p>
                <p className="info-label">Emergency Phone</p>
                <p className="sline-item-name">{this.state.person.ENumber}</p>
              </div>
            </section>
            <div className="form-actions-area">
              <a>
                <button
                  onClick={this.handleEditClick}
                  className="filled-button-continue2"
                  type="button"
                >
                  Edit
                </button>
              </a>
              <a>
                <button
                  onClick={this.handlePasswordClick}
                  className="filled-button-continue2"
                  type="button"
                >
                  Change Password
                </button>
              </a>
              <a>
                <button
                  onClick={this.props.onLogout}
                  className="filled-button-continue2"
                  type="button"
                >
                  Logout
                </button>
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PersonDetail;
