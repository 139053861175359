import { fetchAPI } from "../config";

export async function userLogin(id, email, password) {
  let logininfo = {};
  logininfo.orgid = id;
  logininfo.email = email;
  logininfo.passwd = password;
  logininfo.type = 16;
  let jsonstr = JSON.stringify(logininfo);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = "00000000-0000-0000-0000-000000000000";
  const para = { jsonstr, sid };
  const data = await fetchAPI("/ssm_api_user/Login4Mobile", para);
  return data;
}
