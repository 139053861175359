import React from "react";

import CartItem from "./cart_item";

class CartList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { pcds: this.props.pcds, isCountChange: false };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleUpCountClick = this.handleUpCountClick.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({ pcds: props.pcds });
  }

  handleDeleteClick(item) {
    this.props.onDeleteClick(item);
  }
  handleUpCountClick(pcdid, count, type, selcnt, newpcds) {
    this.props.onUpCountClick(pcdid, count, type, selcnt, newpcds);
  }

  render() {
    return this.state.pcds.map((n) => {
      return (
        <CartItem
          onDeleteClick={() => {
            this.handleDeleteClick(n);
          }}
          OnUpCountClick={this.handleUpCountClick}
          pcd={n}
          key={n.PCDId}
          handleStoreData={this.props.handleStoreData}
          items={n.Items}
        />
      );
    });
  }
}

export default CartList;
