import React from "react";
import ssm_valid from "../../../../../common/utils/ssm_valid";
import { ssm_getcookie } from "../../../../../common/utils/ssm_util";
import ErrorMsg from "../../../../../common/components/ErrorMsg";
import Spinner from "../../../../../common/components/Spinner";
import Checkbox from "../../../../../common/components/register-checkbox";
import { sendMessage } from "../../../../../services/activities/sendMessage";
import { getPerson, getPersonsName } from "../../../../../common/utils/family";

class Communicate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myroster: this.props.myroster,
      loading: false,
      mypid: this.props.mypid,
      commuinfo: {},
      errormsg: "",
      errorflag: 0,
      email: false,
      text: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
  }

  requireds = [];
  errors = {};
  orgid = 0;

  componentDidMount() {
    this.requireds.push("Title");
    this.requireds.push("Content");
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  get_msg() {
    let obj = new Object();
    obj.Comm = new Object();
    obj.CommRes = [];
    obj.Comm.OrgId = this.orgid;
    obj.Comm.Subj = this.state.commuinfo.Title;
    obj.Comm.Mess = this.state.commuinfo.Content;
    obj.Files = [];
    let p = getPerson(this.state.mypid, this.state.myroster);
    obj.Comm.SeId = this.state.mypid;
    obj.Comm.SeName = p.FName + " " + p.LName;
    obj.Comm.SeMail = p.Emai;
    obj.ToType = 1;

    if (this.state.email === true) {
      obj.SeledType = "0";
    }

    if (this.state.text === true) {
      obj.SeledType = "1";
    }

    if (this.state.text === true && this.state.email === true) {
      obj.SeledType = "0,1";
    }

    obj.Filter = "10,12,15,38,39,11,13,25,33,34,37";

    obj.PerList = [];
    for (let i in this.state.myroster) {
      obj.PerList.push(this.state.myroster[i].Person);
    }

    obj.ObjList = [];
    return obj;
  }

  handleBackClick() {
    this.props.onBackClick();
  }

  handleErrOkay() {
    if (this.state.errorflag == 1) this.setState({ errormsg: "" });
    else this.props.onBackClick();
  }

  handleSendClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.commuinfo,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.sendMessage();
  }

  async sendMessage() {
    let msg = this.get_msg();
    let desmg = msg.Comm.Mess;
    msg.Comm.Mess = "";
    const response = await sendMessage(msg, desmg);
    if (response.Id == 0) {
      this.setState({ loading: false, errormsg: "Sent", errorflag: 0 });
    } else {
      this.setState({
        loading: false,
        errormsg: response.Description,
        errorflag: 1,
      });
    }
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;
    this.setState((prevState) => ({
      commuinfo: {
        ...prevState.commuinfo,
        [name]: val,
      },
    }));
  };

  render() {
    let p = getPerson(this.state.mypid, this.state.myroster);
    this.state.commuinfo.From = p.FName + " " + p.LName;
    this.state.commuinfo.To = getPersonsName(this.state.myroster);
    let msg = this.get_msg();
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg
            msg={this.state.errormsg}
            flag={this.state.errorflag}
            onErrOkay={this.handleErrOkay}
          />
        ) : (
          <div id="cd-participant-container">
            <p className="info-label">Send as</p>
            <Checkbox
              id="Email"
              label="Email"
              isSelected={this.state.email}
              onCheckboxChange={() => {
                this.setState({ email: !this.state.email });
              }}
              key="Email"
            />
            <Checkbox
              id="Text"
              label="Text"
              isSelected={this.state.text}
              onCheckboxChange={() => {
                this.setState({ text: !this.state.text });
              }}
              key="Text"
            />
            <p className="info-label">From</p>
            <p className="sline-item-name">{this.state.commuinfo.From}</p>
            <p className="info-label">To</p>
            <p className="sline-item-name">{this.state.commuinfo.To}</p>
            <p className="info-label">Subject</p>
            <input
              className="form-control edit-fm-inputs"
              type="text"
              name="Title"
              value={this.state.commuinfo.Title}
              onChange={this.handleChange}
              maxLength={128}
            />
            {ssm_valid.showerror("Title", this.errors)}
            <p className="info-label">Message</p>
            <textarea
              rows={4}
              className="form-control ssmsgbox"
              type="text"
              name="Content"
              value={this.state.commuinfo.Content}
              onChange={this.handleChange}
            />
            {ssm_valid.showerror("Content", this.errors)}
            <div className="form-actions-area">
              <a>
                <button
                  onClick={this.handleSendClick}
                  className="filled-button-continue"
                  type="button"
                >
                  Send
                </button>
              </a>
              <a>
                <button
                  onClick={this.handleBackClick}
                  className="text-button-cancel"
                  type="button"
                >
                  Cancel
                </button>
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Communicate;