import { ssm_getcookie } from "../../common/utils/ssm_util";
import Constant from "../../common/constants/define";
import { fetchAPI, fetchAPINoID, fetchAPIWithID } from "../config";

export async function getNode(oid, otype) {
  let req = {};
  req.Guid = oid;
  req.Type = otype;
  req.Flag = 2;
  const data = await fetchAPIWithID("/ssm_api_node/getnode", req);
  return data;
}

export async function preReserveNode(id, fces) {
  let rq = {};
  rq.Type = id;
  rq.Sches = fces;
  const data = await fetchAPIWithID("/ssm_api_node/MPreReserve", rq);
  return data;
}

export async function getVenueSlots(oid, otype) {
  let req = {};
  req.Guid = oid;
  req.Type = otype;

  let Allinfo = {};
  Allinfo.Venue = new Buffer(JSON.stringify(req)).toString("base64");
  Allinfo.Slots = new Buffer(JSON.stringify(req)).toString("base64");
  let jsonstr = new Buffer(JSON.stringify(Allinfo)).toString("base64");
  let sid = ssm_getcookie("ssm_sid");
  const para = { jsonstr, sid };

  const data = await fetchAPI("/ssm_api_node/getvenueandslots", para);
  return data;
}

export async function getNodeAnnouncement(id, oid, flag) {
  let req = {};
  req.PId = 0;
  req.OrgId = id;
  if (flag == 0) {
    req.OId = Constant.SSM_ROOTID;
  } else if (flag == 1) {
    req.OId = oid;
  }
  req.Flag = flag;
  const data = await fetchAPINoID("/ssm_api_tree/getannoucements2org", req);
  let response = JSON.parse(data.Data);
  return response;
}