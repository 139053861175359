import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

class SSCalendar extends React.Component {
  constructor(props) {
    super(props);
    var now = new Date();
    this.state = {
      smallscreen: true,
      FC_Fces: this.props.fces,
      monthview: true,
      currentDate: now,
      currentMonth: now.getMonth(),
      currentYear: now.getFullYear(),
    };

    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleMonthClick = this.handleMonthClick.bind(this);
    this.handleWeekClick = this.handleWeekClick.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleWDclick = this.handleWDclick.bind(this);
    this.renderMonthName = this.renderMonthName.bind(this);
  }

  g_MonthName(month) {
    var rt;
    switch (month) {
      case 0:
        rt = "January";
        break;
      case 1:
        rt = "February";
        break;
      case 2:
        rt = "March";
        break;
      case 3:
        rt = "April";
        break;
      case 4:
        rt = "May";
        break;
      case 5:
        rt = "June";
        break;
      case 6:
        rt = "July";
        break;
      case 7:
        rt = "August";
        break;
      case 8:
        rt = "September";
        break;
      case 9:
        rt = "October";
        break;
      case 10:
        rt = "November";
        break;
      case 11:
        rt = "December";
        break;
    }

    return rt + " " + this.state.currentYear;
  }

  g_DayName(day) {
    var rt;
    switch (day) {
      case 1:
        rt = "M";
        break;
      case 2:
        rt = "T";
        break;
      case 3:
        rt = "W";
        break;
      case 4:
        rt = "T";
        break;
      case 5:
        rt = "F";
        break;
      case 6:
        rt = "S";
        break;
      case 0:
        rt = "S";
        break;
    }
    return rt;
  }

  calendarRef = React.createRef();

  componentDidMount() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("dayGridMonth");
    if (this.props.initialDate) {
      if (this.props.initialDate.toString() !== "Invalid Date") {
        var month_int = new Date(this.props.initialDate).getMonth();
        var year_int = new Date(this.props.initialDate).getFullYear();
        this.setState({
          currentMonth: month_int,
          currentYear: year_int,
        });
        calendarApi.gotoDate(this.props.initialDate);
      }
    }

    this.updateDimensions();
  }

  g_ssTime(val) {
    var s = new Date(val);
    var d = s.getDate();
    var m = s.getMonth() + 1;
    var y = s.getFullYear();
    return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
  }

  handleEventClick(e) {
    if (this.props.oneventclick == null || this.state.monthview == true) {
      let calendarApi = this.calendarRef.current.getApi();
      var month_int;
      var year_int;

      calendarApi.changeView("timeGridDay", e.event.start);
      month_int = new Date(e.event.start).getMonth();
      year_int = new Date(e.event.start).getFullYear();

      this.setState({
        monthview: false,
        currentMonth: month_int,
        currentYear: year_int,
        currentDate: e.event.start,
      });
      this.ref_fcfces(false, this.props.fces);
    } else {
      this.props.oneventclick(e);
    }
  }

  handleDateClick = (arg) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay", arg.dateStr);
    var month_int = arg.date.getMonth();
    var year_int = arg.date.getFullYear();
    this.setState({
      monthview: false,
      currentMonth: month_int,
      currentYear: year_int,
      currentDate: arg.date,
    });
    this.ref_fcfces(false, this.props.fces);
  };

  handleMonthClick() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("dayGridMonth");

    this.setState({ monthview: true });
    this.ref_fcfces(true, this.props.fces);
  }

  handleWeekClick() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridWeek");
  }

  handleDayClick() {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay");
  }

  handlePrevClick() {
    let calendarApi = this.calendarRef.current.getApi();
    if (this.state.monthview) {
      calendarApi.prev();
    } else {
      var old = calendarApi.getDate();
      old = this.addDays(old, -7);
      calendarApi.gotoDate(old);
    }

    this.getcurrentMonth();
    var date = calendarApi.getDate();
    this.setState({ currentDate: date });
  }

  handleNextClick() {
    let calendarApi = this.calendarRef.current.getApi();
    if (this.state.monthview) {
      calendarApi.next();
    } else {
      var old = calendarApi.getDate();
      old = this.addDays(old, 7);
      calendarApi.gotoDate(old);
    }

    this.getcurrentMonth();
    var date = calendarApi.getDate();
    this.setState({ currentDate: date });
  }

  handleWDclick = (val) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.changeView("timeGridDay", val);
    var date = calendarApi.getDate();
    var month_int = date.getMonth();
    var year_int = date.getFullYear();

    this.setState({
      currentMonth: month_int,
      currentYear: year_int,
      currentDate: val,
    });
  };

  getcurrentMonth() {
    let calendarApi = this.calendarRef.current.getApi();
    var date = calendarApi.getDate();
    var month_int = date.getMonth();
    var year_int = date.getFullYear();

    this.setState({ currentMonth: month_int, currentYear: year_int });
  }

  componentDidUpdate() {
    if (this.state.node) {
      if (document.getElementsByClassName("fc-scroller").length > 0) {
      }
    }

    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillReceiveProps(props) {
    this.ref_fcfces(this.state.monthview, props.fces);
  }

  componentWillMount() {
    this.ref_fcfces(true, this.props.fces);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  ex_FCdays(start, events) {
    for (var i in events) {
      if (this.g_ssTime(start) == this.g_ssTime(events[i].start)) {
        return true;
      }
    }
    return false;
  }

  ref_fcfces(monthview, fces) {
    if (monthview !== false) {
      for (var i in fces) {
        fces[i].color = "#378006";
      }
    }

    if (monthview === false) {
      for (var i in fces) {
        if (fces[i].title === "Unavailable") {
          fces[i].color = "gray";
        }
      }
    }

    if (monthview == true) {
      let events = fces;
      let events2 = [];
      for (var i in events) {
        if (!this.ex_FCdays(events[i].start, events2)) events2.push(events[i]);
      }
      this.setState({ FC_Fces: events2 });
    } else {
      this.setState({ FC_Fces: fces });
    }
  }

  up_CalenderFormat() {}

  updateDimensions() {
    if (window.innerWidth < 500) {
      if (this.state.smallscreen == false) this.setState({ smallscreen: true });
    } else {
      if (this.state.smallscreen == true) this.setState({ smallscreen: false });
    }
  }

  iswdActive(val) {
    var date = new Date(this.state.currentDate);
    var day = date.getDay();

    return val === day ? "wdactive" : "wdnormal";
  }

  addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  getWeekView() {
    var date = new Date(this.state.currentDate);
    var day = date.getDay();
    var diff = 0 - day;
    var date1 = this.addDays(date, 0 + diff);
    var date2 = this.addDays(date, 1 + diff);
    var date3 = this.addDays(date, 2 + diff);
    var date4 = this.addDays(date, 3 + diff);
    var date5 = this.addDays(date, 4 + diff);
    var date6 = this.addDays(date, 5 + diff);
    var date7 = this.addDays(date, 6 + diff);
    return (
      <div class="row">
        <div class="ss_col_1">
          <a
            onClick={() => {
              this.handlePrevClick(this.state.node);
            }}
            class="cal-button2"
          >
            <img src="/img/arrow-back-icon.svg" class="wdcal-img"></img>
          </a>
        </div>

        <div class="ss_col_1" onClick={() => this.handleWDclick(date1)}>
          <span class="dayofwd">{this.g_DayName(date1.getDay())}</span>
          <br />
          <div class={this.iswdActive(0)}>{date1.getDate()}</div>
        </div>
        <div class="ss_col_1" onClick={() => this.handleWDclick(date2)}>
          <span class="dayofwd">{this.g_DayName(date2.getDay())}</span>
          <br />
          <div class={this.iswdActive(1)}>{date2.getDate()}</div>
        </div>
        <div class="ss_col_1" onClick={() => this.handleWDclick(date3)}>
          <span class="dayofwd">{this.g_DayName(date3.getDay())}</span>
          <br />
          <div class={this.iswdActive(2)}>{date3.getDate()}</div>
        </div>
        <div class="ss_col_1" onClick={() => this.handleWDclick(date4)}>
          <span class="dayofwd">{this.g_DayName(date4.getDay())}</span>
          <br />
          <div class={this.iswdActive(3)}>{date4.getDate()}</div>
        </div>
        <div class="ss_col_1" onClick={() => this.handleWDclick(date5)}>
          <span class="dayofwd">{this.g_DayName(date5.getDay())}</span>
          <br />
          <div class={this.iswdActive(4)}>{date5.getDate()}</div>
        </div>
        <div class="ss_col_1" onClick={() => this.handleWDclick(date6)}>
          <span class="dayofwd">{this.g_DayName(date6.getDay())}</span>
          <br />
          <div class={this.iswdActive(5)}>{date6.getDate()}</div>
        </div>
        <div class="ss_col_1" onClick={() => this.handleWDclick(date7)}>
          <span class="dayofwd">{this.g_DayName(date7.getDay())}</span>
          <br />
          <div class={this.iswdActive(6)}>{date7.getDate()}</div>
        </div>
        <div class="ss_col_1">
          <a
            onClick={() => {
              this.handleNextClick(this.state.node);
            }}
            class="cal-button3"
          >
            <img src="/img/arrow-forward-icon.svg" class="wdcal-img"></img>
          </a>
        </div>
      </div>
    );
  }

  renderMonthName() {
    return this.g_MonthName(this.state.currentMonth);
  }

  render() {
    return (
      <div class="mb-4">
        <div class="tacenter">
          <div>
            {this.state.monthview ? (
              <div></div>
            ) : (
              <span>
                <button
                  onClick={() => {
                    this.handleMonthClick();
                  }}
                  class="filled-button cal-button4"
                >
                  {this.renderMonthName()}
                </button>
              </span>
            )}
          </div>
          {this.state.smallscreen ? (
            <div>
              {this.state.monthview ? (
                <div class="row">
                  <div class="ss_col_1">
                    <a
                      onClick={() => {
                        this.handlePrevClick(this.state.node);
                      }}
                      class="cal-button2"
                    >
                      <img src="/img/arrow-back-icon.svg" class="cal-img"></img>
                    </a>
                  </div>
                  <div class="ss_col_8">{this.renderMonthName()}</div>
                  <div class="ss_col_1">
                    <a
                      onClick={() => {
                        this.handleNextClick(this.state.node);
                      }}
                      class="cal-button3"
                    >
                      <img
                        src="/img/arrow-forward-icon.svg"
                        class="cal-img"
                      ></img>
                    </a>
                  </div>
                </div>
              ) : (
                <div>{this.getWeekView()}</div>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <FullCalendar
          defaultView="timeGridDay"
          headerToolbar={
            this.state.smallscreen == true
              ? { left: "", center: "", right: "" }
              : {
                  left: "dayGridMonth,timeGridWeek,timeGridDay",
                  center: "title",
                  right: "today,prev,next",
                }
          }
          ref={this.calendarRef}
          plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
          events={this.state.FC_Fces}
          dateClick={this.handleDateClick}
          eventClick={this.handleEventClick}
          eventColor="#378006"
          eventMinHeight={20}
          eventTextColor="white"
          displayEventEnd="true"
          slotMinWidth={100}
          slotEventOverlap={false}
          allDaySlot={false}
          minTime="10:00:00"
          maxTime="18:00:00"
          height="600px"
          displayEventTime={this.state.monthview ? false : true}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
        />
      </div>
    );
  }
}

export default SSCalendar;
