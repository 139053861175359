import React from "react";

const Radiobtn = ({ label1, label2, req, value, onRadioChange }) => {
  var img1 = value == "1" ? "/img/radioon.png" : "/img/radiooff.png";
  var img2 = value == "2" ? "/img/radioon.png" : "/img/radiooff.png";
  return (
    <div class="ssoptbox">
      <label id="1" class="radopt1" onClick={onRadioChange}>
        <img id="1" class="sscheckbox" src={img1} /> {label1}
      </label>
      <label id="2" class="radopt2" onClick={onRadioChange}>
        <img id="2" class="sscheckbox" src={img2} /> {label2}
        {req}
      </label>
    </div>
  );
};

export default Radiobtn;
