import React from "react";
import { ssm_getcookie } from "../../../common/utils/ssm_util";
import SSCalendar from "../../../common/components/SSCalendar";
import Spinner from "../../../common/components/Spinner";
import {htmlToText} from "../../../common/utils/utils"

class MyCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fces: this.g_FCfces(this.props.fces),
      initialDate: "",
    };
  }

  orgid = 0;

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  g_FCfces(fces) {
    let fcfces = [];
    for (let i in fces) {
      let f = new Object();
      f.id = fces[i].SCId;
      f.start = new Date(fces[i].Start);
      f.end = new Date(fces[i].End);
      let titleHtml = fces[i].Name;
      let strippedHtml = htmlToText(titleHtml);
      f.title = fces[i].Desc.replace(/<[^>]+>/g, "");
      if (strippedHtml[1] === undefined && strippedHtml[2] === undefined) {
        f.title = fces[i].Desc.replace(/<[^>]+>/g, "");;
      } else if ( strippedHtml[2] === undefined) {
        f.title = `${fces[i].Desc.replace(/<[^>]+>/g, "")}  ${strippedHtml[1]}`;
      } else {
        f.title = `${fces[i].Desc.replace(/<[^>]+>/g, "")}  ${strippedHtml[1]}  -  ${strippedHtml[2]}`;
      }
      fcfces.push(f);
    }
    return fcfces;
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <>
            <SSCalendar fces={this.state.fces} />
          </>
        )}
      </div>
    );
  }
}

export default MyCalendar;