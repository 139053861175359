import { fetchAPI } from "../config";

function get_forget() {
  let html =
    "<p>You recently requested to reset your password for your SportSites account. Click the button below to reset it.</p>";
  html +=
    '<p><a href="mobile.sportsites.com?rpw=SIDSTR"><button>Change Password</button></a></p>';
  html +=
    "<p>If you are having trouble clicking the password reset button, copy and paste the URL below into your web browser.</p>";
  html +=
    '<p style="font-size:16px;color:blue;">mobile.sportsites.com?rpw=SIDSTR</p>';
  html +=
    "<p>If you did not request a password reset, please disregard this email or reply to let us know. This password reset is only valid for the next 48 hours.</p>";
  html += "<p>Sincerely,</p>";
  html += "<p>SportSites Customer Care.</p>";

  return html;
}

export async function forgetPassword(email) {
  let req = {};
  req.email = email;
  req.femail = "Sportsites.com";
  req.Html = get_forget();

  let jsonstr = JSON.stringify(req);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = "00000000-0000-0000-0000-000000000000";
  const para = { jsonstr, sid };
  const data = await fetchAPI("/ssm_api_user/forgetpassword", para);
  return data;
}
