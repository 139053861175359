import React from "react";
import NodeList from "../node-list";
import Constant from "../../../common/constants/define";
import BackTitle from "../../../common/components/back/BackTitle";
import { getAnnouncementDetail } from "../../../services/common/getAnnouncementMessage";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../common/components/modal";
import { selectNodeById } from "../../../services/home/selectNodeById";
import {
  filterMxPos,
  getChildNode,
  getNodeFromTree,
} from "../../../common/utils/node";

class NodeListBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nodes: this.props.nodes,
      node: this.props.node,
      height: 0,
      message: "",
      announcements: this.props.announcements,
      annmsg: "",
      modal: false,
    };

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const height = this.divElement.clientHeight;
    this.setState({ height });
    this.getNodeDescription(this.state.node.OId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.height !== this.state.height) {
      const height = this.divElement.clientHeight;
      this.setState({ height });
    }

    if (prevState.node !== this.state.node) {
      this.getNodeDescription(this.state.node.OId);
    }

    if (prevState.annmsg !== this.state.annmsg) {
      if (this.state.annmsg !== "") {
        this.setState({ modal: true });
      }
    }
  }

  async getNodeDescription(OId) {
    const response = await selectNodeById(OId);
    this.setState({ message: response.Anous[0]?.Mess });
  }

  componentWillReceiveProps(props) {
    let oid;
    let uloc = window.location.toString();
    let isfolder = uloc.indexOf("/folder#");
    
    if (isfolder > 0) {
      oid = uloc.substr(isfolder + 8, 36);
      let childs = getChildNode(oid, this.props.smalltree);
      let node = getNodeFromTree(this.props.smalltree, oid);
      this.setState({ nodes: childs, node: node });
    }
  }

  componentWillMount() {
    let oid;
    let uloc = window.location.toString();
    let isfolder = uloc.indexOf("/folder#");

    if (isfolder > 0) {
      oid = uloc.substr(isfolder + 8, 36);
      let childs = getChildNode(oid, this.props.smalltree);
      let node = getNodeFromTree(this.props.smalltree, oid);
      this.setState({ nodes: childs, node: node });
    }
  }

  handleItemClick(item) {
    if ((item.App & Constant.APP_MAPDEF) == Constant.APP_MAPDEF) {
      this.setState({ node: item });
      this.props.onNavigate(`/campsite#${item.OId}`);
      return;
    }

    if (item.OType == 1) {
      this.props.onNavigate(`/folder#${item.OId}`);
    } else {
      this.props.onNavigate(`/node#${item.OId}`);
    }
  }

  handleBackClick(node) {
    if (node.PId == "00000000-0000-0000-0000-000000000000") {
      this.props.onNavigate("/");
    } else {
      this.props.onNavigate(`/folder#${node.PId}`);
    }
  }

  async handleAnnouncementClick(id) {
    getAnnouncementDetail(id).then((response) => {
      this.setState({ annmsg: response });
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal, annmsg: "" });
  }

  getAnnouncementItem(obj) {
    return (
      <div
        className="ann-msg"
        onClick={() => this.handleAnnouncementClick(obj.AId)}
      >
        <a className="card p-2 d-flex flex-row align-items-center mb-2 anou2">
          <img className="annimg" src="/img/mess.gif" />
          <div className="announcement-text">{obj.Name}</div>
        </a>
      </div>
    );
  }

  renderAnnouncements(announcements, message) {
    if (announcements?.length == 0) return "";
    const msg = [];
    msg.push(<h8 className="ann-label">ANNOUNCEMENTS</h8>);

    for (let i = 0; i < announcements?.length; i++) {
      announcements[i].DFlag = 0;
    }

    for (let m in announcements) {
      let obj = filterMxPos(announcements);
      msg.push(this.getAnnouncementItem(obj));
    }

    return (
      <div className="d-flex flex-column justify-content-center ansbox2">{msg}</div>
    );
  }

  render() {
    return (
      <div className={`container ${!this.props.isDesktop && "header-top-80"}`}>
        <div className="row section-title-container">
          <div
            ref={(divElement) => {
              this.divElement = divElement;
            }}
            className="col-12 header-back-fixed"
          >
            <BackTitle
              title={this.state.node.Name}
              onBack={() => {
                this.handleBackClick(this.state.node);
              }}
            />
          </div>
        </div>
        <div>
          {this.renderAnnouncements(
            this.state.announcements,
            this.state.annmsg
          )}
        </div>
        {this.state.message !== "" && (
          <div className="card tab-content p-3 rounded">
            <div
              className="d-flex flex-column justify-content-center align-items-center"
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            />
          </div>
        )}
        <div className="tab-content mb-5">
          <NodeList
            nodes={this.state.nodes}
            onItemClick={this.handleItemClick}
          />
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader>
            <h5>Announcement</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.toggle}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="anbox">
              <div
                className="orgwel"
                dangerouslySetInnerHTML={{ __html: this.state.annmsg }}
              ></div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default NodeListBox;
