import React from "react";
import MainBody from "../main/index";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const App = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 850px)",
  });

  const navigate = useNavigate();

  const onNavigate = (path) => {
    navigate(path);
  };

  return <MainBody isDesktop={isDesktopOrLaptop}  onNavigate={onNavigate}/>;
};

export default App;
