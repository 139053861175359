import React from 'react';

const Help = ({}) => {
  return (
    <div className="shelf-item">
      <p className="shelf-item__title">this is help page</p>
    </div>
  );
};

export default Help;
