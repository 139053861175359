import { fetchAPI } from "../config";

export async function getOrganization(body) {
  try {
    const data = await fetchAPI("/ssm_api_tree/getorgress", body);
    return data;
  } catch (error) {
    throw error; 
  }
}

export async function getOrganization2(body) {
  const data = await fetchAPI("/ssm_api_tree/getorgress2", body);
  return data;
}

export async function getOrganizationWelcomeMessage(body) {
  const data = await fetchAPI("/ssm_api_user/getorgwelcome", body);
  return data;
}
