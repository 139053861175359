import React from "react";
import { ssm_getcookie, ssm_setcookie } from "../../../common/utils/ssm_util";
import Spinner from "../../../common/components/Spinner";
import ssm_valid from "../../../common/utils/ssm_valid";
import Checkbox from "../../../common/components/register-checkbox";
import { userLogin } from "../../../services/auth/userLogin";

class UserLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false, errormsg: "", logininfo: {} };
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
    this.handleForget = this.handleForget.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  orgid = 0;
  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("Emai");
    this.requireds.push("Passwd");
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  handleLoginClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.logininfo,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.do_login();
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;
    this.setState((prevState) => ({
      logininfo: {
        ...prevState.logininfo,
        [name]: val,
      },
    }));
  };

  handleErrOkay(event) {
    this.setState({ errormsg: "" });
  }
  handleForget(event) {
    this.props.onNavigate("/forget");
  }
  handleRegister(event) {
    this.props.onNavigate("/registeraccount");
  }

  handleCheckboxChange = (event) => {
    let name = event.target.id;

    this.setState((prevState) => ({
      logininfo: {
        ...prevState.logininfo,

        [name]: !prevState.logininfo[name],
      },
    }));
  };

  async do_login() {
    const response = await userLogin(
      this.orgid,
      this.state.logininfo.Emai,
      this.state.logininfo.Passwd
    );

    if (response.Id == 0) {
      let data = JSON.parse(response.Data);
      this.setState({ loading: false });
      ssm_setcookie("ssm_sid", data.SId, this.state.logininfo.Reme);
      this.props.onLoginClick(data);
    } else {
      this.errors["Passwd"] = "Email or Password is incorrect";
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="content-area">
            <section id="login-container">
              <div className="container-fluid">
                <div id="login-title" className="row">
                  <div id="empty-cart-title" className="col-12">
                    <p className="login-title">Login</p>
                  </div>
                </div>
                <div id="login-form-container" className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <input
                      onChange={this.handleChange}
                      className="form-control login-inputs"
                      type="text"
                      name="Emai"
                      placeholder="Email"
                      maxLength={50}
                    />
                    {ssm_valid.showerror("Emai", this.errors)}
                    <input
                      onChange={this.handleChange}
                      className="form-control login-inputs"
                      type="password"
                      name="Passwd"
                      maxLength={18}
                      placeholder="Password"
                    />
                    {ssm_valid.showerror("Passwd", this.errors)}
                    <div className="row">
                      <div className="col-6">
                        <Checkbox
                          id="Reme"
                          label="Remember me"
                          isSelected={this.state.logininfo.Reme}
                          onCheckboxChange={this.handleCheckboxChange}
                          fontsize="sschecklable_s"
                          key="Reme"
                        />
                      </div>
                    </div>
                    <div className="tacenter">
                      <a>
                        <button
                          onClick={this.handleLoginClick}
                          className="filled-button-continue"
                        >
                          Login
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
                <div id="not-member" className="row">
                  <div className="col-12">
                    <p>
                      Not a member?{" "}
                      <a onClick={this.handleRegister} className="text-link">
                        Sign up
                      </a>
                    </p>
                    <p>
                      <a onClick={this.handleForget} id="forgotten-password">
                        Forgot password?
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default UserLogin;