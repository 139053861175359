import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import queryString from "query-string";
import Home from "../screens/home";
import Spinner from "../common/components/Spinner";
import MyHome from "../screens/activities";
import MyAccount from "../screens/account";
import Header from "../common/components/header";
import UserLogin from "../screens/auth/user_login";
import Register from "../screens/register";
import Store from "../screens/store";
import {
  ssm_getcookie,
  ssm_setcookie,
  ssm_clrcookie,
} from "../common/utils/ssm_util";
import Constant from "../common/constants/define";
import ContactUS from "../screens/contact";
import Help from "../screens/help";
import ForgetPassword from "../screens/auth/forgetpassword";
import RegisterAccount from "../screens/auth/registeraccount";
import ResetPassword from "../screens/auth/resetpassword";
import ErrorMsg from "../common/components/ErrorMsg";
import Prerequisite from "../screens/register/prerequisite";
import Footer from "../common/components/footer";
import SportsitesMessage from "../common/components/message";
import {
  getfeeAmou,
  getinoutfee,
  checkfees,
  d_StItem_l2,
} from "../common/utils/node";
import { getRegisterNode } from "../services/main/getRegisterNode";
import { getOrgByDomain } from "../services/main/getOrgByDomain";
import {
  getAnnouncementsOrg,
  getAnnouncementsAct,
} from "../services/main/getAnnouncements";
import {
  getOrganization,
  getOrganization2,
  getOrganizationWelcomeMessage,
} from "../services/main/getOrganization";
import { getTrees, getTreesAndPerson } from "../services/main/getTrees";
class MainBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invid: 0,
      C_MulSlots: [],
      Annos: [],
      orgwel: "",
      family: {},
      errormsg: "",
      overallpcds: [],
      smalltree: [],
      rootnodes: [],
      pcds: [],
      toregnode: null,
      upcomings: [],
      loading: true,
      storeLoading: true,
      isallhome: 1,
      Logined: 0,
      error: "error",
      orgLogo: "",
      orgMessage: "",
      maxCampsiteReservations: 0,
    };

    this.onLogout = this.onLogout.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
    this.handleErr = this.handleErr.bind(this);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleSubmitClick = this.handleSubmitClick.bind(this);
    this.handlePayClick = this.handlePayClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleUpCountClick = this.handleUpCountClick.bind(this);
    this.handlePrerOkClick = this.handlePrerOkClick.bind(this);
    this.handleUpdateData = this.handleUpdateData.bind(this);
  }
  _isMounted = false;
  orgid = 0;
  c_Schs = [];
  A_Vens = [];
  C_OrgInfo = {};

  componentDidMount() {
    this._isMounted = true;
    if (this.orgid) {
      this.getOrgMessage();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getOrgMessage() {
    const orgid = this.orgid;
    const body = { orgid };
    const res = await getOrganizationWelcomeMessage(body);
    this.setState({ orgMessage: res });
  }

  onLogout() {
    ssm_clrcookie("ssm_sid");
    this.setState({ Logined: 0, toregnode: null, overallpcds: [] });
    this.props.onNavigate("/");
  }

  handleLoginClick(data) {
    this.setState({ Logined: 1, family: data });
    if (this.state.toregnode == null) {
      this.props.onNavigate("/");
      let sid = ssm_getcookie("ssm_sid");
      if (sid != undefined) {
        this.setState({ storeLoading: true });
        this.setState({ Logined: 1 });
        this.getTreeswithUser(sid);
        this.get_Anous(1);
      } else {
        this.getTrees();
        this.get_Anous(0);
      }
    } else {
      this.handleRegisterClick(this.state.toregnode, this.state.C_MulSlots);
    }
  }

  handleErr(err) {
    if (err.Id == 5) {
      ssm_clrcookie("ssm_sid");
      this.setState({ Logined: 0, toregnode: null, errormsg: err.Msg });
    }
  }

  handleErrOkay() {
    this.setState({ errormsg: "" });
    this.props.onNavigate("/login");
  }

  handleRegisterClick(node, mulslots) {
    this.setState({ toregnode: node, C_MulSlots: mulslots });
    if (this.state.Logined == 0) {
      this.props.onNavigate("/login");
    } else {
      if (node.OType == Constant.TP_ITEM) {
        if (node.Item.RType == Constant.REGTP_NRITEM) {
          this.kick_NRItem(node);
        } else {
          this.props.onNavigate("/register");
        }
      } else {
        this.props.onNavigate("/register");
      }
    }
  }

  handlePrerOkClick() {}

  async regdo_Node_m(sportcreateinfo) {
    this.setState({ loading: true });

    let jsonstr = JSON.stringify(sportcreateinfo);
    let sid = ssm_getcookie("ssm_sid");
    const para = { jsonstr, sid };
    const rt = await getRegisterNode(para);
    if (rt.Id == 0) {
      let obj = JSON.parse(rt.Data);
      this.setState({ loading: false });
      this.handleSubmitClick(
        obj.pcds,
        this.state.family.PersonInfo.Person,
        1,
        this.state.family.PersonInfo.Person.PId
      );
    } else {
      this.setState({ loading: false, errormsg: rt.Description });
    }
  }

  g_LNName(node) {
    return node.Name;
  }

  bd_Pcd(person, group, bGroup, type, fid, fee, qa, vrinfo, count, item) {
    let OId = item.OId;
    let OType = item.OType;
    let node = item.Item;

    let pcd = {};
    pcd.PPCDId = Constant.SSM_ROOTID;
    pcd.OId = OId;
    pcd.OType = OType;
    pcd.PType = Constant.TP_PURCHASER;
    pcd.LId = Constant.SSM_ROOTID;

    pcd.Type = type;
    if (type == Constant.PCD_MAIN) {
      if (person != null) {
        if (OType != Constant.TP_VENUE) {
          if (bGroup == false)
            pcd.Desc = this.g_LNName(node) + " (" + person.FName + ")";
          else pcd.Desc = this.g_LNName(node) + " (Team : " + group.Name + ")";
        }
      } else {
        pcd.Desc = this.g_LNName(node);
      }
    }

    pcd.FId = fid;
    pcd.Rate = (fee==-1)?0:fee;
    pcd.Count = count;
    pcd.INVId = this.state.invid;

    return pcd;
  }

  bd_MainPcd3(node, fee, count) {
    let pcd = this.bd_Pcd(
      null,
      null,
      false,
      Constant.PCD_MAIN,
      fee.FId,
      getfeeAmou(node, fee.FId),
      null,
      null,
      count,
      node
    );
    return pcd;
  }

  bd_Pcds3(node, fee, count) {
    let pcds = [];
    let pcd = this.bd_MainPcd3(node, fee, count);
    pcds.push(pcd);

    return pcds;
  }

  reg_NRItem(node, fee, count) {
    let c_Orgid = this.orgid;
    let reg = {};
    reg.OrgId = c_Orgid;
    reg.OId = node.OId;
    reg.OType = node.OType;

    let sportregistrationinfo = {};
    sportregistrationinfo.Type = node.Item.RType;
    sportregistrationinfo.reg = reg;
    sportregistrationinfo.OrgId = c_Orgid;
    sportregistrationinfo.OId = node.OId;
    sportregistrationinfo.OType = node.OType + 100;
    sportregistrationinfo.TZId = this.C_OrgInfo.TZId;
    sportregistrationinfo.TransType = node.Item.TransType;
    if (
      node.Item.TransType == 2 ||
      node.Item.TransType == 3 ||
      node.Item.TransType == 4
    )
      sportregistrationinfo.reg.PaId = this.state.family.PersonInfo.Person.PId;
    sportregistrationinfo.pcds = this.bd_Pcds3(node, fee, count);
    sportregistrationinfo.person = this.state.family.PersonInfo.Person;
    sportregistrationinfo.regQAs = [];

    this.regdo_Node_m(sportregistrationinfo);
  }

  getNodefee(item) {
    let node = null;
    switch (item.OType) {
      case Constant.TP_ITEM:
        node = item.Item;
        break;
    }

    let t5 = (node.Type & 2) == 2;
    if (t5) {
      let inout =
        this.C_OrgInfo.InZip.indexOf(
          this.state.family.PersonInfo.Person.PCode
        ) >= 0
          ? true
          : false;
      return getinoutfee(inout, item);
    } else {
      for (let i in item.Fees) {
        if (item.Fees[i].Type == 1) return item.Fees[i];
      }
    }
    return null;
  }

  kick_NRItem(node) {
    if (node.NdPreq == 1) {
      return <Prerequisite node={node} onOkClick={this.handlePrerOkClick} />;
    } else {
      if (checkfees(node)) {
        this.props.onNavigate("/register");
      } else {
        this.reg_NRItem(node, this.getNodefee(node), 1);
      }
    }
  }

  add_InvCnt(pcd) {
    if (pcd.Type == Constant.PCD_TOT) {
      pcd.InvCnt = this.state.toregnode.Item.Count;
    }
  }

  handleSubmitClick(pcds, person, rela, pid) {
    let allpcds = this.state.overallpcds;
    if (pcds.length > 0) {
      for (let m in pcds) {
        if (pcds[m].RType == Constant.REGTP_NRITEM) {
          this.add_InvCnt(pcds[m]);
        }
        allpcds.push(pcds[m]);
      }
    }

    if (rela == 5) {
      person.PId = pid;
      let pinfo = {};
      pinfo.Person = person;
      this.state.family.RelaInfos.push(pinfo);
    }

    this.setState({ overallpcds: allpcds, toregnode: null });
    this.props.onNavigate("/store");
  }

  handlePayClick() {
    this.setState({ invid: 0 });
    this.props.onNavigate("/myhome");
  }

  handleDeleteClick(item) {
    const items2 = this.state.overallpcds.filter(
      (pcd) => pcd.PCDId !== item.PCDId
    );
    const items = items2.filter((pcd) => pcd.PPCDId !== item.PCDId);
    this.setState({ overallpcds: items });
  }

  handleUpCountClick(pcdid, count, type, selcnt, newpcds) {
    let pcds = this.state.overallpcds;
    if (pcds.length > 0) {
      for (let m in pcds) {
        if (pcds[m].Type == 6 && pcds[m].PCDId == pcdid) {
          pcds[m].Count = count;
        }
      }
    }
    if (type == 1) {
      if (newpcds.length > 0) {
        for (let m in newpcds) {
          pcds.push(newpcds[m]);
        }
      }
    } else {
      pcds = d_StItem_l2(pcdid, selcnt, pcds);
    }
    this.setState({ overallpcds: pcds });
    this.handleUpdateData();
  }

  handleannclick(id) {
    this.g_OrgAn(id);
  }

  componentWillMount() {
    let subdomain = window.location.host.split(".")[0];
    if (subdomain == "mobile" || subdomain.indexOf("localhost") >= 0) {
      let parsed = queryString.parse(window.location.search);
      if (parsed.orgid != undefined) {
        ssm_setcookie("ssm_orgid", parsed.orgid, 1);
        this.orgid = parsed.orgid;
      } else {
        this.orgid = ssm_getcookie("ssm_orgid");
      }
      this.get_OrgInfo();
    } else {
      this.getorgbydomain();
    }
  }

  get_Anous(flag) {
    if (flag == 1) this.get_Anous1();
    else this.get_Anous2(0);
  }

  get_OrgInfo() {
    let sid = ssm_getcookie("ssm_sid");
    if (sid != undefined) {
      this.setState({ storeLoading: true });
      this.setState({ Logined: 1 });
      this.getTreeswithUser(sid);
      this.get_Anous(1);
    } else {
      this.getTrees();
      this.get_Anous(0);
    }
  }

  async getorgbydomain() {
    this.setState({ loading: true });

    let jsonstr = window.location.host.split(".")[0];
    const para = { jsonstr };

    let rt = await getOrgByDomain(para);
    if (rt.Id == 0) {
      let org = JSON.parse(rt.Data);
      ssm_setcookie("ssm_orgid", org.OrgId, 1);
      this.orgid = org.OrgId;
      this.get_OrgInfo();
    } else {
      alert("Please check your domain.");
    }
  }

  handleUpdateData() {
    if (!this._isMounted) {
      return;
    }
    this.setState({ loading: true });
    let sid = ssm_getcookie("ssm_sid");
    if (sid != undefined) {
      this.getTreeswithUser(sid);
      this.get_Anous(1);
    } else {
      this.getTrees();
      this.get_Anous(0);
    }
    if (this._isMounted) {
      this.setState({ loading: false });
    }
  }

  async get_Anous2(flag) {
    let req = new Object();
    req.PId = 0;
    req.OrgId = this.orgid;
    if (flag == 0) {
      req.OId = Constant.SSM_ROOTID;
    }
    req.Flag = flag;
    let jsonstr = JSON.stringify(req);
    jsonstr = new Buffer(jsonstr).toString("base64");
    const para = { jsonstr };
    const rt = await getAnnouncementsOrg(para);
    let aninfo = JSON.parse(rt.Data);
    this.setState({ orgwel: aninfo.OrgWel, Annos: aninfo.Annos });
  }

  async get_Anous1() {
    let req = {};
    req.PId = 0;
    req.OrgId = this.orgid;
    req.OId = Constant.SSM_ROOTID;
    req.Flag = 1;
    let jsonstr = JSON.stringify(req);
    jsonstr = new Buffer(jsonstr).toString("base64");
    let sid = ssm_getcookie("ssm_sid");
    const para = { jsonstr, sid };
    let rt = await getAnnouncementsAct(para);
    let aninfo = JSON.parse(rt.Data);
    this.setState({ orgwel: aninfo.OrgWel, Annos: aninfo.Annos });
  }

  async getTrees() {
    let rt = await getTrees(this.orgid);
    let All = JSON.parse(rt.Data);
    let tr = JSON.parse(All.TR);
    let nodes = JSON.parse(tr.Data);
    let str = JSON.parse(All.STR);
    let simtree = JSON.parse(str.Data);

    let rootnodes = [];
    if (nodes.length > 0) {
      for (let m in nodes) {
        if (nodes[m].PId == "00000000-0000-0000-0000-000000000000") {
          rootnodes.push(nodes[m]);
        }
      }
    }
    let upcomings = [];
    if (nodes.length > 0) {
      for (let m in nodes) {
        if (nodes[m].OType != 1) {
          upcomings.push(nodes[m]);
        }
      }
    }

    this.setState({
      smalltree: [...simtree],
      rootnodes: [...rootnodes],
      upcomings: [...upcomings],
      loading: false,
    });

    this.getOrgRess2();
  }

  async getTreeswithUser(sid) {
    let req = {};
    req.Guid = this.orgid;
    req.Type = 0;
    req.Flag = 0;
    let Allinfo = {};
    Allinfo.TR = new Buffer(JSON.stringify(req)).toString("base64");
    Allinfo.PE = new Buffer(JSON.stringify(req)).toString("base64");
    req.Type = 1;
    Allinfo.STR = new Buffer(JSON.stringify(req)).toString("base64");
    let jsonstr = JSON.stringify(Allinfo);
    jsonstr = new Buffer(jsonstr).toString("base64");

    const para = { jsonstr, sid };
    let rt = await getTreesAndPerson(para);
    if (rt.Id == 0) {
      let All = JSON.parse(rt.Data);
      let tr = JSON.parse(All.TR);
      let nodes = JSON.parse(tr.Data);
      let str = JSON.parse(All.STR);
      let simtree = JSON.parse(str.Data);
      let pe = JSON.parse(All.PE);
      let l_family = JSON.parse(pe.Data);
      let rootnodes = [];
      if (nodes.length > 0) {
        for (let m in nodes) {
          if (nodes[m].PId == "00000000-0000-0000-0000-000000000000") {
            rootnodes.push(nodes[m]);
          }
        }
      }
      let upcomings = [];
      if (nodes.length > 0) {
        for (let m in nodes) {
          if (nodes[m].OType != 1) {
            upcomings.push(nodes[m]);
          }
        }
      }

      let lpcds = JSON.parse(All.PCDs);
      let unpays = JSON.parse(lpcds.Data);
      if (unpays.length == 0) {
        this.setState({
          invid: 0,
          smalltree: [...simtree],
          rootnodes: [...rootnodes],
          upcomings: [...upcomings],
          overallpcds: [...unpays],
          loading: false,
          storeLoading: false,
          family: l_family,
        });
      } else {
        this.setState({
          invid: unpays[0].INVId,
          smalltree: [...simtree],
          rootnodes: [...rootnodes],
          upcomings: [...upcomings],
          overallpcds: [...unpays],
          loading: false,
          storeLoading: false,
          family: l_family,
        });
      }
      this.getOrgRess();
    } else if (rt.Id == 5) {
      ssm_clrcookie("ssm_sid");
      this.setState({ Logined: 0, toregnode: null });
      window.location.reload(false);
    } else {
    }
  }
  async getOrgRess() {
    let req = {};
    req.Guid = this.orgid;
    req.Type = 1;
    let sid = ssm_getcookie("ssm_sid");
    let jsonstr = JSON.stringify(req);
    jsonstr = new Buffer(jsonstr).toString("base64");
    const para = { jsonstr, sid };
    let rt = await getOrganization(para);
    let orgres = JSON.parse(rt.Data);
    let schoo = JSON.parse(orgres.Schoinfo);
    this.c_Schs = JSON.parse(schoo.Data);

    let veni = JSON.parse(orgres.Veninfo);
    this.A_Vens = JSON.parse(veni.Data);

    let orginfostr = JSON.parse(orgres.Orginfo);
    this.C_OrgInfo = JSON.parse(orginfostr.Data);
    this.setState({
      mlogo: !this.C_OrgInfo.MobileBanner
        ? this.C_OrgInfo.Logo
        : this.C_OrgInfo.MobileBanner,
      maxCampsiteReservations: this.C_OrgInfo.MaxCR,
    });
    this.setState({
      orgLogo: this.C_OrgInfo.Logo,
    });
  }

  async getOrgRess2() {
    if (this.orgid == undefined || this.orgid == 0) {
      return;
    }
    let req = {};
    req.Guid = this.orgid;
    req.Type = 1;

    let jsonstr = JSON.stringify(req);
    jsonstr = new Buffer(jsonstr).toString("base64");
    const para = { jsonstr };
    let rt = await getOrganization2(para);
    if (rt.Id == 0) {
      let orgres = JSON.parse(rt.Data);
      let orginfostr = JSON.parse(orgres.Orginfo);
      this.C_OrgInfo = JSON.parse(orginfostr.Data);
      this.setState({
        mlogo: !this.C_OrgInfo.MobileBanner
          ? this.C_OrgInfo.Logo
          : this.C_OrgInfo.MobileBanner,
        maxCampsiteReservations: this.C_OrgInfo.MaxCR,
        orgLogo: this.C_OrgInfo.Logo,
      });
    }
  }

  render() {
    let isDesktop = this.props.isDesktop;
    let parsed = queryString.parse(window.location.search);
    let toreset = 0;
    if (parsed.rpw != undefined) toreset = 1;

    return (
      <>
        {this.state.loading ? (
          <div className="d-flex align-items-center loading-screen">
            <Spinner />
          </div>
        ) : this.state.errormsg != "" ? (
          <ErrorMsg msg={this.state.errormsg} onErrOkay={this.handleErrOkay} />
        ) : toreset ? (
          <ResetPassword />
        ) : this.orgid == undefined || this.orgid == 0 ? (
          <ErrorMsg
            msg="Please check your local City's website for the correct registration links."
            displayok={0}
          />
        ) : (
          <>
            <Routes>
              <Route
                path="/reset"
                element={
                  <>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div
                      className={
                        isDesktop ? "header-web-top" : "footer-bottom-90"
                      }
                    >
                      <ResetPassword onNavigate={this.props.onNavigate} />
                    </div>
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <div className={!isDesktop && "header-top"}>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div className={isDesktop && "header-web-top"}>
                      <UserLogin
                        onLoginClick={this.handleLoginClick}
                        onNavigate={this.props.onNavigate}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/registeraccount"
                element={
                  <div className={!isDesktop && "header-top"}>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div className={isDesktop && "header-web-top"}>
                      <RegisterAccount
                        onLoginClick={this.handleLoginClick}
                        onNavigate={this.props.onNavigate}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/myhome"
                element={
                  <>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                    />
                    <div className={isDesktop && "header-web-top"}>
                      <MyHome
                        onErr={this.handleErr}
                        family={this.state.family}
                        orginfo={this.C_OrgInfo}
                        mlogo={this.state.mlogo}
                        Logined={this.state.Logined}
                        isDesktop={this.props.isDesktop}
                      />
                    </div>
                  </>
                }
              />
              <Route
                path="/myaccount"
                element={
                  <div className={!isDesktop && "header-top"}>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                    />
                    <div className={isDesktop && "header-web-top"}>
                      <MyAccount
                        onErr={this.handleErr}
                        family={this.state.family}
                        onLogout={this.onLogout}
                        onRefresh={this.handleUpdateData}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/store"
                element={
                  <div className={!isDesktop && "header-top-100"}>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                    />
                    <div className={isDesktop && "header-web-top"}>
                      <Store
                        onErr={this.handleErr}
                        family={this.state.family}
                        orginfo={this.C_OrgInfo}
                        pcds={this.state.overallpcds}
                        onDeleteClick={this.handleDeleteClick}
                        onUpCountClick={this.handleUpCountClick}
                        onPayClick={this.handlePayClick}
                        loading={this.state.storeLoading}
                        isDesktop={this.props.isDesktop}
                        onNavigate={this.props.onNavigate}
                        onRefresh={this.handleUpdateData}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/register"
                element={
                  <div
                    className={`footer-bottom ${!isDesktop && "header-top"}`}
                  >
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div
                      className={
                        isDesktop ? "header-web-top" : "footer-bottom-90"
                      }
                    >
                      <Register
                        onErr={this.handleErr}
                        invid={this.state.invid}
                        orginfo={this.C_OrgInfo}
                        mulslots={this.state.C_MulSlots}
                        node={this.state.toregnode}
                        pcds={this.state.overallpcds}
                        family={this.state.family}
                        schools={this.c_Schs}
                        onSubmitClick={this.handleSubmitClick}
                        isDesktop={this.props.isDesktop}
                        smalltree={this.state.smalltree}
                        onNavigate={this.props.onNavigate}
                      />
                    </div>
                  </div>
                }
              />
              <Route
                path="/contact"
                element={
                  <div className={!isDesktop && "header-top"}>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div className={isDesktop && "header-web-top"}>
                      <ContactUS />
                    </div>
                  </div>
                }
              />
              <Route path="/help" element={<Help />} />
              <Route
                path="/forget"
                element={
                  <div className={!isDesktop && "header-top"}>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div
                      className={
                        isDesktop ? "header-web-top" : "footer-bottom-90"
                      }
                    >
                      <ForgetPassword onNavigate={this.props.onNavigate} />
                    </div>
                  </div>
                }
              />
              <Route
                path="/*"
                element={
                  <>
                    <Header
                      logined={this.state.Logined}
                      logo={
                        this.props.isDesktop
                          ? this.state.orgLogo
                          : this.state.mlogo
                      }
                      center
                    />
                    <div
                      className={
                        isDesktop ? "header-web-top" : "footer-bottom-90"
                      }
                    >
                      <Home
                        onErr={this.handleErr}
                        smalltree={this.state.smalltree}
                        rootnodes={this.state.rootnodes}
                        upcomings={this.state.upcomings}
                        onRegisterClick={this.handleRegisterClick}
                        mlogo={this.state.mlogo}
                        Logined={this.state.Logined}
                        announcements={this.state.Annos}
                        isDesktop={this.props.isDesktop}
                        orginfo={this.C_OrgInfo}
                        onNavigate={this.props.onNavigate}
                        orgMessage={this.state.orgMessage}
                        maxCampsiteReservations={
                          this.state.maxCampsiteReservations
                        }
                        onRefresh={this.handleUpdateData}
                      />
                    </div>
                  </>
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </>
        )}
        {!this.props.isDesktop ? (
          <Footer isLogged={this.state.Logined} />
        ) : (
          <div className="fixed-bottom bg-white">
            <SportsitesMessage bottom={5} />
          </div>
        )}
      </>
    );
  }
}

export default MainBody;
