import React from "react";

class Count extends React.Component {
  constructor(props) {
    super(props);
    this.state = { count: 1 };
    this.handleOkClick = this.handleOkClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ count: value });
  }

  getCountContent() {
    return (
      <div className="content-area">
        <section id="login-container">
          <div className="container-fluid">
            <div id="login-title" className="row">
              <div id="empty-cart-title" className="col-12">
                <p className="login-title">Please select count</p>
              </div>
            </div>

            <div id="login-form-container" className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <select
                  className="form-control login-inputs"
                  name="count"
                  value={this.state.count}
                  onChange={this.handleChange}
                >
                  <option value="1" selected>
                    1
                  </option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <div className="tacenter">
                  <a>
                    <button onClick={this.handleOkClick} className="filled-button">
                      Continue
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  handleOkClick() {
    this.props.onOkClick(this.state.count);
  }

  render() {
    return <div>{this.getCountContent()}</div>;
  }
}

export default Count;
