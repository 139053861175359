import { fetchAPI } from "../config";

export async function selectNodeById(OId) {
  let req = new Object();
  req.Guid = OId;
  req.Type = 0;
  req.Flag = 0;

  let jsonstr = JSON.stringify(req);
  jsonstr = new Buffer(jsonstr).toString("base64");
  var sid = "00000000-0000-0000-0000-000000000000";
  const para = { jsonstr, sid };

  const data = await fetchAPI("/ssm_api_node/selectbypid_node", para);
  let nodeData = JSON.parse(data.Data);
  return nodeData;
}
