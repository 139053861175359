import Cookies from "universal-cookie";

const cookies = new Cookies();

export const ssm_alert = (msg) => {
  aa(msg);
};

export const ssm_setcookie = (name, val, flag) => {
  const timestamp = new Date().getTime();
  const expire = timestamp + 60 * 60 * 24 * 1000 * 1;
  const expireDate = new Date(expire);

  if (flag) cookies.set(name, val, { path: "/" });
  else cookies.set(name, val, { path: "/", expires: expireDate });
};

export const ssm_getcookie = (name) => {
  return cookies.get(name);
};

export const ssm_clrcookie = (name) => {
  cookies.remove(name);
};

function aa(msg) {
  alert(msg);
}
