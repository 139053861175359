import { fetchAPINoID } from "../config";

export async function getContactInformation(id) {
  let req = {};
  req.Guid = id;
  req.Type = 0;
  const data = await fetchAPINoID("/ssm_api_user/getorgcontactinfo", req);
  let orginfo = JSON.parse(data.Data);
  return orginfo;
}
