import { fetchAPI } from "../config";

export async function userRegister(person) {
  let object = {};
  object.person = person;

  let date = new Date();
  object.TZ = date.getTimezoneOffset();

  let jsonstr = JSON.stringify(object);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = "00000000-0000-0000-0000-000000000000";
  const para = { jsonstr, sid };
  const data = await fetchAPI("/ssm_api_user/register", para);
  return data;
}
