import React from "react";

class Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = { node: this.props.node };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    var fee = new Object();
    fee.FId = e.target.value;
    this.props.onOkClick(fee);
  }

  getPriceContent() {
    const items = [];
    for (var i in this.state.node.Fees) {
      if (this.state.node.Fees[i].Type === 3) {
        this.state.node.Fees[i].Name = "Register Team";
      }
      if (this.state.node.Fees[i].Type === 4) {
        this.state.node.Fees[i].Name = "Find Team";
      }
      if (this.state.node.Fees[i].Type === 5) {
        this.state.node.Fees[i].Name = "Free Agent";
      }

      if (
        this.state.node.Fees[i].Type === 1 ||
        this.state.node.Fees[i].Type === 3 ||
        this.state.node.Fees[i].Type === 4 ||
        this.state.node.Fees[i].Type === 5
      )
        items.push(
          <option value={this.state.node.Fees[i].FId}>
            {this.state.node.Fees[i].Name}
          </option>
        );
    }

    return (
      <select className="form-control login-inputs" onChange={this.handleChange}>
        <option value="0" selected>
          Please select fee type
        </option>
        {items}
      </select>
    );
  }

  render() {
    return <div>{this.getPriceContent()}</div>;
  }
}

export default Price;
