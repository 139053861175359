import { ssm_getcookie } from "../common/utils/ssm_util";
import Config from "../config";

export async function fetchAPIWithID(url, request) {
  let jsonstr = JSON.stringify(request);
  jsonstr = new Buffer(jsonstr).toString("base64");
  let sid = ssm_getcookie("ssm_sid");
  const body = { jsonstr, sid };
  const res = await fetch(Config.ssm_service + url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  let rt = await res.json();
  return rt;
}

export async function fetchAPINoID(url, request) {
    let jsonstr = JSON.stringify(request);
    jsonstr = new Buffer(jsonstr).toString("base64");
    const body = { jsonstr };
    const res = await fetch(Config.ssm_service + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    let rt = await res.json();
    return rt;
  }

  export async function fetchAPI(url, body) {
    const res = await fetch(Config.ssm_service + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    let rt = await res.json();
    return rt;
  }

