import React from "react";
import Spinner from "../../../../common/components/Spinner";
import { getStanding } from "../../../../services/activities/getStanding";

class MyStuff_standing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      node: this.props.node,
      myregmanobj: this.props.myregmanobj,
      myteamsches: [],
      loading: false,
    };
  }

  componentWillMount() {
    this.getStanding();
  }

  async getStanding() {
    this.setState({ loading: true });
    const response = await getStanding(this.state.node.Act.AId);
    this.setState({ myteamsches: response, loading: false });
  }

  getStandinginfo(team) {
    let cls =
      this.state.node.Group.GId == team.id
        ? "row cdi-open-item listseled"
        : "row cdi-open-item";

    return (
      <div className={cls}>
        <div id="roster-gender" className="col-5 sscol ta-left">
          <span className="roster-info">{team.name}</span>
        </div>
        <div id="roster-age" className="col-3 sscol ta-middle">
          <span className="roster-info">{team.win + "-" + team.los}</span>
        </div>
        <div id="roster-number" className="col-3 sscol ta-right">
          <span className="roster-info">{team.gb == 0 ? "-" : team.gb}</span>
        </div>
      </div>
    );
  }

  exist_tm(id, teams2) {
    for (let i in teams2) {
      if (id == teams2[i].id) return true;
    }
    return false;
  }

  f_FTeams(teams, teams2) {
    for (let i in teams) {
      if (!this.exist_tm(teams[i].id, teams2)) return teams[i];
    }
    return teams[0];
  }

  f_HiTeam(teams, teams2) {
    let team = this.f_FTeams(teams, teams2);
    for (let i in teams) {
      if (!this.exist_tm(teams[i].id, teams2)) {
        if (team.sco < teams[i].sco) {
          team = teams[i];
        }
      }
    }
    return team;
  }

  sort_TeamPl(teams) {
    let teams2 = [];
    for (let i in teams) {
      let team = this.f_HiTeam(teams, teams2);
      teams2.push(team);
    }

    return teams2;
  }

  get_TeamGB(teams) {
    if (teams.length == 0) return;
    let firstdiff = teams[0].win - teams[0].los;
    for (let i in teams) {
      if (i == 0) continue;
      let selfdiff = teams[i].win - teams[i].los;
      let gb = (firstdiff - selfdiff) / 2;
      teams[i].gb = gb;
    }
  }

  getStanding_v() {
    const items = [];

    let l_teams = [];
    for (let i in this.state.myregmanobj.grs) {
      if (this.state.myregmanobj.grs[i].Group.Type === 9) {
        let team = new Object();
        team.id = this.state.myregmanobj.grs[i].Group.GId;
        team.name = this.state.myregmanobj.grs[i].Group.Name;
        team.win = 0;
        team.los = 0;
        team.equ = 0;
        team.sco = 0;
        team.gb = 0;
        let sches = this.state.myteamsches;
        for (let j in sches) {
          if (sches[j].HScore != -1 && sches[j].GScore != -1) {
            if (team.id == sches[j].GuId) {
              if (sches[j].HScore > sches[j].GScore) {
                team.win++;
                team.sco = team.sco + 2;
              } else if (sches[j].HScore < sches[j].GScore) {
                team.los++;
              } else if (sches[j].HScore == sches[j].GScore) {
                team.equ++;
                team.sco++;
              }
            } else if (team.id == sches[j].HmId) {
              if (sches[j].HScore > sches[j].GScore) {
                team.los++;
              } else if (sches[j].HScore < sches[j].GScore) {
                team.win++;
                team.sco = team.sco + 2;
              } else if (sches[j].HScore == sches[j].GScore) {
                team.equ++;
                team.sco++;
              }
            }
          }
        }

        l_teams.push(team);
      }
    }
    let l_teams2 = [];
    l_teams2 = this.sort_TeamPl(l_teams);
    this.get_TeamGB(l_teams2);
    for (let i in l_teams2) {
      items.push(this.getStandinginfo(l_teams2[i]));
    }

    return <div className="p-3">{items}</div>;
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-around  align-items-center schedule-header rounded">
              <span className="standing-item d-flex justify-content-center mr-4">
                Team
              </span>
              <span className="standing-item record d-flex justify-content-center">
                Record
              </span>
              <span className="standing-item d-flex justify-content-center pl-4">
                GB
              </span>
            </div>
            {this.getStanding_v()}
          </>
        )}
      </div>
    );
  }
}

export default MyStuff_standing;