import React from "react";
import { useNavigate } from "react-router-dom";

export default ({ msg, flag, onErrOkay, displayok }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (onErrOkay == undefined) navigate(-1);
    else onErrOkay();
  };

  return (
    <div class="content-area p-4">
      <section id="successful-payment">
        <div class="container">
          <div class="row">
            {flag == 0 ? (
              <div class="col-12 tacenter">
                <img width="64" src="img/success-icon.svg" alt="success" />
                <p class="success-message">{msg}</p>
              </div>
            ) : (
              <div class="col-12 tacenter">
                <img width="64" src="img/error.png" alt="Error" />
                <p class="success-message">{msg}</p>
              </div>
            )}
          </div>
        </div>
      </section>
      {displayok == 0 ? (
        <div></div>
      ) : (
        <div id="cart-actions" className="container">
          <button
            onClick={handleClick}
            class="filled-button"
          >
            Ok
          </button>
        </div>
      )}
    </div>
  );
};
