import React from "react";
import Constant from "../../../../common/constants/define";
import Spinner from "../../../../common/components/Spinner";
import { ssm_getcookie } from "../../../../common/utils/ssm_util";
import { htmlToText } from "../../../../common/utils/utils";
import { getCampsiteInformation } from "../../../../services/home/getCampsiteInformation";
import { getNodeFromTree } from "../../../../common/utils/node";

class ListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      node: this.props.node,
      c_MapObjId: this.props.c_MapObjId,
      PagePos: 0,
      CampInfo: {},
      loading: false,
      OStartDate: this.props.OStartDate,
      OEndDate: this.props.OEndDate,
      StartDate: this.props.StartDate,
      EndDate: this.props.EndDate,
      c_CR_List: this.props.c_CR_List,
      c_CR_Listall: this.props.c_CR_Listall,
      c_CR_CampList: this.props.c_CR_CampList,
    };

    this.handleSlotClick = this.handleSlotClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSiteClick = this.handleSiteClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleLoopSelChange = this.handleLoopSelChange.bind(this);
  }

  cFirstDay = "";
  orgid = 0;

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  componentWillReceiveProps(props) {
    this.setState({
      PagePos: !props.sh_Continue,
      c_MapObjId: props.c_MapObjId,
    });
  }

  async getcampinfo(oid) {
    this.setState({ loading: true });
    const response = await getCampsiteInformation(oid);
    if (response.Id == 0) {
      var cmsinfo = JSON.parse(response.Data);
      this.setState({ loading: false, PagePos: 1, CampInfo: cmsinfo });
      this.props.onListBackClick(0);
    }
  }

  handleBackClick() {
    this.setState({ PagePos: 0 });
    this.props.onListBackClick(1);
  }

  handleLoopSelChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;
    this.setState({ c_MapObjId: val });
    this.props.onUpClick(val);
  };

  handlePrevClick() {
    this.props.onPrevClick(this.cFirstDay);
  }

  handleNextClick() {
    this.props.onNextClick(this.cFirstDay);
  }
  handleSiteClick(event) {
    this.getcampinfo(event.target.id);
  }

  handleSlotClick(event) {
    if (event.target.src.indexOf("icon_campsite_notAvailable") > 0) return;
    var C_CP_SId = event.target.id;

    var co_OId = event.target.parentNode.parentNode.id; 
    if (event.target.src.indexOf("icon_campsite_available") > 0) {
      this.props.onStartReserve(co_OId, Constant.REG_VENUE, C_CP_SId);
    } else {
      this.props.onSeledUpdate(null, null, co_OId, C_CP_SId, 0);
    }
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  getCSTableHead(days, start, end, cday) {
    const heads = [];

    heads.push(
      <div className="ss_cscol2_2">
        <a
          onClick={() => {
            this.handlePrevClick(this.state.node);
          }}
          className="cal-button2_1"
        >
          <img src="/img/arrow-back-icon.svg" className="wdcal-img"></img>
        </a>
      </div>
    );
    let dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    for (var i = 0; i < days; i++) {
      const dayName = dayNames[cday.getDay()];
      if (cday >= start && cday < end)
        heads.push(
          <div className="ss_cscol2_1">
            <span className="dayofwd2">{dayName}</span>
            <br />
            <div className="wdactive2 d-flex justify-content-center align-items-center">
              <span className="camsiteActiveText">{cday.getDate()}</span>
            </div>
          </div>
        );
        else
          heads.push(
            <div className="ss_cscol2_1">
              <span className="dayofwd2">{dayName}</span>
              <br />
              <div><span className="wdnormal">{cday.getDate()}</span></div>
            </div>
          );
      cday = this.addDays(cday, 1);
    }
    heads.push(
      <div className="ss_cscol2_1">
        <a
          onClick={() => {
            this.handleNextClick(this.state.node);
          }}
          className="cal-button3"
        >
          <img src="/img/arrow-forward-icon.svg" className="wdcal-img"></img>
        </a>
      </div>
    );
    return heads;
  }

  get_ssday(day) {
    var s = day.getDate() + "/" + day.getMonth() + "/" + day.getFullYear();
    return s;
  }

  ck_AV4Campday(vid, day) {
    var now = new Date();
    var diff = Math.round((day - now) / (1000 * 60 * 60 * 24));
    if (diff <= 0) return false; //c_Orginfo.CutCR

    for (var i in this.state.c_CR_Listall) {
      if (vid == this.state.c_CR_Listall[i].VId) {
        var day2 = new Date(this.state.c_CR_Listall[i].End); //.substr(0,10)
        var d = this.get_ssday(day); //.toLocaleDateString();//,'MM/dd'
        var d2 = this.get_ssday(day2); //.toLocaleDateString();//,'MM/dd');
        if (d == d2) {
          return true;
        }
      }
    }
    return false;
  }

  gsid_Campday(vid, day) {
    for (var i in this.state.c_CR_Listall) {
      if (vid == this.state.c_CR_Listall[i].VId) {
        var day2 = new Date(this.state.c_CR_Listall[i].End);
        var d = this.get_ssday(day); //.toLocaleDateString();
        var d2 = this.get_ssday(day2); //.toLocaleDateString();
        if (d == d2) {
          return this.state.c_CR_Listall[i].SId;
        }
      }
    }
    return null;
  }

  Is_CSSeled3(oid, sid) {
    var seled = this.props.C_MulSlots;
    for (var i in seled) {
      if (seled[i].OId == oid && seled[i].Event.OSCId == sid) {
        return true;
      }
    }
    return false;
  }

  getBodyItem(days, cday, start, end, vid) {
    const tds = [];
    for (var j = 0; j < days; j++) {
      if (this.Is_CSSeled3(vid, this.gsid_Campday(vid, cday))) {
        tds.push(
          <div className="ss_cscol2_1">
            <img
              className="campdot"
              onClick={this.handleSlotClick}
              id={this.gsid_Campday(vid, cday)}
              src="/img/CampIcon/icon_campsite_selected.png"
            />
          </div>
        );
      } else {
        var av = this.ck_AV4Campday(vid, cday);
        if (av)
          tds.push(
            <div className="ss_cscol2_1">
              <img
                className="campdot"
                onClick={this.handleSlotClick}
                id={this.gsid_Campday(vid, cday)}
                src="/img/CampIcon/icon_campsite_available.png"
              />
            </div>
          );
        else
          tds.push(
            <div className="ss_cscol2_1">
              <img
                className="campdot"
                onClick={this.handleSlotClick}
                id={this.gsid_Campday(vid, cday)}
                src="/img/CampIcon/icon_campsite_notAvailable.png"
              />
            </div>
          );
      }
      cday = this.addDays(cday, 1);
    }
    return tds;
  }

  getCSTableBody(days, start, end, d_start, d_end, cday) {
    var uloc = window.location.toString();
    var isnode = uloc.indexOf("/campsite#");
    var oid = uloc.substr(isnode + 10, 36);

    const bodys = [];
    if (this.state.c_MapObjId == oid) {
      if (this.state.c_CR_CampList.length > 0) {
        for (var i = 0; i < this.state.c_CR_CampList.length; i++) {
          if (this.ck_CSFilter(this.state.c_CR_CampList[i])) {
            cday = d_start;
            bodys.push(
              <div className="row" id={this.state.c_CR_CampList[i].VId}>
                <div
                  onClick={this.handleSiteClick}
                  id={this.state.c_CR_CampList[i].VId}
                  className="ss_cscol2_2 m-tab"
                >
                  <span className="campltit" id={this.state.c_CR_CampList[i].VId}>
                    {this.state.c_CR_CampList[i].Name}
                  </span>
                </div>
                {this.getBodyItem(
                  days,
                  cday,
                  start,
                  end,
                  this.state.c_CR_CampList[i].VId
                )}
                <div className="ss_cscol2_1"></div>
              </div>
            );
          } else {
            bodys.push(
              <div className="d-flex justify-content-center bg-danger rounded mb-3 mx-4">
                <span className="text-white">No Results</span>
              </div>
            );
            bodys.length = 2;
          }
        }
      }
    } else {
      if (this.state.c_CR_CampList.length > 0) {
        for (var i = 0; i < this.state.c_CR_CampList.length; i++) {
          var node = getNodeFromTree(this.props.smalltree, this.state.c_CR_CampList[i].VId);
          if (node.PId != this.state.c_MapObjId) continue;
          if (this.ck_CSFilter(this.state.c_CR_CampList[i])) {
            cday = d_start;
            bodys.push(
              <div className="row" id={this.state.c_CR_CampList[i].VId}>
                <div
                  onClick={this.handleSiteClick}
                  id={this.state.c_CR_CampList[i].VId}
                  className="ss_cscol2_2"
                >
                  <span className="campltit" id={this.state.c_CR_CampList[i].VId}>
                    {this.state.c_CR_CampList[i].Name}
                  </span>
                </div>
                {this.getBodyItem(
                  days,
                  cday,
                  start,
                  end,
                  this.state.c_CR_CampList[i].VId
                )}
                <div className="ss_cscol2_1"></div>
              </div>
            );
          } else {
            bodys.push(
              <div className="d-flex justify-content-center bg-danger rounded mb-3 mx-4">
                <span className="text-white">No Results</span>
              </div>
            );
            bodys.length = 2;
          }
        }
      }
    }

    return bodys;
  }

  ck_CSFilter(camp) {
    if (camp == null) return false;
    var len = this.props.Filter.Length;
    var pow = this.props.Filter.Power;
    var wat = this.props.Filter.Water;
    var sew = this.props.Filter.Sewer;
    var tab = this.props.Filter.Table;
    var fir = this.props.Filter.Fire;
    if (camp.Len < len) {
      return false;
    } else if (camp.Pow < pow) {
      return false;
    } else if (camp.Wat < wat) {
      return false;
    } else if (camp.Sew < sew) {
      return false;
    } else if (camp.Tab < tab) {
      return false;
    } else if (camp.Fir < fir) {
      return false;
    }
    return true;
  }

  bd_CamOpt() {
    const opts = [];
    var uloc = window.location.toString();
    var isnode = uloc.indexOf("/campsite#");
    var oid = uloc.substr(isnode + 10, 36);

    opts.push(
      <option value={oid} selected>
        All
      </option>
    );
    for (var i = 0; i < this.props.c_MapInfo.Objs.length; i++) {
      var node = getNodeFromTree(this.props.smalltree, this.props.c_MapInfo.Objs[i].OId);
      opts.push(
        <option value={node.OId} selected>
          {node.Name}
        </option>
      );
    }

    return (
      <div className="row">
        <div className="col-2"></div>
        <div className="col-8">
          <select
            name="Length"
            className="form-control login-inputs"
            value={this.state.c_MapObjId}
            onChange={this.handleLoopSelChange}
          >
            {opts}
          </select>
        </div>
        <div className="col-2"></div>
      </div>
    );
  }

  bd_CamList2() {
    const table = [];
    var start = new Date(this.state.OStartDate);
    var end = new Date(this.state.OEndDate);
    var do_start = new Date(this.state.StartDate);
    var do_end = new Date(this.state.EndDate);

    var num = Math.round((do_end - do_start) / (1000 * 60 * 60 * 24));
    var p = 0,
      n = 0;
    switch (num) {
      case 1:
        p = -3;
        n = 3;
        break;
      case 2:
        p = -2;
        n = 3;
        break;
      case 3:
        p = -2;
        n = 2;
        break;
      case 4:
        p = -1;
        n = 2;
        break;
      case 5:
        p = -1;
        n = 1;
        break;
      case 6:
        p = 0;
        n = 1;
        break;
      case 7:
        p = 0;
        n = 0;
        break;
      default:
        break;
    }

    var d_start = this.addDays(do_start, p);
    var d_end = this.addDays(do_end, n);
    var days = Math.round((d_end - d_start) / (1000 * 60 * 60 * 24));
    if (days > 7) days = 7;

    var cday = d_start;
    this.cFirstDay = cday;

    table.push(
      <div>
        <div className="row">{this.getCSTableHead(days, start, end, cday)}</div>
        <div>
          {" "}
          {this.getCSTableBody(days, start, end, d_start, d_end, cday)}
        </div>
      </div>
    );

    return <div>{table}</div>;
  }

  IMGURL = "https://s3-us-west-2.amazonaws.com/sportsites-filer";
  get_3Img(i) {
    return (
      <div className="col-md-4 venitembox">
        <div className="venitem">
          <img src={this.IMGURL + this.state.CampInfo.Imgs[i].Path} />
        </div>
      </div>
    );
  }

  g_Photos() {
    const imgs = [];
    for (var i = 0; i < this.state.CampInfo.Imgs.length; i++) {
      imgs.push(this.get_3Img(i));
    }
    return <div className="row">{imgs}</div>;
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            {this.state.PagePos == 1 ? (
              <div>
                <div className="card p-3">
                  <div>
                    <span className="description-title">Name:</span>{" "}
                    {this.state.CampInfo.Name}
                  </div>
                  <div>
                    <span className="description-title">Description:</span>{" "}
                    {this.state.CampInfo.Desc == ""
                      ? "N/A"
                      : this.state.CampInfo.Desc}
                  </div>
                  <div>
                    <span className="description-title">Message:</span>{" "}
                    {this.state.CampInfo.Welc == ""
                      ? "N/A"
                      : htmlToText(this.state.CampInfo.Welc)}
                  </div>
                  <div>
                    <span className="description-title">Power:</span>{" "}
                    {this.state.CampInfo.Pow == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Table:</span>{" "}
                    {this.state.CampInfo.Tab == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Sewer:</span>{" "}
                    {this.state.CampInfo.Sew == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Fire pit:</span>{" "}
                    {this.state.CampInfo.Fir == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Water:</span>{" "}
                    {this.state.CampInfo.Wat == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Length:</span>{" "}
                    {this.state.CampInfo.Len}
                  </div>
                </div>
                {this.g_Photos()}
                <div className="tacenter">
                  <a>
                    <button
                      onClick={() => {
                        this.handleBackClick();
                      }}
                      className="filled-button mb-4"
                    >
                      Back
                    </button>
                  </a>
                </div>
              </div>
            ) : (
              <div>{this.bd_CamList2()}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ListView;
