export function parseTotal(val) {
  return parseFloat(val).toFixed(2);
}

//fmtFloat2
export const parseAmount = (val) => {
  return (Math.round(val * 100) / 100).toFixed(2);
};

export const parseFloatAmount = (val) => {
  return parseFloat((Math.round(val * 100) / 100).toFixed(2));
}