import React from "react";
import { ssm_getcookie } from "../../../common/utils/ssm_util";
import Constant from "../../../common/constants/define";
import Spinner from "../../../common/components/Spinner";
import SSCalendar from "../../../common/components/SSCalendar";
import ActivitySchedule from "./activityschedule";
import { Buffer } from "buffer";
import { htmlToText } from "../../../common/utils/utils";
import BackTitle from "../../../common/components/back/BackTitle";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../common/components/modal";
import Alert from "../../../common/components/modal/alert";
import {
  getNode,
  preReserveNode,
  getVenueSlots,
  getNodeAnnouncement,
} from "../../../services/home/nodeDetail";
import { getAnnouncementDetail } from "../../../services/common/getAnnouncementMessage";
import {
  checkConflict,
  checkGroup,
  exMulslots,
  filterFce,
  filterMxPos,
  getGender,
  getNodeFromTree,
  getRealName,
  getVRInfo,
  f_DR_m,
} from "../../../common/utils/node";
import {
  addDays,
  filterWeek,
  formatCompleteDateENGB,
  formatDateEN,
} from "../../../common/utils/date";
import { parseTotal } from "../../../common/utils/parse";
import { filterDaysSchedules, filterVens, getRegsCinC } from "./utils";
import Map from "../../../common/components/maps";

class NodeDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      node: {},
      loading: false,
      tabPos: 1,
      PagePos: 1,
      C_MulSlots: [],
      A_Fces: [],
      FC_Fces: [],
      ofces: [],
      A_Exclu: [],
      A_Vens: [],
      currentMonth: 0,
      closemsg: "",
      annmsg: "",
      Annos: [],
      reservedSlots: [],
      cutOff: 0,
      initialDate: "",
      isPublish: true,
      isActivityFull: false,
      modal: false,
      showAlert: false,
      alertMessage: "",
      VDescription: "",
      VDescriptionError: "",
      lat: 0,
      lng: 0,
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleSelSlotsClick = this.handleSelSlotsClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleBack2Click = this.handleBack2Click.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleannclick = this.handleannclick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  orgid = 0;

  buttonDOM = React.createRef();

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");

    let uloc = window.location.toString();
    let isnode = uloc.indexOf("/node#");
    let oid = uloc.substr(isnode + 6, 36);

    this.getVenueDetail(oid);
    this.get_Anous2(1, oid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.node.Activity !== this.state.node.Activity) {
      if (checkGroup(this.state.node)) {
        if (
          this.state.node.Activity?.MaxGrou !== 0 &&
          this.state.node.Activity?.CurGrou >= this.state.node.Activity?.MaxGrou
        ) {
          this.setState({
            isActivityFull: true,
          });
        } else {
          this.setState({
            isActivityFull: false,
          });
        }
      } else {
        if (
          this.state.node.Activity?.MaxMem !== 0 &&
          this.state.node.Activity?.CurMem >= this.state.node.Activity?.MaxMem
        ) {
          this.setState({
            isActivityFull: true,
          });
        } else {
          this.setState({
            isActivityFull: false,
          });
        }
      }
    }

    if (prevState.annmsg !== this.state.annmsg) {
      if (this.state.annmsg !== "") {
        this.setState({ modal: true });
      }
    }
  }

  handleAlertClose() {
    this.setState({ showAlert: false, alertMessage: "" });
  }

  handleTabClick(pos) {
    this.setState({ tabPos: pos });
  }

  del_MulSlots(id) {
    let mslots = this.state.C_MulSlots;
    for (let i in mslots) {
      if (id == mslots[i].Event.id) {
        mslots.splice(i, 1);
      }
    }
    this.setState({ C_MulSlots: mslots });
  }

  handleannclick(id) {
    this.g_OrgAn(id);
  }

  pre_Fce_m5(Schedules, event, flag) {
    let fce = {};
    fce.Start =
      formatCompleteDateENGB(event.start) + " " + formatDateEN(event.start);
    fce.End = formatCompleteDateENGB(event.end) + " " + formatDateEN(event.end);
    fce.id = event.id;
    fce.Type = event.Type;
    fce.Buf = 0;
    fce.VId = this.state.node.OId;
    if (flag == 0) fce.SCId = event.SCId;
    fce.OrgId = this.orgid;
    fce.SubsId = 0;
    fce.Name = "";
    fce.Desc = "";
    fce.SubsTp = Constant.PREO_SLOT;
    Schedules.push(fce);
  }

  handleDescriptionChange = (event) => {
    let val = event.target.value;

    this.setState({
      VDescription: val,
    });
  };

  ref_fcfces(vrs, fces) {
    let reserved = this.state.reservedSlots;
    let scheduleEvents = this.state.ofces;
    let events = fces;
    let events2 = [];
    let reservedEvents = [];
    let unavailableColor = "gray";
    let unavailableTitle = "Unavailable";

    for (let i = 0; i < reserved.length; i++) {
      reservedEvents.push(reserved[i]);
    }

    for (const i in reservedEvents) {
      let date1 = new Date(reservedEvents[i].Start);
      let date2 = new Date(reservedEvents[i].End);
      let os = (date2 - date1) / 3600000 / 24 + 1;
      for (let j = 0; j < Math.floor(os); j++) {
        let timeStart = new Date(date1.getTime());
        let timeEnd = new Date(date2.getTime());
        reservedEvents[i].start = timeStart;
        reservedEvents[i].end = timeEnd;
        reservedEvents[i].color = unavailableColor;
        reservedEvents[i].title = unavailableTitle;
      }
    }

    if (scheduleEvents && scheduleEvents.length > 0) {
      for (const i in scheduleEvents) {
        let date1 = new Date(scheduleEvents[i].Start);
        let date2 = new Date(scheduleEvents[i].End);
        let os = (date2 - date1) / 3600000 / 24 + 1;
        for (let j = 0; j < Math.floor(os); j++) {
          let timeStart = new Date(date1.getTime());
          let timeEnd = new Date(date2.getTime());
          scheduleEvents[i].start = timeStart;
          scheduleEvents[i].end = timeEnd;
          scheduleEvents[i].title = scheduleEvents[i].Name.slice(7, -4);
        }
      }
    }

    let map = {},
      i;
    for (i = 0; i < reservedEvents.length; ++i) {
      map[reservedEvents[i].start] = true;
    }

    for (i = 0; i < events.length; ++i) {
      if (exMulslots(events[i].id, vrs)) {
        events[i].color = "blue";
      } else {
        if (checkConflict(events[i], vrs)) {
          let fce = filterFce(events[i].id, this.state.A_Fces);
          fce.gray = true;
          events[i].color = unavailableColor;
          events[i].title = unavailableTitle;
        } else {
          let fce = filterFce(events[i].id, this.state.A_Fces);
          fce.gray = false;
          events[i].color = "#378006";
          events[i].title = "";
        }
      }

      if (map[events[i].start]) {
        events[i].color = unavailableColor;
        events[i].title = unavailableTitle;
        map[events[i].start] = 0;
      }
    }

    for (let k = 0; k < reservedEvents.length; ++k) {
      var end2=new Date(reservedEvents[k].end.getFullYear(),reservedEvents[k].end.getMonth(),reservedEvents[k].end.getDate(),reservedEvents[k].end.getHours(),reservedEvents[k].end.getMinutes()+reservedEvents[k].Buf);
      for (let l = 0; l < events.length; ++l) {
        if (!(
          events[l].start >=end2  ||
          events[l].end <= reservedEvents[k].start
        )) {
          events[l].color = unavailableColor;
          events[l].title = unavailableTitle;
        }
        /*if (
          reservedEvents[k].start > events[l].start &&
          reservedEvents[k].start < events[l].end
        ) {
          events[l].color = unavailableColor;
          events[l].title = unavailableTitle;
        }

        if (
          reservedEvents[k].end > events[l].start &&
          reservedEvents[k].end < events[l].end
        ) {
          events[l].color = unavailableColor;
          events[l].title = unavailableTitle;
        }

        if (
          events[l].start >= reservedEvents[k].start &&
          events[l].end <= reservedEvents[k].end
        ) {
          events[l].color = unavailableColor;
          events[l].title = unavailableTitle;
        }*/
      }
    }

    if (scheduleEvents && scheduleEvents.length > 0) {
      if (this.state.node.OType == Constant.TP_VENUE)
        events2 = [...events, ...scheduleEvents];
      else events2 = [...events, ...reservedEvents, ...scheduleEvents];
    } else {
      if (this.state.node.OType == Constant.TP_VENUE) events2 = [...events];
      else events2 = [...events, ...reservedEvents];
    }

    let cutOffDates = "";
    const time = Date.now();
    const initialDate = new Date(time);

    for (let i = 0; i < this.state.cutOff; i++) {
      const startDate = initialDate;
      cutOffDates = addDays(startDate, i);
    }

    for (let i = 0; i < events2.length; i++) {
      if (events2[i].start < cutOffDates || events2[i].end < cutOffDates) {
        events2[i].display = "none";
      }

      if (events2[i].Name !== undefined && events2[i].Name !== "") {
        let testHtml = events2[i].Name;
        let strippedHtml = htmlToText(testHtml);
        if (events2[i].title === "undefined") {
          events2[i].title = unavailableTitle;
        }
        if (strippedHtml[1] === undefined) {
          events2[i].title = unavailableTitle;
        } else if (strippedHtml[2] === undefined) {
          events2[i].title = `${strippedHtml[1]}`;
        } else {
          events2[i].title = `${strippedHtml[1]}  -  ${strippedHtml[2]}`;
        }
      }
    }

    this.setState({ FC_Fces: events2 });
  }

  ck_MAvail_m(vr) {
    let Schedules = [];

    if (vr) {
      if (vr.Cked != 0 && vr.Cked != 1) {
        if (vr.Event.Type == Constant.PLAN_SLOT)
          this.pre_Fce_m5(Schedules, vr.Event, 1);
        else if (vr.Event.Type == Constant.RAND_SLOT)
          this.pre_Fce_m5(Schedules, vr.Event, 0);
      }
    }

    if (Schedules.length > 0) this.Rsv_MSlot_m2(vr, Schedules, 0);
  }

  async Rsv_MSlot_m2(vr, fces) {
    const response = await preReserveNode(this.orgid, fces);
    const events = fces;
    if (response.Id == 0) {
      let mrrt = JSON.parse(response.Data);
      if (mrrt.PIds.length > 0) {
        for (let m = 0; m < mrrt.PIds.length; m++) {
          vr.Event.SCId = mrrt.PIds[m].NId;
          vr.Cked = 1;
          let vrs = this.state.C_MulSlots;
          vrs.push(vr);
          this.setState({ C_MulSlots: vrs });
          this.ref_fcfces(vrs, this.state.A_Fces);
        }
      } else {
        this.setState({
          showAlert: true,
          alertMessage:
            "Some of the selected sites are already in your shopping cart.",
        });
      }
    }
  }

  get_vr(event, flag) {
    if (event) {
      var vr = getVRInfo(event, this.state.C_Plan, event.blkid);
      vr.HdFces = [];
      if (flag == 1) vr.Cked = 1;
      vr.Event.CTime = new Date();
      return vr;
    }
  }

  handleEventClick(e) {
    if (this.state.node.OType != Constant.TP_VENUE) return;
    if (exMulslots(e.event.id, this.state.C_MulSlots)) {
      this.del_MulSlots(e.event.id);
      this.ref_fcfces(this.state.C_MulSlots, this.state.A_Fces);
    } else {
      let fce = filterFce(e.event.id, this.state.A_Fces);
      if (fce) {
        if (fce.title === "") {
          let vr = this.get_vr(fce);
          this.ck_MAvail_m(vr);
        }
      }
    }
  }

  handleBackClick(node) {
    let item = getNodeFromTree(this.props.smalltree, node.OId);
    if (item.PId == "00000000-0000-0000-0000-000000000000") {
      this.props.onNavigate("/");
    } else {
      this.props.onNavigate(`/folder#${item.PId}`);
    }
  }

  handleBack2Click(node) {
    this.setState({ PagePos: 1 });
    this.ref_fcfces(this.state.C_MulSlots, this.state.A_Fces);
  }

  async g_OrgAn(id) {
    getAnnouncementDetail(id).then((response) => {
      this.setState({ annmsg: response });
    });
  }

  async getVenueDetail(oid) {
    let node = getNodeFromTree(this.props.smalltree, oid);
    this.setState({ loading: true });
    const response = await getVenueSlots(oid, node.OType);
    if (response.Id == 0) {
      let All = JSON.parse(response.Data);
      let Venue = JSON.parse(All.Venue);
      let ce_Node = JSON.parse(Venue.Data);
      let a = ce_Node.Plans;
      let Slots = JSON.parse(All.Slots);
      let C_ReservedFces = JSON.parse(Slots.Data);

      let fces = [];
      let month = "";

      if (ce_Node.OType == Constant.TP_VENUE) {
        this.setState({ reservedSlots: C_ReservedFces });
        this.setState({
          cutOff: ce_Node.Venue.Cutoff,
          lat: parseFloat(ce_Node.Addrs[0].Lati),
          lng: parseFloat(ce_Node.Addrs[0].Long),
        });
        fces = this.getVenueEvent(ce_Node);
      } else {
        fces = this.getSportEvent(C_ReservedFces);
      }

      if (fces != null && fces.length > 0) {
        month = fces[0].start.getMonth();
      }

      this.setState({
        node: ce_Node,
        A_Fces: fces,
        ofces: ce_Node.UnSches,
        loading: false,
        currentMonth: month,
      });

      if (node.OType == Constant.TP_VENUE) {
        const initialDate = new Date(this.state?.A_Fces[0]?.start);
        this.setState({
          initialDate: initialDate,
        });
      }

      if (this.state.ofces !== undefined) {
        const initialDate = new Date(this.state?.ofces[0]?.Start);
        this.setState({
          initialDate: initialDate,
        });
      }

      this.ref_fcfces(this.state.C_MulSlots, fces);
      if (ce_Node != undefined) this.deal_Message_c(ce_Node);

      this.setState({ reservedSlots: C_ReservedFces });
    } else if (response.Id == 5) {
      let err = {};
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    }
  }

  async getDetail(oid) {
    let node = getNodeFromTree(this.props.smalltree, oid);
    if (node.OType == Constant.TP_VENUE) return this.getVenueDetail(oid);
    this.setState({ loading: true });
    const response = await getNode(oid, node.OType);
    if (response.Id == 0) {
      let lnode = JSON.parse(response.Data);
      let fces = [];
      let month = "";
      if (lnode.OType == Constant.TP_VENUE) {
        fces = this.getVenueEvent(lnode);
      } else {
        fces = this.getSportEvent(lnode);
      }
      if (fces != null && fces.length > 0) {
        month = fces[0].start.getMonth();
      }
      this.setState({
        node: lnode,
        A_Fces: fces,
        ofces: lnode.UnSches,
        loading: false,
        currentMonth: month,
      });

      this.ref_fcfces(this.state.C_MulSlots, fces);
      if (lnode != undefined) this.deal_Message_c(lnode);
    } else if (response.Id == 5) {
      let err = {};
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    }
  }

  handleSelSlotsClick(node) {
    if (this.state.C_MulSlots.length == 0) {
      this.setState({
        showAlert: true,
        alertMessage:
          "Please select at least one slot to start your reservation.",
      });
    } else {
      this.setState({ PagePos: 2 });
    }
  }

  handleRegisterClick(node) {
    if (
      this.state.node.OType === Constant.TP_VENUE &&
      this.state.VDescription === ""
    ) {
      this.setState({ VDescriptionError: "Required." });
      return;
    }
    if (this.state.node.OType === Constant.TP_VENUE) this.fil_VReg();
    this.props.onRegisterClick(node, this.state.C_MulSlots);
    this.setState({ VDescriptionError: "" });
  }

  fil_VReg() {
    for (let i in this.state.C_MulSlots) {
      let Schedules2 = [];
      this.pre_Fce_m4(
        Schedules2,
        this.state.VDescription,
        this.state.C_MulSlots[i]
      );
      let rq = {};
      rq.Type = 1;
      rq.Flag = 1; //co_SType;
      rq.Sches = Schedules2;
      let jsonstr = JSON.stringify(rq);
      this.state.C_MulSlots[i].VR_Rq = new Buffer(jsonstr).toString("base64");
      this.state.C_MulSlots[i].HdFces.length = 0;
    }
  }

  pre_Fce_m4(Schedules, desc, vrinfo) {
    let fce = {};
    fce.Start = formatCompleteDateENGB(vrinfo.Event.start)+ " " + formatDateEN(vrinfo.Event.start);
    fce.End = formatCompleteDateENGB(vrinfo.Event.end)+ " " + formatDateEN(vrinfo.Event.end);
    fce.id = vrinfo.Event.id;
    fce.SCId = vrinfo.Event.SCId;
    let dr = f_DR_m(vrinfo.Event.blkid,this.state.C_Plan);
    fce.Buf =parseInt(dr.Buf);
    //console.log("Buf:"+fce.Buf);
    fce.Type = vrinfo.Type;
    fce.VId = this.state.node.OId;
    fce.OrgId = this.orgid;
    fce.SubsId = 0; //c_User==null?0:c_User.PersonInfo.Person.PId;
    fce.Name = ""; //c_User==null?"":c_User.PersonInfo.Person.Emai;
    fce.Desc = desc;
    fce.SubsTp = Constant.TP_PLAYER;
    Schedules.push(fce);
  }

  get_Fees(node) {
    let type = node.Type;
    let fees = this.state.node.Fees;
    const table = [];

    for (let i = 0; i < fees.length; i++) {
      if (fees[i].Type === 3) {
        fees[i].Name = "Register Team";
      }
      if (fees[i].Type === 4) {
        fees[i].Name = "Find Team";
      }
      if (fees[i].Type === 5) {
        fees[i].Name = "Free Agent";
      }
      if (fees[i].Type === 6) {
        fees[i].Name = "Each Player";
      }

      if (fees[i].Type == Constant.FEETP_LATE || fees[i].Amou == -1) continue;
      table.push(
        <div>
          {getRealName(fees[i].Name)} :{" "}
          <span> ${parseTotal(fees[i].Amou)}</span>
        </div>
      );
    }

    return <div>{table}</div>;
  }

  ad_VensTimes2(ds, drng, date_b, date_e) {
    if (
      !this.ck_inExclu_m(drng.ExVen_Rngs, date_b, date_e, drng.Ven_Items[0])
    ) {
      let vt = {};
      vt.BlkId = drng.Id;
      vt.Start = date_b;
      vt.End = date_e;
      vt.Ven_Items = drng.Ven_Items;
      vt.Day_Rngs = drng.Day_Rngs;

      ds.VensTimes.push(vt);
    }
  }

  ad_DaysSches(t, t2, drng, DaysSches) {
    let d = t.getDate();
    let m = t.getMonth();
    let y = t.getFullYear();
    let d2 = t2.getDate();
    let m2 = t2.getMonth();
    let y2 = t2.getFullYear();
    let date_b = new Date(m + 1 + "/" + d + "/" + y + " " + drng.Starttm);
    let date_e = new Date(m2 + 1 + "/" + d2 + "/" + y2 + " " + drng.Endtm);

    let ds = filterDaysSchedules(t, DaysSches);
    if (ds == null) {
      let ds = {};
      ds.Date = t;
      ds.Slots = 0;
      ds.VensTimes = [];
      this.ad_VensTimes2(ds, drng, date_b, date_e);
      DaysSches.push(ds);
    } else {
      this.ad_VensTimes2(ds, drng, date_b, date_e);
    }
  }

  ck_inExclu_m(Exs, start, end, venid) {
    let flag = false;
    if (Exs != null && Exs.length > 0) {
      for (let i = 0; i < Exs.length; i++) {
        if (!(Exs[i].b >= end || Exs[i].e <= start) && Exs[i].venid == venid) {
          flag = true;
        }
      }
    }
    if (this.state.A_Exclu != null && this.state.A_Exclu.length > 0) {
      for (let i = 0; i < this.state.A_Exclu.length; i++) {
        if (
          !(
            this.state.A_Exclu[i].b >= end || this.state.A_Exclu[i].e <= start
          ) &&
          this.state.A_Exclu[i].venid == venid
        ) {
          flag = true;
        }
      }
    }
    return flag;
  }

  ad_VensTimes(ds, drng, y, m, d) {
    if (drng.Day_Rngs.length > 0) {
      for (let l = 0; l < drng.Day_Rngs.length; l++) {
        if (filterWeek(ds.Date.getDay(), drng.Day_Rngs[l].Weekday) == true) {
          let date_b = new Date(
            m + 1 + "/" + d + "/" + y + " " + drng.Day_Rngs[l].Start
          );
          let date_e = new Date(
            m + 1 + "/" + d + "/" + y + " " + drng.Day_Rngs[l].End
          );

          if (
            !this.ck_inExclu_m(
              drng.ExVen_Rngs,
              date_b,
              date_e,
              drng.Ven_Items[0]
            )
          ) {
            let vt = {};
            vt.BlkId = drng.Id;
            vt.Start = date_b;
            vt.End = date_e;
            vt.Ven_Items = drng.Ven_Items;
            vt.Day_Rngs = drng.Day_Rngs;

            ds.VensTimes.push(vt);
          }
        }
      }
    }
  }

  c_CalEvt_m(Tab, s, e, venid, blkid, AFces, CFces, flag, node) {
    let ven = null;
    let now = new Date();
    let st = new Date(s);

    ven = filterVens(venid, this.state.A_Vens);

    let fce = {};
    fce.id = AFces.length + 1;
    fce.app = 0;
    fce.OrgId = this.orgid;
    fce.start = s;
    fce.end = e;

    fce.title = "";
    let newnow = new Date();
    newnow.setDate(now.getDate() + node.Venue.Cutoff);
    let nd = newnow.getDate();
    let nm = newnow.getMonth();
    let ny = newnow.getFullYear();
    let now1 = new Date(nm + 1 + "/" + nd + "/" + ny);
    if (now1 < st) {
      fce.className = "v_ply_av";
    } else {
      fce.className = "v_adm_av";
    }

    fce.ven = ven;
    fce.vid = venid;
    fce.blkid = blkid;
    fce.Type = Constant.PLAN_SLOT;
    fce.editable = false;
    fce.allDay = false;

    let now2 = new Date(nm + 1 + "/" + nd + "/" + ny);
    if (now2 <= st || flag == 1) {
      AFces.push(fce);
      CFces.push(fce);
    }

    return fce;
  }

  b_AvalSlot2(Tab, DR, DaysSches, AFces, CFces, node) {
    for (let j = 0; j < DaysSches.length; j++) {
      for (let k in DaysSches[j].VensTimes) {
        for (let n = 0; n < DaysSches[j].VensTimes[k].Ven_Items.length; n++) {
          let date_b = DaysSches[j].VensTimes[k].Start;
          let date_e = DaysSches[j].VensTimes[k].End;
          if (DR.Mode == 0) {
            DaysSches[j].Slots++;

            this.c_CalEvt_m(
              Tab,
              date_b,
              date_e,
              DaysSches[j].VensTimes[k].Ven_Items[n],
              DaysSches[j].VensTimes[k].BlkId,
              AFces,
              CFces,
              0,
              node
            ); //2
          } else {
            let date_e2 = new Date(Date.parse(date_b) + DR.Minim * 60000);
            while (date_e2 <= date_e) {
              DaysSches[j].Slots++;

              this.c_CalEvt_m(
                Tab,
                date_b,
                date_e2,
                DaysSches[j].VensTimes[k].Ven_Items[n],
                DaysSches[j].VensTimes[k].BlkId,
                AFces,
                CFces,
                0,
                node
              );
              date_b = new Date(Date.parse(date_b) + DR.Every * 60000);
              date_e2 = new Date(Date.parse(date_e2) + DR.Every * 60000);
            }
          }
        }
      }
    }
  }

  Sche2Cal_m2(Tab, Plan, DaysSches, AFces, CFces, node) {
    AFces.length = 0;
    CFces.length = 0;

    for (let i = 0; i < Plan.Data_Rngs.length; i++) {
      DaysSches.length = 0;
      if (Plan.Data_Rngs[i].Type == 0) {
        let date1 = new Date(Plan.Data_Rngs[i].Start);
        let date2 = new Date(Plan.Data_Rngs[i].End);
        let os = (date2 - date1) / 3600000 / 24 + 1;
        for (let j = 0; j < Math.floor(os); j++) {
          let t = new Date(
            date1.getTime() + 5 * 60 * 60 * 1000 + j * 24 * 60 * 60 * 1000
          );
          let d = t.getDate();
          let m = t.getMonth();
          let y = t.getFullYear();

          let ds = filterDaysSchedules(t, DaysSches);
          if (ds == null) {
            let ds = {};
            ds.Date = t;
            ds.Slots = 0;
            ds.VensTimes = [];
            this.ad_VensTimes(ds, Plan.Data_Rngs[i], y, m, d);
            DaysSches.push(ds);
          } else {
            this.ad_VensTimes(ds, Plan.Data_Rngs[i], y, m, d);
          }
        }
      } else if (Plan.Data_Rngs[i].Type == 1) {
        let dateutl = new Date(Plan.Data_Rngs[i].Until);
        let date1 = new Date(Plan.Data_Rngs[i].Start);
        let date2 = new Date(Plan.Data_Rngs[i].End);
        let os = (dateutl - date1) / 3600000 / 24 + 1;
        for (let j = 0; j < os; j++) {
          let t = new Date(
            date1.getTime() + 5 * 60 * 60 * 1000 + j * 24 * 60 * 60 * 1000
          );
          let t2 = new Date(
            date2.getTime() + 5 * 60 * 60 * 1000 + j * 24 * 60 * 60 * 1000
          );
          if (Plan.Data_Rngs[i].Cycle == 0) {
            if (filterWeek(t.getDate(), Plan.Data_Rngs[i].CycleM) == true) {
              this.ad_DaysSches(t, t2, Plan.Data_Rngs[i], DaysSches);
            }
          } else if (Plan.Data_Rngs[i].Cycle == 1) {
            if (filterWeek(t.getDay(), Plan.Data_Rngs[i].CycleW) == true) {
              this.ad_DaysSches(t, t2, Plan.Data_Rngs[i], DaysSches);
            }
          } else if (Plan.Data_Rngs[i].Cycle == 2) {
            this.ad_DaysSches(t, t2, Plan.Data_Rngs[i], DaysSches);
          }
        }
      }
      this.b_AvalSlot2(Tab, Plan.Data_Rngs[i], DaysSches, AFces, CFces, node);
    }
  }

  bd_ExBlk_m(Tab, Plan, DaysSches, AFces, CFces) {
    this.state.A_Exclu.length = 0;
    for (let i = 0; i < Plan.Data_Rngs.length; i++) {
      Plan.Data_Rngs[i].ExVen_Rngs = [];
      for (let k = 0; k < Plan.Data_Rngs[i].Ex_Rngs.length; k++) {
        for (let n = 0; n < Plan.Data_Rngs[i].Ven_Items.length; n++) {
          let date1 = new Date(Plan.Data_Rngs[i].Ex_Rngs[k].Start);
          let date2 = new Date(Plan.Data_Rngs[i].Ex_Rngs[k].End);
          let os = (date2 - date1) / 3600000 / 24 + 1;
          for (let j = 0; j < os; j++) {
            let date_b = new Date(
              date1.getTime() + 5 * 60 * 60 * 1000 + j * 24 * 60 * 60 * 1000
            );
            let date_e = new Date(
              date1.getTime() +
                5 * 60 * 60 * 1000 +
                (j + 1) * 24 * 60 * 60 * 1000
            );

            let obj = {};
            obj.b = date_b;
            obj.e = date_e;
            obj.venid = Plan.Data_Rngs[i].Ven_Items[n];
            Plan.Data_Rngs[i].ExVen_Rngs.push(obj);
            if (Plan.Data_Rngs[i].Ex_Rngs[k].Glb == true)
              this.state.A_Exclu.push(obj);
          }
        }
      }
    }
  }

  s_RegVenPlan(node, type) {
    this.state.C_Plan = JSON.parse(node.Plans[0].SPlan);
    let C_DaysSches = [];
    let allfces = [];
    let curfces = [];
    this.bd_ExBlk_m(null, this.state.C_Plan, null, null, null);
    this.Sche2Cal_m2(
      "#sp_tab",
      this.state.C_Plan,
      C_DaysSches,
      allfces,
      curfces,
      node
    );

    return allfces;
  }

  handleDeleteClick(fce) {
    this.del_MulSlots(fce.Event.id);
  }

  get_Slot(fce) {
    const item = fce.Event.start;
    const item2 = fce.Event.end;
    let str = formatCompleteDateENGB(item) + " " + formatDateEN(item);
    let str2 = formatCompleteDateENGB(item2) + " " + formatDateEN(item2);
    return (
      <div id="cart-item" className="row csvseled">
        <div className="col-10">
          <div className="cart-item-name">
            {str} - {str2}
          </div>
        </div>
        <div className="col-2 ta-right cart-item-delete">
          <img
            onClick={() => {
              this.handleDeleteClick(fce);
            }}
            width="24"
            src="img/close-icon.svg"
            alt="delete item"
          />
        </div>
      </div>
    );
  }

  getSelectedSlots() {
    const fces = [];
    for (let i in this.state.C_MulSlots) {
      fces.push(this.get_Slot(this.state.C_MulSlots[i]));
    }
    return <div>{fces}</div>;
  }

  IMGURL = "https://s3-us-west-2.amazonaws.com/sportsites-filer";
  get_3Img(i) {
    return (
      <div className="col-md-4 venitembox">
        <div className="venitem">
          <img src={this.IMGURL + this.state.node.VImgs[i].Path} />
        </div>
      </div>
    );
  }

  g_Photos() {
    const imgs = [];
    for (let i = 0; i < this.state.node.VImgs.length; i++) {
      imgs.push(this.get_3Img(i));
    }
    return <div className="row">{imgs}</div>;
  }

  getSportEvent(node) {
    let events2 = [];

    for (let i in node.UnSches) {
      let eve = {};
      eve.id = node.UnSches[i].SCId;
      eve.start = new Date(node.UnSches[i].Start);
      eve.end = new Date(node.UnSches[i].End);
      eve.title = node.UnSches[i].Name.replace("<br>", " ");
      eve.title = eve.title.replace(/<[^>]+>/g, "");
      events2.push(eve);
    }
    return events2;
  }

  getVenueEvent(node) {
    return this.s_RegVenPlan(node, Constant.SCHETP_VEN);
  }

  getAmend() {
    const items = [];

    let flag = 1;
    let os = JSON.parse(this.state.node.Venue.Amens);

    if (os != null) {
      for (let i = 0; i < os.length; i++) {
        if (flag == 0) {
        } else if (flag == 1) {
          if (i == 0) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 1 ? (
                  <div>Electricity : Yes</div>
                ) : (
                  <div>Electricity : No</div>
                )
              );
          } else if (i == 1) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Sports Fields : None</div>
                ) : (
                  <div>Sports Fields : {os[i].V}</div>
                )
              );
          } else if (i == 2) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Restrooms : None</div>
                ) : (
                  <div>Restrooms : {os[i].V}</div>
                )
              );
          } else if (i == 3) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Tennis Courts : None</div>
                ) : (
                  <div>Tennis Courts : {os[i].V}</div>
                )
              );
          } else if (i == 4) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Picnic Tables : None</div>
                ) : (
                  <div>Picnic Tables : {os[i].V}</div>
                )
              );
          } else if (i == 5) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Pool : None</div>
                ) : (
                  <div>Pool : {os[i].V}</div>
                )
              );
          } else if (i == 6) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Pavilions : None</div>
                ) : (
                  <div>Pavilions : {os[i].V}</div>
                )
              );
          } else if (i == 7) {
            if (os[i].V == -1) items.push(<div></div>);
            else
              items.push(
                os[i].V == 0 ? (
                  <div>Play Grounds : None</div>
                ) : (
                  <div>Play Grounds : {os[i].V}</div>
                )
              );
          }
        }
      }
    }

    return <div>{items}</div>;
  }

  ck_Sum(node, flag) {
    let ccnt = getRegsCinC(this.state.node.OId);
    if (flag == 0) {
      if (node.MaxMem == 0) return false;
      if (node.CurMem + ccnt >= node.MaxMem && node.CurMem != 0) {
        return true;
      } else {
        return false;
      }
    } else if (flag == 1) {
      if (node.MaxGrou == 0) return false;
      if (node.CurGrou + ccnt >= node.MaxGrou && node.CurGrou != 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  deal_Message_c(node) {
    let obj = null;
    switch (node.OType) {
      case Constant.TP_ITEM:
        obj = node.Item;
        break;
      case Constant.TP_VENUE:
        obj = node.Venue;
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        obj = node.Activity;
        break;
    }

    let now = new Date();
    let lrstartd = obj.LRStart;
    let lrendd = obj.LREnd;
    let rstart = obj.RStart;
    let rend = obj.REnd;
    let lrstart = lrstartd;
    let lrend = lrendd;

    let max = false;
    if (this.ck_Sum(obj, obj.Type & 1) == true) {
      max = true;
    }
    if (obj.Count <= 0 && obj.Count != -100) {
      max = true;
    }

    let close = false;
    if (lrstartd != "" && lrendd != "") {
      if (now >= new Date(rstart) && now <= new Date(rend)) {
        let i = 0;
      } else if (now >= new Date(lrstart) && now <= new Date(lrend)) {
        if (max == false) {
        }
      } else {
        close = true;
        if (max == false) {
          if ((node.OType === Constant.TP_ITEM && !obj.SStart) || !obj.SEnd) {
            if (node.OType === Constant.TP_VENUE) {
              if (now >= new Date(obj.REnd)) {
                this.setState({
                  closemsg:
                    obj.RegClosed == ""
                      ? "Registration Closed or Full"
                      : obj.RegClosed,
                });
              }
            } else {
              this.setState({
                closemsg: "",
              });
            }
          } else {
            this.setState({
              closemsg:
                obj.RegClosed == ""
                  ? "Registration Closed or Full"
                  : obj.RegClosed,
            });
          }
        }
      }
    } else if (lrstartd == "" || lrendd == "") {
      if (now >= new Date(rstart) && now <= new Date(rend)) {
        let i = 0;
      } else {
        close = true;
      }
    }
  }

  async get_Anous2(flag, oid) {
    const response = await getNodeAnnouncement(this.orgid, oid, flag);
    this.setState({ Annos: response.Annos });
  }

  gen_Anouitem(obj) {
    return (
      <div className="ann-msg" onClick={() => this.handleannclick(obj.AId)}>
        <a className="card p-2 d-flex flex-row align-items-center mb-2 anou2">
          <img className="annimg" src="/img/mess.gif" />
          {obj.Name}
        </a>
      </div>
    );
  }

  toggle() {
    this.setState({ modal: !this.state.modal, annmsg: "" });
  }

  gen_Anous(oans) {
    if (oans.length == 0) return "";
    const msg = [];
    msg.push(<h8 className="ann-label">ANNOUNCEMENTS</h8>);

    for (let i = 0; i < oans.length; i++) {
      oans[i].DFlag = 0;
    }

    for (let m in oans) {
      let obj = filterMxPos(oans);
      msg.push(this.gen_Anouitem(obj));
    }

    return (
      <div className="d-flex flex-column justify-content-center ansbox2">
        {msg}
      </div>
    );
  }

  render() {
    let actionname, lnode, startword, endword, scheduleStart, scheduleEnd;
    let slotseltext;
    slotseltext = "Selected ( " + this.state.C_MulSlots.length + " )";

    switch (this.state.node.OType) {
      case Constant.TP_ITEM:
        lnode = this.state.node.Item;
        actionname = "Purchase";
        startword = "Availability Open";
        endword = "Availability Closed";
        scheduleStart = "Schedule Start";
        scheduleEnd = "Schedule End";
        break;
      case Constant.TP_VENUE:
        lnode = this.state.node.Venue;
        actionname = "Reserve";
        startword = "Reservation Open";
        endword = "Reservation Closed";
        scheduleStart = "Schedule Start";
        scheduleEnd = "Schedule End";
        break;
      case Constant.TP_SPORT:
      case Constant.TP_CLASS:
      case Constant.TP_EVENT:
        lnode = this.state.node.Activity;
        actionname = "Register";
        startword = "Registration Open";
        endword = "Registration Closed";
        scheduleStart = "Schedule Start";
        scheduleEnd = "Schedule End";
        break;
    }
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="container">
            <div className="row section-title-container">
              <div className="col-12">
                <BackTitle
                  title={lnode.Name}
                  onBack={() => {
                    this.handleBackClick(this.state.node);
                  }}
                />
              </div>
            </div>
            <div>{this.gen_Anous(this.state.Annos)}</div>
            {this.state.PagePos == 2 ? (
              <div className="content-no-space container-fluid">
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 vensel mb-3">
                    <div className="row csvseled">
                      selected ( {this.state.C_MulSlots.length} )
                    </div>
                    <input
                      className="form-control login-inputs"
                      type="text"
                      name="VDescription"
                      placeholder="Description"
                      value={this.state.VDescription}
                      onChange={this.handleDescriptionChange}
                    />
                    {this.state.VDescriptionError !== "" && (
                      <span className="text-danger mt-1 mb-1">
                        {this.state.VDescriptionError}
                      </span>
                    )}
                    {this.getSelectedSlots()}
                    <div className="tacenter">
                      <a>
                        <button
                          onClick={() => {
                            this.handleRegisterClick(this.state.node);
                          }}
                          className="filled-button"
                        >
                          Reserve
                        </button>
                      </a>
                    </div>
                    <div className="tacenter">
                      <a>
                        <button
                          onClick={() => {
                            this.handleBack2Click();
                          }}
                          className="border-button"
                        >
                          Back
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            ) : (
              <div id="home-session-tabs">
                <div className="content-no-space container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <ul
                        className="nav nav-pills nav-justified"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className={
                              this.state.tabPos == 1
                                ? "nav-link active rounded-circle mr-2"
                                : "nav-link rounded-circle mr-2"
                            }
                            onClick={() => {
                              this.handleTabClick(1);
                            }}
                          >
                            Info
                          </a>
                        </li>
                        {this.state.node.OType != Constant.TP_VENUE &&
                          this.state.node.OType != Constant.TP_ITEM &&
                          this.state.ofces !== undefined &&
                          (this.state.ofces[0]?.App & Constant.APP_PUBED) ===
                            Constant.APP_PUBED && (
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.tabPos == 3
                                    ? "nav-link active rounded-circle mr-2"
                                    : "nav-link rounded-circle mr-2"
                                }
                                onClick={() => {
                                  this.handleTabClick(3);
                                }}
                              >
                                Schedule
                              </a>
                            </li>
                          )}
                        {this.state.node.OType != Constant.TP_ITEM &&
                          this.state.closemsg == "" &&
                          this.state.ofces !== undefined &&
                          (this.state.ofces[0]?.App & Constant.APP_PUBED) ===
                            Constant.APP_PUBED && (
                            <li className="nav-item">
                              <a
                                className={
                                  this.state.tabPos == 2
                                    ? "nav-link active rounded-circle mr-2"
                                    : "nav-link rounded-circle mr-2"
                                }
                                ref={(buttonDOM) => {
                                  this.buttonDOM = buttonDOM;
                                }}
                                onClick={() => {
                                  this.handleTabClick(2);
                                }}
                              >
                                Calendar
                              </a>
                            </li>
                          )}
                        {this.state.ofces === undefined && (
                          <li className="nav-item">
                            <a
                              className={
                                this.state.tabPos == 2
                                  ? "nav-link active rounded-circle mr-2"
                                  : "nav-link rounded-circle mr-2"
                              }
                              ref={(buttonDOM) => {
                                this.buttonDOM = buttonDOM;
                              }}
                              onClick={() => {
                                this.handleTabClick(2);
                              }}
                            >
                              Calendar
                            </a>
                          </li>
                        )}

                        <li className="nav-item">
                          <a
                            className={
                              this.state.tabPos == 4
                                ? "nav-link active rounded-circle"
                                : "nav-link rounded-circle"
                            }
                            onClick={() => {
                              this.handleTabClick(4);
                            }}
                          >
                            Photos
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content mb-3" id="myTabContent">
                        {this.state.tabPos == 1 ? (
                          <div className="sstab-pane">
                            <section id="family-member-profile">
                              <div className="container-fluid">
                                <div
                                  id="family-member-pinfo"
                                  className="row act-desc-pinfo"
                                >
                                  <div className="col-12">
                                    {this.state.closemsg != "" ? (
                                      <div className="closemsgbox">
                                        {this.state.closemsg}
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {this.state.isActivityFull && (
                                      <span style={{ color: "red" }}>
                                        Program is Full
                                      </span>
                                    )}
                                    <p className="info-label">Description</p>
                                    <p className="sline-item-name ssdeswel">
                                      {lnode.Desc}
                                    </p>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: lnode.Welc,
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  id="family-member-pinfo"
                                  className="row family-member-pinfo"
                                >
                                  <div className="col-12">
                                    <p className="list-title-tag">
                                      Information
                                    </p>
                                    <p className="info-label">Name</p>
                                    <p className="sline-item-name">
                                      {lnode.Name}
                                    </p>
                                    {lnode.RStart &&
                                      lnode.RStart !== "1/1/1970 12:00:00 AM" &&
                                      lnode.RStart !==
                                        "1/1/1970 11:59:00 PM" && (
                                        <>
                                          <p className="info-label">
                                            {startword}
                                          </p>
                                          <p className="sline-item-name">
                                            {formatCompleteDateENGB(
                                              lnode.RStart
                                            ) +
                                              " " +
                                              formatDateEN(lnode.RStart)}
                                          </p>
                                        </>
                                      )}
                                    {lnode.REnd &&
                                      lnode.REnd !== "1/1/1970 12:00:00 AM" &&
                                      lnode.REnd !== "1/1/1970 11:59:00 PM" && (
                                        <>
                                          <p className="info-label">
                                            {endword}
                                          </p>
                                          <p className="sline-item-name">
                                            {formatCompleteDateENGB(
                                              lnode.REnd
                                            ) +
                                              " " +
                                              formatDateEN(lnode.REnd)}
                                          </p>
                                        </>
                                      )}
                                    {lnode.SStart &&
                                      lnode.SStart !== "1/1/1970 12:00:00 AM" &&
                                      lnode.SStart !==
                                        "1/1/1970 11:59:00 PM" && (
                                        <>
                                          <p className="info-label">
                                            {scheduleStart}
                                          </p>
                                          <p className="sline-item-name">
                                            {formatCompleteDateENGB(
                                              lnode.SStart
                                            )}
                                          </p>
                                        </>
                                      )}
                                    {lnode.SEnd &&
                                      lnode.SEnd !== "1/1/1970 11:59:00 PM" &&
                                      lnode.SEnd !== "1/1/1970 12:00:00 AM" && (
                                        <>
                                          <p className="info-label">
                                            {scheduleEnd}
                                          </p>
                                          <p className="sline-item-name">
                                            {formatCompleteDateENGB(lnode.SEnd)}
                                          </p>
                                        </>
                                      )}
                                    {lnode.LRStart &&
                                      lnode.LRStart !==
                                        "1/1/1970 12:00:00 AM" &&
                                      lnode.LRStart !==
                                        "1/1/1970 11:59:00 PM" && (
                                        <>
                                          <p className="info-label">
                                            Late Registration Start
                                          </p>
                                          <p className="sline-item-name">
                                            {formatCompleteDateENGB(
                                              lnode.LRStart
                                            ) +
                                              " " +
                                              formatDateEN(lnode.LRStart)}
                                          </p>
                                        </>
                                      )}
                                    {lnode.LREnd &&
                                      lnode.LREnd !== "1/1/1970 12:00:00 AM" &&
                                      lnode.LREnd !==
                                        "1/1/1970 11:59:00 PM" && (
                                        <>
                                          <p className="info-label">
                                            Late Registration End
                                          </p>
                                          <p className="sline-item-name">
                                            {formatCompleteDateENGB(
                                              lnode.LREnd
                                            ) +
                                              " " +
                                              formatDateEN(lnode.LREnd)}
                                          </p>
                                        </>
                                      )}
                                    {this.state.node.OType ===
                                    Constant.TP_VENUE ? (
                                      <div>
                                        <p className="info-label">Amenities</p>
                                        <p className="sline-item-name">
                                          {this.getAmend()}
                                        </p>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {this.state.node.OType !=
                                      Constant.TP_ITEM &&
                                    this.state.node.OType !=
                                      Constant.TP_VENUE ? (
                                      <div>
                                        <p className="info-label">Gender</p>
                                        <p className="sline-item-name">
                                          {getGender(lnode.Gend)}
                                        </p>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {this.state.node.OType !=
                                    Constant.TP_VENUE ? (
                                      <div>
                                        <p className="info-label">Fees</p>
                                        <p className="sline-item-name">
                                          {this.get_Fees(lnode)}
                                        </p>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    {/* {this.state.node.OType !=
                                      Constant.TP_ITEM &&
                                    this.state.node.OType !=
                                      Constant.TP_VENUE ? (
                                      <div>
                                        {checkGroup(this.state.node) ? (
                                          <>
                                            <p className="info-label">
                                              Max Teams
                                            </p>
                                            <p className="sline-item-name">
                                              {lnode.MaxGrou == 0
                                                ? "Unlimited"
                                                : lnode.MaxGrou}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                             <p className="info-label">
                                              Max Participants
                                            </p>
                                            <p className="sline-item-name">
                                              {lnode.MaxMem == 0
                                                ? "Unlimited"
                                                : lnode.MaxMem}
                                            </p> 
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <div></div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                              <>
                                {Constant.TP_VENUE &&
                                this.state.lat &&
                                this.state.lng &&
                                !isNaN(this.state.lat) &&
                                !isNaN(this.state.lng) ? (
                                  <div>
                                    <div className="container-fluid">
                                      <div className="map-container">
                                        <Map
                                          lat={this.state.lat}
                                          lng={this.state.lng}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </>
                            </section>
                            <div id="cd-info-container">
                              {this.state.closemsg != "" ? (
                                <div></div>
                              ) : (
                                <div className="tacenter">
                                  <a>
                                    <button
                                      onClick={() => {
                                        this.state.node.OType ==
                                        Constant.TP_VENUE
                                          ? this.handleTabClick(2)
                                          : this.handleRegisterClick(
                                              this.state.node
                                            );
                                      }}
                                      className="filled-button"
                                      disabled={this.state.isActivityFull}
                                    >
                                      {actionname}
                                    </button>
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {this.state.node.OType != Constant.TP_VENUE &&
                        this.state.node.OType != Constant.TP_ITEM ? (
                          this.state.tabPos === 3 ? (
                            <div className="sstab-pane mb-5">
                              <ActivitySchedule
                                ofces={this.state.ofces}
                                A_Fces={this.state.A_Fces}
                                node={this.state.node}
                              />
                            </div>
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div></div>
                        )}
                        {this.state.node.OType != Constant.TP_ITEM ? (
                          this.state.tabPos === 2 ? (
                            <div className="sstab-pane mb-5">
                              <div>
                                {this.state.node.OType === Constant.TP_VENUE ? (
                                  <div className="d-flex justify-content-center rounded selected-title">
                                    <div>
                                      <div className="selected-text">
                                        {slotseltext}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                                <div
                                  className={
                                    this.state.closemsg != "" && "mb-5"
                                  }
                                >
                                  <SSCalendar
                                    fces={this.state.FC_Fces}
                                    currentMonth={this.state.currentMonth}
                                    oneventclick={this.handleEventClick}
                                    initialDate={this.state.initialDate}
                                  />
                                </div>
                                {this.state.node.OType === Constant.TP_VENUE ? (
                                  <div className="tacenter">
                                    {this.state.closemsg != "" ? (
                                      <></>
                                    ) : (
                                      <a>
                                        <button
                                          onClick={() => {
                                            this.handleSelSlotsClick(
                                              this.state.node
                                            );
                                          }}
                                          className="filled-button mb-4"
                                        >
                                          Continue
                                        </button>
                                      </a>
                                    )}
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div></div>
                        )}
                        {this.state.tabPos == 4 ? (
                          <div className="sstab-pane">
                            <div className="container">{this.g_Photos()}</div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Modal isOpen={this.state.modal}>
              <ModalHeader>
                <h5>Announcement</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggle}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <div className="anbox">
                  <div
                    className="orgwel"
                    dangerouslySetInnerHTML={{ __html: this.state.annmsg }}
                  ></div>
                </div>
              </ModalBody>
            </Modal>
            <Alert
              message={this.state.alertMessage}
              show={this.state.showAlert}
              onClose={this.handleAlertClose}
            />
          </div>
        )}
      </div>
    );
  }
}

export default NodeDetail;
