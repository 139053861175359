import React from "react";

import NodeItem from "./node_item";

class NodeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nodes: this.props.nodes };
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(item) {
    this.props.onItemClick(item);
  }

  render() {
    return this.props.nodes.map((n) => {
      return (
        <NodeItem
          onItemClick={() => {
            this.handleItemClick(n);
          }}
          node={n}
          key={n.OId}
        />
      );
    });
  }
}

export default NodeList;
