import React from "react";
import { useNavigate } from "react-router-dom";

const EmptySC = () => {
  const navigate = useNavigate();
  return (
    <section id="cart-container" className="mt-5">
      <div className="container-fluid mb-5">
        <div id="cart-title" className="row mb-5">
          <div id="empty-cart-title" className="col-12">
            <p className="cart-title">
              There are no items
              <br />
              in your shopping cart
            </p>
            <p className="items-amount">0 items</p>
          </div>
        </div>
        <div id="cart-totals" className="row">
          <div id="cart-totals-labels" className="col-6">
            <p className="cart-totals-labels">Subtotal</p>
            <p className="cart-totals-labels">Taxes</p>
            <p className="cart-totals-total">Total</p>
          </div>
          <div id="cart-totals-amounts" className="col-6">
            <p className="cart-totals-labels">$0.00</p>
            <p className="cart-totals-labels">$0.00</p>
            <p className="cart-totals-total">$0.00</p>
          </div>
        </div>
        <div id="cart-actions">
          <a>
            <button
              onClick={() => navigate("/")}
              className="border-button"
            >
              Continue browsing
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default EmptySC;
