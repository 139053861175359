import React from "react";
import {
  Home,
  ListAlt,
  ContactPage,
  ShoppingCart,
  Login,
  AppRegistration,
} from "@mui/icons-material";
import SportsitesMessage from "../message";
import { Link } from "react-router-dom";

const FooterTab = ({ name, Icon, to }) => {
  return (
    <Link
      className="menu-item"
      to={to}
    >
      <Icon htmlColor="#fff" />
      <span className="menu-item-text">{name}</span>
    </Link>
  );
};

const Footer = ({ isLogged }) => {
  return isLogged == 1 ? (
    <>
      <div className="bg-white fixed-bottom">
        <SportsitesMessage bottom={80} />
      </div>
      <footer className="d-flex justify-content-around align-items-center fixed-bottom footer">
        <FooterTab name="Home" Icon={Home} to="/"/>
        <FooterTab name="Activities" Icon={ListAlt} to="/myhome" />
        <FooterTab name="Contact us" Icon={ContactPage} to="/contact" />
        <FooterTab name="Cart" Icon={ShoppingCart} to="/store" />
      </footer>
    </>
  ) : (
    <div className="fixed-bottom">
      <div className="bg-white">
        <SportsitesMessage bottom={80} />
      </div>
      <footer className="d-flex justify-content-around align-items-center fixed-bottom footer">
        <FooterTab name="Home" Icon={Home} to="/" />
        <FooterTab name="Login" Icon={Login} to="/login" />
        <FooterTab
          name="Register"
          Icon={AppRegistration}
          to="/registeraccount"
        />
        <FooterTab name="Contact us" Icon={ContactPage} to="/contact" />
      </footer>
    </div>
  );
};

export default Footer;
