import React from "react";
import Spinner from "../../common/components/Spinner";
import MyFamily from "./familly";
import MyPurchases from "./purchases";
import MyProfile from "./profile";
import { ssm_getcookie } from "../../common/utils/ssm_util";

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tabPos: 1,
      family: this.props.family,
      self: this.props.family.PersonInfo.Person,
    };

    this.handleMyProfileClick = this.handleMyProfileClick.bind(this);
    this.handleMyFamilyClick = this.handleMyFamilyClick.bind(this);
    this.handleMyPurchaseClick = this.handleMyPurchaseClick.bind(this);
    this.handleErr = this.handleErr.bind(this);
  }

  orgid = 0;

  componentDidMount() {
    if (document.hasFocus()) {
      this.props.onRefresh();
    }
  }

  handleErr(err) {
    this.props.onErr(err);
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  handleMyProfileClick() {}
  handleMyFamilyClick() {}
  handleMyPurchaseClick() {}

  isActive(val) {
    return val === this.state.tabPos ? "nav-link active show" : "nav-link";
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="container">
            <div id="home-session-tabs">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <ul
                      className="nav nav-pills nav-justified"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active rounded-circle mr-2"
                          onClick={this.handleMyProfileClick}
                          id="info-tab"
                          data-toggle="tab"
                          href="#person-info-tab"
                          role="tab"
                          aria-controls="info"
                          aria-selected="true"
                        >
                          Profile
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link rounded-circle mr-2"
                          onClick={this.handleMyFamilyClick}
                          id="schedule-tab"
                          data-toggle="tab"
                          href="#family-info-tab"
                          role="tab"
                          aria-controls="schedule"
                          aria-selected="false"
                        >
                          Family
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link rounded-circle"
                          onClick={this.handleMyPurchaseClick}
                          id="participant-tab"
                          data-toggle="tab"
                          href="#purchases-info-tab"
                          role="tab"
                          aria-controls="participant"
                          aria-selected="false"
                        >
                          Purchases
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content mb-5" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="person-info-tab"
                        role="tabpanel"
                        aria-labelledby="info-tab"
                      >
                        <MyProfile
                          person={this.state.self}
                          family={this.state.family}
                          onLogout={this.props.onLogout}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="family-info-tab"
                        role="tabpanel"
                        aria-labelledby="schedule-tab"
                      >
                        <MyFamily
                          family={this.state.family}
                          onLogout={this.props.onLogout}
                        />
                      </div>
                      <div
                        className="tab-pane fade"
                        id="purchases-info-tab"
                        role="tabpanel"
                        aria-labelledby="schedule-tab"
                      >
                        <MyPurchases
                          onErr={this.handleErr}
                          family={this.state.family}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MyAccount;
