import React from "react";

class NodeItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(item) {
    this.props.onItemClick(item);
  }

  getIcon(item) {
    let img = "";
    if (item.OType == 1) img = "/img/folder.png";
    else if (item.OType == 2) img = "/img/sport.png";
    else if (item.OType == 6) img = "/img/event.png";
    else if (item.OType == 4) img = "/img/class.png";
    else if (item.OType == 7) img = "/img/venue.png";
    else if (item.OType == 14) img = "/img/item.png";

    return <img className="nodeicn" src={img} />;
  }

  render() {
    return (
      <div
        className="card d-flex flex-row justify-content-between mb-2"
        id="activity-row"
        onClick={() => this.handleItemClick(this.props.node)}
      >
        <div className="col-1">{this.getIcon(this.props.node)}</div>
        <div className="col-9">
          {this.props.node.Name}
        </div>
        <div className="col-2 ta-right">
          {this.props.node.OType == 1 ? (
            <img width="24" src="img/arrow-right-icon.svg" alt="see more" />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default NodeItem;
