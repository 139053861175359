import Constant from "../constants/define";
import { htmlToText } from "../utils/utils";

export const getfeeAmou = (node, fid) => {
  for (let i in node.Fees) {
    if (node.Fees[i].FId == fid) return node.Fees[i].Amou;
  }
  return 0;
};

export const getfeeName = (node, fid) => {
  for (let i in node.Fees) {
    if (node.Fees[i].FId == fid) return node.Fees[i].Name;
  }
  return "";
};

export const getinoutfee = (inout, node) => {
  for (let i in node.Fees) {
    if (node.Fees[i].Type == 1) {
      if (inout) {
        if (node.Fees[i].Name.indexOf("IN Postal Code") >= 0) {
          return node.Fees[i];
        }
      } else {
        if (node.Fees[i].Name.indexOf("OUT Postal Code") >= 0) {
          return node.Fees[i];
        }
      }
    }
  }
  return null;
};

export const checkfees = (item) => {
  let rt = false;
  let node = null;
  switch (item.OType) {
    case Constant.TP_ITEM:
      node = item.Item;
      break;
  }

  let t5 = (node.Type & 2) == 2;
  if (!t5) {
    if (item.Fees.length > 2) rt = true;
  }

  return rt;
};

export const d_StItem_l2 = (pcdid, cnt, allpcds) => {
  let num = 0;
  let pcds = allpcds;
  for (let i = pcds.length - 1; i >= 0; i--) {
    if (pcds[i].PPCDId == pcdid && pcds[i].Type == Constant.PCD_MAIN) {
      if (num < cnt) {
        pcds.splice(i, 1);
        num++;
      }
    }
  }
  return pcds;
};

export const getFces = (fces) => {
  let fcfces = [];
  for (let i in fces) {
    let f = new Object();
    f.id = fces[i].SCId;
    f.start = new Date(fces[i].Start);
    f.end = new Date(fces[i].End);
    f.title = fces[i].Desc.replace(/<[^>]+>/g, "");
    let titleHtml = fces[i].Name;
    let strippedHtml = htmlToText(titleHtml);
    if (strippedHtml[1] === undefined && strippedHtml[2] === undefined) {
      f.title = fces[i].Desc.replace(/<[^>]+>/g, "");
    } else if (strippedHtml[2] === undefined) {
      f.title = `${strippedHtml[1]}`;
    } else {
      f.title = `${strippedHtml[1]}  -  ${strippedHtml[2]}`;
    }
    fcfces.push(f);
  }
  return fcfces;
};

export const getTeamFce = (fces, node) => {
  let fces2 = [];
  for (let i in fces) {
    if (
      node.Act.AType != Constant.TP_CLASS &&
      node.Act.AType != Constant.TP_EVENT
    ) {
      if (fces[i].HmId == node?.Group.GId || fces[i].GuId == node?.Group.GId) {
        fces2.push(fces[i]);
      }
    } else {
      fces2.push(fces[i]);
    }
  }
  fces2 = getFces(fces2);
  return fces2;
};

export const getNodeFromTree = (tree, oid) => {
  for (let i in tree) {
    if (tree[i].OId == oid) {
      return tree[i];
    }
  }
};

export const getChildNodeFromTree = (tree, oid) => {
  let nod = [];
  for (let i in tree) {
    if (tree[i].PId == oid) {
      nod.push(tree[i]);
    }
  }
  return nod;
};

export const getChildNode = (oid, tree) => {
  let childs = [];
  for (let i in tree) {
    if (
      tree[i].PId == oid &&
      (tree[i].OType == 1 ||
        (tree[i].App & Constant.APP_PUBED) == Constant.APP_PUBED)
    ) {
      childs.push(tree[i]);
    }
  }
  return childs;
};

export const getParentNode = (pnode, tree) => {
  for (let i in tree) {
    if (tree[i].OId == pnode.PId) {
      return tree[i];
    }
  }
  return [];
};

export const checkGroup = (node) => {
  let bGroup = false;

  switch (node.OType) {
    case Constant.TP_ITEM:
    case Constant.TP_VENUE:
      break;
    case Constant.TP_SPORT:
    case Constant.TP_CLASS:
    case Constant.TP_EVENT:
      let t0 = (node.Activity.Type & 1) == 1;
      let t1 = (node.Activity.Type & 4) == 4;
      let t2 = (node.Activity.Type & 8) == 8;
      let t3 = (node.Activity.Type & 16) == 16;
      let t4 = (node.Activity.Type & 32) == 32;
      let tp = 1; // $("select[name='regtp']").val();

      if (!t0) {
        bGroup = false;
      } else if (t1 && !t2 && !t3) {
        bGroup = true;
      } else if (!t1 && t2 && !t3) {
        bGroup = false;
      } else if (!t1 && !t2 && t3) {
        bGroup = false;
      } else if (t1 && t2 && !t3) {
        bGroup = tp == 1;
      } else if (!t1 && t2 && t3) {
        bGroup = false;
      } else if (t1 && !t2 && t3) {
        bGroup = tp == 1;
      } else if (t1 && t2 && t3) {
        bGroup = tp == 1;
      }

      break;
  }

  return bGroup;
};


export const f_DR_m = (drid, Plan) => {
  if (Plan.Data_Rngs.length > 0) {
    for (let m in Plan.Data_Rngs) {
      if (Plan.Data_Rngs[m].Id == drid) {
        return Plan.Data_Rngs[m];
      }
    }
  }
  return null;
};

export const getVRInfo = (event, plan, blkid) => {
  let l_VRInfo = {};
  if (event.Type == Constant.PLAN_SLOT) {
    let c_DR = f_DR_m(blkid, plan);
    l_VRInfo.Dr = c_DR;
    l_VRInfo.Event = event;
    l_VRInfo.Type = Constant.PLAN_SLOT;
  } else if (event.Type == Constant.RAND_SLOT) {
    l_VRInfo.Type = Constant.RAND_SLOT;
    l_VRInfo.Event = event;
    l_VRInfo.ExHours = 0;
  }
  return l_VRInfo;
};


export const filterFce = (id, fces) => {
  for (let i in fces) {
    if (id == fces[i].id) {
      return fces[i];
    }
  }
};

export const exMulslots = (id, vrs) => {
  for (let i in vrs) {
    if (id == vrs[i].Event.id) {
      return true;
    }
  }
  return false;
};

export const getRealName = (name) => {
  if (name.indexOf("(IN Postal Code)") >= 0) {
    return name.substr(0, name.indexOf("(IN Postal Code)"));
  } else if (name.indexOf("(OUT Postal Code)") >= 0) {
    return name.substr(0, name.indexOf("(OUT Postal Code)"));
  } else {
    return name;
  }
};

export const getGender = (gend) => {
  let rt;
  if (gend == 1) rt = "Co-Ed";
  else if (gend == 2) rt = "Male";
  else if (gend == 3) rt = "Female";

  return rt;
};

export const checkConflict = (event, vrs) => {
  for (let i in vrs) {
    if (!(event.start >= vrs[i].Event.end || event.end <= vrs[i].Event.start)) {
      return true;
    }
  }
  return false;
};

//Anouncements
export const filterFstPos = (list) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].DFlag == 0) return i;
  }
  return 0;
};

export const filterMxPos = (list) => {
  let num = filterFstPos(list);
  let maxpos = list[num].Pos;
  let maxpos2 = num;
  for (let i = 0; i < list.length; i++) {
    if (list[i].Pos > maxpos && list[i].DFlag == 0 && i != maxpos2) {
      maxpos = list[i].Pos;
      maxpos2 = i;
    }
  }

  list[maxpos2].DFlag = 1;
  return list[maxpos2];
};

export const check_gend = (gend, node) => {
  let bgrad = true;
  let ce_Node = node;
  let co_OType = node.OType;
  if (co_OType == Constant.TP_VENUE || co_OType == Constant.TP_ITEM) {
    bgrad = true;
  } else if (
    co_OType == Constant.TP_SPORT ||
    co_OType == Constant.TP_CLASS ||
    co_OType == Constant.TP_EVENT
  ) {
    let grad = gend;
    switch (ce_Node.Activity.Gend) {
      case 1:
        bgrad = true;
        break;
      case 2:
        if (grad == 1) bgrad = true;
        else bgrad = false;
        break;
      case 3:
        if (grad == 2) bgrad = true;
        else bgrad = false;
        break;
    }
  }
  return bgrad;
}