module.exports = {
    SSM_GO_USERLOGIN: 1,
    SSM_GO_USERREGISTRATION: 2,
    SSM_GO_USERLOGOUT: 3,

    SSM_GO_HOME: 4,
    SSM_GO_UPCOMING: 5,

    SSM_GO_MYHOME: 6,
    SSM_GO_MYUPCOMING: 7,
    SSM_GO_MYPURCHASE: 8,
    SSM_GO_MYCALENDAR: 9,
    
    SSM_GO_NODEDETAIL: 10,
    SSM_GO_NODEREGISTRATION: 11,
    SSM_GO_SHOPPINGCART: 12,

    SSM_GO_SEARCH: 13,
    SSM_GO_MYFAM: 14,
    SSM_GO_CONTACT: 15,
    SSM_GO_HELP: 16,


    //payment
    PAY_FISH:0,
    PAY_CASH:1,
    PAY_CHECK:2,
    PAY_CARD:3,
    PAY_MULPAY:4,


    FEETP_INDI:1,
    FEETP_LATE:2,
    FEETP_RTEAM:3,
    FEETP_FTEAM:4,
    FEETP_FAGEN:5,
    FEETP_ACOAC:6,
    

    SSM_ROOTID:'00000000-0000-0000-0000-000000000000',

    TP_ROOT : 0,
    TP_FOLDER : 1,
    TP_SPORT : 2,
    TP_PASS : 3,
    TP_CLASS : 4,
    TP_STORE : 5,
    TP_EVENT : 6,
    TP_VENUE : 7,
    TP_ANNOUNCEMENT : 8,
    TP_TEAM : 9,
    TP_PLAYER : 10,
    TP_COACH : 11,
    TP_STUDENT : 12,
    TP_TEACHER : 13,
    TP_ITEM : 14,
    TP_PARTICIPATOR : 15,
    TP_PERSON : 16,
    TP_ORG : 17,
    TP_ADMIN : 18,
    // TP_USER : 19,
    TP_SYSTEM : 20,
    TP_SYSTEMAV : 21,
    TP_LOCATION : 22,
    TP_TEAM_UNP : 23,
    TP_TEAM_UNC : 24,
    TP_MANAGER : 25,
    TP_RDADMIN : 26,
    TP_REGISTER : 27,
    TP_GL : 28,
    TP_TAX : 29,
    TP_PRER : 30,
    TP_COUPON : 31,
    TP_COMM : 32,
    TP_OFFICAL : 33,
    TP_VOLUNTEER : 34,
    TP_ROADMIN : 35,
    TP_HANDDISCOUNT : 36,
    TP_COMMISS : 37,
    TP_SUBSCRIBER : 38,
    TP_PURCHASER : 39,
    TP_MULREGDISC : 40,
    TP_RESOURCE : 41,
    TP_CODE : 42,
    TP_ROLE : 43,

    PCD_MAIN:1,
    PCD_QA:2,
    PCD_LATEFEE:3,
    PCD_ADDFEE:4,
    PCD_TEAMMEMB:5,
    PCD_TOT:6,
    PCD_GTOT:7,
    PCD_EXRESV:8,

// REGTP_PLAYER : 2;
    REGTP_COACH : 4,// make sense for teacher/manager as well
    REGTP_SING_S : 8,
    REGTP_SING_F : 16,
    REGTP_GROUP : 32,
    REGTP_VENUE : 64,
    REGTP_RITEM : 128,
    REGTP_NRITEM : 256,
    REGTP_ADJUST : 512,

    QTP_QUESMUL : 0,
    QTP_QUESTXT : 1,
    QTP_WAIV : 2,

    FAILED_SLOT:1,
    RAND_SLOT:2,
    PLAN_SLOT:4,
    ACTIVITY_SLOT:8,
    ACTIVITYTOU_SLOT:16,
    
    PREO_SLOT:32,
    CAMP_SLOT:64,
    ROLE_SLOT:128,
    

  // APP_INVAL : 1;
  // APP_ASSI : 2;		
  APP_APPRO :1,
  APP_ACTIVITED : 2,
  APP_PAID : 4,
  APP_REFUND : 8,
  APP_HALFPAID : 16,
  APP_ARCHIVED : 32,
  APP_PUBED : 64,
  APP_USED : 128,
  APP_ENABLED : 256,
  APP_SENT : 512,
  APP_MIGRA : 1024,
  APP_DELREG : 2048,
  APP_RECPAY : 4096,
  APP_DEFAULT : 8192,
  APP_CLOSED : 16384,
  // APP_PUBED2 : 256;
  APP_CHECKEDIN:32768,
  APP_CHECKEDOUT:65536,
  APP_SHIELD:131072,
  APP_MAPDEF:262144,
        
  REG_VENUE : 107,

  PG_ADMIN : 1,
  PG_PLAYER : 2,
  PG_HOME : 3,
  PG_REGISTER : 4,
  PG_REPORT : 5,
  PG_UPLOAD : 6,

  TRANSTP_PAY:1,
  TRANSTP_REF:2,
  TRANSTP_REP:3,
  TRANSTP_PPAY:4,
  TRANSTP_STREC:5

}