import React from "react";
import queryString from "query-string";
import { ssm_getcookie } from "../../../common/utils/ssm_util";
import Spinner from "../../../common/components/Spinner";
import ErrorMsg from "../../../common/components/ErrorMsg";
import ssm_valid from "../../../common/utils/ssm_valid";
import { resetPassword } from "../../../services/auth/resetPassword";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false, errormsg: "", errorflag: 0, passinfo: {} };
    this.handleSetClick = this.handleSetClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleErrOkay = this.handleErrOkay.bind(this);
  }

  orgid = 0;
  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("oldpwd");
    this.requireds.push("newpwd");
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  handleSetClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.passinfo,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.do_reset();
  }

  handleChange = (event) => {
    ssm_valid.ck_valid(this.requireds, event.target, this.errors);
    let val = event.target.value;
    let name = event.target.name;

    if (this.state.passinfo.newpwd != undefined)
      if (name == "newpwd") {
        if (this.state.passinfo.oldpwd != val) {
          this.errors["newpwd"] = "Password does not match.";
        } else {
          this.errors["newpwd"] = "";
        }
      } else {
        if (this.state.passinfo.newpwd != val) {
          this.errors["newpwd"] = "Password does not match.";
        } else {
          this.errors["newpwd"] = "";
        }
      }

    this.setState((prevState) => ({
      passinfo: {
        ...prevState.passinfo,
        [name]: val,
      },
    }));
  };

  handleErrOkay(event) {
    if (this.state.errorflag == 1) this.props.onNavigate("/forget");
    else this.props.onNavigate("/login");
  }

  async do_reset() {
    let parsed = queryString.parse(window.location.search);
    if (parsed.rpw == undefined) {
      this.setState({
        loading: false,
        errormsg:
          "Please check your email to verify the correct url or resend the “forgot email” request.",
        errorflag: 1,
      });
      return;
    }

    const response = await resetPassword(
      this.state.passinfo.newpwd,
      parsed.rpw
    );

    if (response.Id == 0) {
      this.setState({
        loading: false,
        errormsg: "Your password has been reset. Please close this tab.",
        errorflag: 0,
      });
    } else {
      this.setState({
        loading: false,
        errormsg: response.Description,
        errorflag: 1,
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : this.state.errormsg != "" ? (
          <ErrorMsg
            msg={this.state.errormsg}
            flag={this.state.errorflag}
            onErrOkay={this.handleErrOkay}
          />
        ) : (
          <div className="content-area">
            <section id="login-container">
              <div className="container-fluid">
                <div id="login-title" className="row">
                  <div id="empty-cart-title" className="col-12">
                    <p className="login-title">Reset Password</p>
                  </div>
                </div>
                <div id="login-form-container" className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <p className="info-label">New Password</p>
                    <input
                      className="form-control edit-fm-inputs"
                      type="password"
                      name="oldpwd"
                      maxLength={18}
                      onChange={this.handleChange}
                    />
                    {ssm_valid.showerror("oldpwd", this.errors)}
                    <p className="info-label">Confirm new Password</p>
                    <input
                      className="form-control edit-fm-inputs"
                      type="password"
                      name="newpwd"
                      maxLength={18}
                      onChange={this.handleChange}
                    />
                    {ssm_valid.showerror("newpwd", this.errors)}
                    <div className="form-actions-area">
                      <a>
                        <button
                          onClick={this.handleSetClick}
                          className="filled-button-continue"
                          type="button"
                        >
                          Reset
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    );
  }
}

export default ResetPassword;