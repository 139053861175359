import { ssm_getcookie } from "../../common/utils/ssm_util";
import { fetchAPI } from "../config";

export async function finishCart(cart) {
  let jsonstr = JSON.stringify(cart);
  let sid = ssm_getcookie("ssm_sid");
  const para = { jsonstr, sid };
  const data = await fetchAPI("/ssm_api_store/finishcart", para);
  return data;
}
