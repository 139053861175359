import React from "react";
import ssm_valid from "../../../common/utils/ssm_valid";

class Price extends React.Component {
  constructor(props) {
    super(props);

    this.state = { node: this.props.node, fee: {}, loading: false };

    this.handleOkClick = this.handleOkClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  requireds = [];
  errors = {};

  componentDidMount() {
    this.requireds.push("FId");
  }

  handleChange(e) {
    const { name, value } = e.target;
    ssm_valid.ck_selvalid(this.requireds, name, value, this.errors);

    this.setState((prevState) => ({
      fee: {
        ...prevState.fee,
        [name]: value,
      },
    }));
  }

  getPriceContent() {
    const items = [];
    for (var i in this.state.node.Fees) {
      if (this.state.node.Fees[i].Type == 1)
        items.push(
          <option value={this.state.node.Fees[i].FId}>
            {this.state.node.Fees[i].Name}
          </option>
        );
    }

    return (
      <div className="content-area">
        <section id="login-container">
          <div className="container-fluid">
            <div id="login-title" className="row">
              <div id="empty-cart-title" className="col-12">
                <p className="login-title">Fee Info</p>
              </div>
            </div>

            <div id="login-form-container" className="row">
              <div className="col-1"></div>
              <div className="col-10">
                <select
                  className="form-control login-inputs"
                  name="FId"
                  onChange={this.handleChange}
                >
                  <option value="11111111-1111-1111-1111-111111111111" selected>
                    Please select fee type*
                  </option>
                  {items}
                </select>
                {ssm_valid.showerror("FId", this.errors)}
                <div className="tacenter">
                  <a>
                    <button onClick={this.handleOkClick} className="filled-button">
                      Continue
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  handleOkClick() {
    this.setState({ loading: true });
    let rqrt = ssm_valid.ck_required(
      this.requireds,
      this.state.fee,
      this.errors
    );
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }
    rqrt = ssm_valid.ck_allvalid(this.errors);
    if (!rqrt) {
      this.setState({ loading: false });
      return;
    }

    this.props.onOkClick(this.state.fee);
  }

  render() {
    return <div>{this.getPriceContent()}</div>;
  }
}

export default Price;
