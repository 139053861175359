import { fetchAPIWithID } from "../config";

function fi_Guids(guids, userinfo) {
  guids.push(userinfo.PersonInfo.Person.PId);
  for (var i in userinfo.RelaInfos) {
    guids.push(userinfo.RelaInfos[i].Person.PId);
  }
}

export async function getMyFamilyStuff(id, family) {
  let req = {};
  req.OrgId = id;
  req.Guids = [];
  fi_Guids(req.Guids, family);
  req.Type = 0;
  req.Side = 2;

  let req2 = {};
  req2.OrgId = id;
  req2.Guids = [];
  req2.Type = 0;

  let userinfo = family;
  req2.Guids.push(userinfo.PersonInfo.Person.PId);
  for (let i in userinfo.RelaInfos) {
    req2.Guids.push(userinfo.RelaInfos[i].Person.PId);
  }

  let allin = {};
  allin.Myfamilystuffinfo = new Buffer(JSON.stringify(req)).toString("base64");
  allin.Slots = new Buffer(JSON.stringify(req2)).toString("base64");

  const data = await fetchAPIWithID("/ssm_api_user/getmyfamilystuff", allin);
  return data;
}
