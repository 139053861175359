import React from "react";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validNumberRegex = RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/i);
const validStringRegex = RegExp(/<.*?>/i);
const validDateRegex = RegExp(
  /(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/i
);

const ssm_valid = {
  validateForm: function (errors) {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  },
  ck_required: function (requireds, obj, errors) {
    let flag = true;
    for (var i in requireds) {
      if (
        !obj[requireds[i]] ||
        obj[requireds[i]].length == 0 ||
        obj[requireds[i]] == "" ||
        obj[requireds[i]] == "11111111-1111-1111-1111-111111111111"
      ) {
        errors[requireds[i]] = "Required";
        flag = false;
      }
    }

    return flag;
  },
  ck_required2: function (requireds, obj, errors) {
    let flag = true;
    for (var i in requireds) {
      if (
        !obj[requireds[i]] ||
        obj[requireds[i]].length == 0 ||
        obj[requireds[i]] == "" ||
        obj[requireds[i]] == "11111111-1111-1111-1111-111111111111"
      ) {
        flag = false;
      }
    }

    return flag;
  },
  ck_cqrequired: function (requireds, cqs, errors) {
    let flag = true;
    for (var j in cqs) {
      for (var i in requireds) {
        if (cqs[j].QId == requireds[i]) {
          if (cqs[j].Answ == "" || cqs[j].Answ == -1) {
            errors[requireds[i]] = "Required";
            flag = false;
          }
        }
      }
    }

    return flag;
  },

  checkIsCustomQuestionIsAnswered: function (requireds, cqs) {
    let flag = true;
    for (var j in cqs) {
      for (var i in requireds) {
        if (cqs[j].QId == requireds[i]) {
          if (cqs[j].Answ == "" || cqs[j].Answ == -1) {
            flag = false;
          }
        }
      }
    }

    return flag;
  },
  ck_cqvalid: function (requireds, id, value, errors) {
    for (var i in requireds) {
      if (id == requireds[i]) {
        if (value.trim() == "" || value == -1) {
          errors[requireds[i]] = "Required";
          return;
        } else {
          errors[requireds[i]] = validStringRegex.test(value)
            ? "Invalid String"
            : "";
        }
      }
    }
  },
  ck_selvalid: function (requireds, name, value, errors) {
    for (var i in requireds) {
      if (name == requireds[i]) {
        if (
          value.trim() == "" ||
          value == -1 ||
          value == "-1" ||
          value == "11111111-1111-1111-1111-111111111111"
        ) {
          errors[requireds[i]] = "Required";
          return;
        } else {
          errors[requireds[i]] = "";
        }
      }
    }
  },
  ck_radvalid: function (requireds, name, value, errors) {
    for (var i in requireds) {
      if (name == requireds[i]) {
        errors[requireds[i]] = "";
      }
    }
  },
  ck_allvalid: function (errors) {
    for (let key of Object.keys(errors)) {
      let value = errors[key];
      if (value != "") return false;
    }
    return true;
  },
  ck_valid: function (requireds, target, errors) {
    const { name, value } = target;

    for (var i in requireds) {
      if (name == requireds[i] && value.trim() == "") {
        errors[requireds[i]] = "Required";
        return;
      }
    }

    switch (name) {
      case "FName":
      case "LName":
      case "ECon":
      case "Budd":
      case "PCoa":
        errors[name] = validStringRegex.test(value) ? "Invalid Name" : "";
        break;
      case "Addr":
        errors[name] = validStringRegex.test(value) ? "Invalid Address" : "";
        break;
      case "City":
        errors[name] = validStringRegex.test(value) ? "Invalid City" : "";
        break;
      case "BDay":
        errors.BDay = validDateRegex.test(value) ? "" : "Invalid Date";
        break;
      case "description":
        errors.description =
          value.length < 5
            ? ""
            : "Tip : Description must be less than 5 characters long!";
        break;
      case "Emai":
        errors.Emai = validEmailRegex.test(value) ? "" : "Invalid Email";
        break;
      default:
        errors[name] = validStringRegex.test(value) ? "Invalid" : "";
        break;
    }
  },
  showerror: function (name, errors) {
    return (
      errors[name] &&
      errors[name].length > 0 && <div className="error">{errors[name]}</div>
    );
  },
};

export default ssm_valid;
