import React from "react";

import Constant from "../../../../common/constants/define";
import Spinner from "../../../../common/components/Spinner";
import { ssm_getcookie } from "../../../../common/utils/ssm_util";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Add, Remove, RestartAlt } from "@mui/icons-material";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../common/components/modal";
import { htmlToText } from "../../../../common/utils/utils";
import { getCampsiteInformation } from "../../../../services/home/getCampsiteInformation";
import { getMapItems } from "../../../../services/home/getMapItems";
import { getNodeFromTree } from "../../../../common/utils/node";
import { addDays } from "../../../../common/utils/date";
import { checkCampsiteFilter } from "../utils";

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      node: this.props.node,
      sh_Upbtn: 0,
      c_MapObjId: this.props.c_MapObjId,
      MapHeight: 0,
      loading: false,
      StartDate: this.props.StartDate,
      EndDate: this.props.EndDate,
      c_CR_List: this.props.c_CR_List,
      c_CR_Listall: this.props.c_CR_Listall,
      c_CR_CampList: this.props.c_CR_CampList,
      c_MapInfo: this.props.c_MapInfo,
      MapUrl: "",
      modal: false,
      currentItem: {},
      campInfo: {},
      notAvailable: false,
      loading: false,
    };

    this.handleIconClick = this.handleIconClick.bind(this);
    this.handleUpClick = this.handleUpClick.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  IMGURL = "https://s3-us-west-2.amazonaws.com/sportsites-filer";
  orgid = 0;
  imgElement = React.createRef();

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  componentWillReceiveProps(props) {
    if (props.c_MapObjId != this.state.c_MapObjId) {
      var uloc = window.location.toString();
      var isnode = uloc.indexOf("/campsite#");
      var oid = uloc.substr(isnode + 10, 36);

      if (props.c_MapObjId == oid) {
        this.setState({ sh_Upbtn: 0 });
      } else {
        this.setState({ sh_Upbtn: 1 });
      }

      this.setState({ c_MapObjId: props.c_MapObjId });
      this.get_MapIts(props.c_MapObjId);
    }
  }

  toggle() {
    this.setState({ modal: !this.state.modal, notAvailable: false });
  }

  async getcampinfo(oid) {
    this.setState({ loading: true });
    const response = await getCampsiteInformation(oid);
    if (response.Id == 0) {
      var cmsinfo = JSON.parse(response.Data);
      this.setState({ loading: false, campInfo: cmsinfo });
    }
  }

  IMGURL = "https://s3-us-west-2.amazonaws.com/sportsites-filer";

  get_3Img(i) {
    return (
      <div className="col-md-4 venitembox">
        <div className="venitem">
          <img src={this.IMGURL + this.state.campInfo?.Imgs[i].Path} />
        </div>
      </div>
    );
  }

  g_Photos() {
    const imgs = [];
    for (var i = 0; i < this.state.campInfo?.Imgs?.length; i++) {
      imgs.push(this.get_3Img(i));
    }
    return <div className="row">{imgs}</div>;
  }

  componentDidMount() {
    this.rf_ObjMap_v();
    this.get_MapIts(this.state.c_MapObjId);
  }

  gsid_Campday(vid, day) {
    for (var i in this.state.c_CR_Listall) {
      if (vid == this.state.c_CR_Listall[i].VId) {
        var day2 = new Date(this.state.c_CR_Listall[i].End);
        var d = day.toLocaleDateString();
        var d2 = day2.toLocaleDateString();
        if (d == d2) {
          return this.state.c_CR_Listall[i].SId;
        }
      }
    }
    return null;
  }

  rf_ObjMap_v() {
    if (this.state.c_MapInfo != null && this.state.c_MapInfo.Reses != null) {
      if (this.state.c_MapInfo.Reses.length > 0) {
        for (var i in this.state.c_MapInfo.Reses) {
          if (this.state.c_MapInfo.Reses[i].RType == 3) {
            this.setState({
              MapUrl: this.IMGURL + this.state.c_MapInfo.Reses[i].URL,
            });
          }
        }
      }
    }
  }

  getmapcontent() {
    const table = [];
    const table2 = [];

    if (
      this.state.c_MapInfo.Objs != null &&
      this.state.c_MapInfo.Objs.length > 0
    ) {
      for (var i in this.state.c_MapInfo.Reses) {
        table.push(this.getmapresitem(this.state.c_MapInfo.Reses[i], i));
      }
      for (var i in this.state.c_MapInfo.Objs) {
        table2.push(this.getmapitem(this.state.c_MapInfo.Objs[i], i));
      }
    }

    return (
      <TransformWrapper
        initialScale={this.props.isDesktop ? 0.7 : 0.5}
        minScale={0.3}
        maxScale={7}
        limitToBounds
        centerOnInit
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <>
            <div className="d-flex justify-content-center flex-column map-buttons-container ml-2">
              <button
                className="d-flex justify-content-center align-items-center map-button"
                onClick={() => zoomIn()}
              >
                <Add htmlColor="#8cc63f" />
              </button>
              <button
                className=" d-flex justify-content-center align-items-center map-button"
                onClick={() => resetTransform()}
              >
                <RestartAlt htmlColor="#8cc63f" />
              </button>
              <button
                className="d-flex justify-content-center align-items-center map-button"
                onClick={() => zoomOut()}
              >
                <Remove htmlColor="#8cc63f" />
              </button>
            </div>
            <TransformComponent wrapperClass="transform-component-container-map">
              <div>
                <div id="mapimgbd" className="map-image-container">
                  {this.state.sh_Upbtn == 1 ? (
                    <img
                      onClick={this.handleUpClick}
                      className="upiconmap"
                      src={"/img/CampIcon/mapup.png"}
                    />
                  ) : (
                    <div></div>
                  )}
                  <img
                    id="mapimg"
                    className="mapImage"
                    ref={this.imgElement}
                    onLoad={() =>
                      this.setBkImgHeight(this.imgElement.current.naturalHeight)
                    }
                    src={this.state.MapUrl}
                  />
                </div>
                <div style={{ position: "absolute" }}>{table}</div>
                <div>{table2}</div>
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    );
  }

  setBkImgHeight(height) {
    this.setState({ MapHeight: height });
  }

  Is_CSSeled(oid) {
    for (var i in this.props.C_MulSlots) {
      if (this.props.C_MulSlots[i].OId == oid) return true;
    }
    return false;
  }

  Is_CSSeled2(oid) {
    var count = 0;

    var start = new Date(this.state.StartDate);
    var cday = start;
    var end = new Date(this.state.EndDate);
    var days = Math.round((end - start) / (1000 * 60 * 60 * 24));

    for (var j = 0; j < days; j++) {
      var sid = this.gsid_Campday(oid, cday);
      if (this.Is_CSSeled3(oid, sid)) {
        count++;
      }
      cday = addDays(cday, 1);
    }
    if (count == 0) {
      return 0;
    } else {
      if (count == days) return 1;
      else return 2;
    }
  }

  Is_CSSeled3(oid, sid) {
    var seled = this.props.C_MulSlots;
    for (var i in seled) {
      if (seled[i].OId == oid && seled[i].Event.OSCId == sid) {
        return true;
      }
    }
    return false;
  }

  CS_AvailCheck(oid) {
    for (var i in this.state.c_CR_List) {
      if (oid == this.state.c_CR_List[i].VId) {
        return true;
      }
    }
    return false;
  }

  ck_CSFilter(camp) {
    if (camp == null) return false;
    var len = this.props.Filter.Length;
    var pow = this.props.Filter.Power;
    var wat = this.props.Filter.Water;
    var sew = this.props.Filter.Sewer;
    var tab = this.props.Filter.Table;
    var fir = this.props.Filter.Fire;
    if (camp.Len < len) {
      return false;
    } else if (camp.Pow < pow) {
      return false;
    } else if (camp.Wat < wat) {
      return false;
    } else if (camp.Sew < sew) {
      return false;
    } else if (camp.Tab < tab) {
      return false;
    } else if (camp.Fir < fir) {
      return false;
    }
    return true;
  }

  F_AvailCamp(oid) {
    for (var i in this.state.c_CR_CampList) {
      if (oid == this.state.c_CR_CampList[i].VId) {
        return this.state.c_CR_CampList[i];
      }
    }
    return null;
  }

  ck_CSFilter2(oid) {
    return checkCampsiteFilter(this.F_AvailCamp(oid), this.props.Filter);
  }

  getmapitem(itemobj, i) {
    var divx = -this.state.MapHeight;
    var imgy = 0;
    var sub = getNodeFromTree(this.props.smalltree, itemobj.OId);
    if (sub == null) return;
    var imgic = "";
    var itclass = "";
    var wid = 0;
    if (sub.OType == Constant.TP_FOLDER) {
      imgic = "icon_campsite_folder";
      itclass = "itna2";
    } else {
      itclass = "itna";
      if (!this.ck_CSFilter2(itemobj.OId)) return;
      var ccrt = this.Is_CSSeled2(itemobj.OId);
      if (!ccrt) {
        if (this.CS_AvailCheck(itemobj.OId)) {
          imgic = "icon_campsite_available";
        } else {
          imgic = "icon_campsite_notAvailable";
        }
      } else {
        if (ccrt == 1) imgic = "icon_campsite_selected";
        else if (ccrt == 2) imgic = "icon_campsite_halfselected";
      }
    }
    wid = parseInt(wid) + parseInt(itemobj.Width);
    var xtop = itemobj.OffX + divx - i * 50 - 10;
    var xleft = itemobj.OffY + imgy;
    return (
      <div
        onClick={() => {
          this.handleIconClick(sub);
        }}
        style={{
          top: xtop,
          left: xleft,
          position: "relative",
          width: itemobj.Width + "px",
        }}
      >
        <div>
          <img
            className="csimg"
            src={"/img/CampIcon/map/" + imgic + ".png"}
            style={{
              width: itemobj.Width + "px",
              height: itemobj.Height + "px",
            }}
          />
        </div>
        <div
          className={itclass}
          style={{ position: "absolute" }}
        >
          {sub.Name}
        </div>
      </div>
    );
  }

  getmapresitem(itemobj, i) {
    var divx = -this.state.MapHeight;
    var imgy = 0;
    var sub = getNodeFromTree(this.props.smalltree, itemobj.OId);
    if (sub == null) return;
    var imgic = "";
    var itclass = "";
    var wid = 0;
    itclass = "itna";
    wid = parseInt(wid) + parseInt(itemobj.Width);
    var xtop = itemobj.OffX + divx - i * 50 + 20;
    var xleft = itemobj.OffY + imgy;
    return (
      <div style={{ top: xtop, left: xleft, position: "relative" }}>
        <div>
          <img
            className="csimg"
            src={itemobj.URL}
            style={{
              width: itemobj.Width + "px",
              height: itemobj.Height + "px",
            }}
          />
        </div>
      </div>
    );
  }

  handleUpClick() {
    var uloc = window.location.toString();
    var isnode = uloc.indexOf("/campsite#");
    var oid = uloc.substr(isnode + 10, 36);

    var node = getNodeFromTree(this.props.smalltree, this.state.c_MapObjId);
    this.setState({ c_MapObjId: node.PId });
    this.props.onUpClick(node.PId);
    this.get_MapIts(node.PId);

    if (node.PId == oid) {
      this.setState({ sh_Upbtn: 0 });
    }
  }

  handleIconClick(item) {
    if (item.OType == Constant.TP_FOLDER) {
      this.setState({ c_MapObjId: item.OId, sh_Upbtn: 1 });
      this.props.onUpClick(item.OId);
      this.get_MapIts(item.OId);
    } else {
      this.getcampinfo(item.OId);
      this.setState({
        currentItem: {
          itemName: item.Name,
          itemOid: item.OId,
        },
      });
      this.toggle();
    }
  }

  handleSelectItem(OId) {
    var ccrt = this.Is_CSSeled2(OId);
    if (this.CS_AvailCheck(OId)) {
      if (!ccrt) {
        this.props.onStartReserve(OId, Constant.REG_VENUE, 0);
      } else {
        if (ccrt == 2) this.props.onStartReserve(OId, Constant.REG_VENUE, 0);
        else if (ccrt == 1) this.props.onSeledUpdate(null, null, OId, 0, 0);
      }
      this.toggle();
    } else {
      this.setState({ notAvailable: true });
      if (ccrt == 2) this.props.onSeledUpdate(null, null, OId, 0, 0);
    }
  }

  async get_MapIts(oid) {
    this.setState({ loading: true });
    const response = await getMapItems(this.orgid, oid);
    if (response.Id == 0) {
      var cmsinfo = JSON.parse(response.Data);
      this.setState({ loading: false, c_MapInfo: cmsinfo });
      this.rf_ObjMap_v();
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div>
            {this.props.sh_Infoicon2 == 1 ? (
              <div>
                Tips: The availability info and your selection on map view only
                apply to the date(s) of your search criteria.
              </div>
            ) : (
              <div></div>
            )}
            <div>{this.getmapcontent()}</div>
            <Modal isOpen={this.state.modal}>
              <ModalHeader>
                <h3>{this.state.currentItem?.itemName}</h3>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggle}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <div>
                  <div className="d-flex justify-content-center bg-danger rounded mb-3">
                    {this.state.notAvailable && (
                      <span className="text-white">Not available</span>
                    )}
                  </div>
                  <div>
                    <span className="description-title">Name:</span>{" "}
                    {this.state.campInfo?.Name}
                  </div>
                  <div>
                    <span className="description-title">Description:</span>{" "}
                    {this.state.campInfo?.Desc == ""
                      ? "N/A"
                      : this.state.campInfo?.Desc}
                  </div>
                  <div>
                    <span className="description-title">Message:</span>{" "}
                    {this.state.campInfo?.Welc == ""
                      ? "N/A"
                      : this.state.CampInfo?.Welc &&
                        htmlToText(this.state.CampInfo?.Welc)}
                  </div>
                  <div>
                    <span className="description-title">Power:</span>{" "}
                    {this.state.campInfo?.Pow == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Table:</span>{" "}
                    {this.state.campInfo?.Tab == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Sewer:</span>{" "}
                    {this.state.campInfo?.Sew == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Fire pit:</span>{" "}
                    {this.state.campInfo?.Fir == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Water:</span>{" "}
                    {this.state.campInfo?.Wat == 1 ? "Yes" : "No"}
                  </div>
                  <div>
                    <span className="description-title">Length:</span>{" "}
                    {this.state.campInfo?.Len}
                  </div>
                </div>
                {this.g_Photos()}
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn filled-button-campsite"
                  onClick={() =>
                    this.handleSelectItem(this.state.currentItem?.itemOid)
                  }
                >
                  {this.Is_CSSeled(this.state.currentItem?.itemOid)
                    ? "Unselect"
                    : "Select"}
                </button>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default MapView;
