import React from "react";
import Spinner from "../../common/components/Spinner";
import MyStuffList from "./mystuff_list";
import MyCalendar from "./mycalendar";
import MySchedule from "./myschedule";
import MyStuffItem from "./mystuff_item";
import { ssm_getcookie } from "../../common/utils/ssm_util";
import MyStuffPassesItem from "./mystuff_passes_item";
import { getMyFamilyStuff } from "../../services/activities/getMyFamillyStuff";

class MyHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mystuff: {},
      mynode: {},
      fces: [],
      loading: false,
      tabPos: 1,
      isDetail: 0,
      family: this.props.family,
      mypid: 0,
    };

    this.handleMyStuffClick = this.handleMyStuffClick.bind(this);
    this.handleMyCalendarClick = this.handleMyCalendarClick.bind(this);
    this.handleMyScheduleClick = this.handleMyScheduleClick.bind(this);
    this.handleMyStuffItemClick = this.handleMyStuffItemClick.bind(this);
    this.handleMyStuffPassesItemClick =
      this.handleMyStuffPassesItemClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
  }

  orgid = 0;

  componentWillReceiveProps(props) {
    this.setState({ isDetail: 0 });
  }

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");
  }

  componentDidMount() {
    this.getMyFamilyStuff();
  }

  handleBackClick() {
    this.setState({ isDetail: 0, tabPos: 1 });
  }
  handleMyStuffClick() {
    this.setState({ tabPos: 1 });
  }
  handleMyCalendarClick() {
    this.setState({ tabPos: 2 });
  }
  handleMyScheduleClick() {
    this.setState({ tabPos: 3 });
  }
  handleMyStuffItemClick(item, pid) {
    this.setState({ isDetail: 1, mynode: item, mypid: pid });
  }

  handleMyStuffPassesItemClick(item, pid) {
    this.setState({ isDetail: 2, mynode: item, mypid: pid });
  }

  async getMyFamilyStuff() {
    this.setState({ loading: true });

    const response = await getMyFamilyStuff(this.orgid, this.state.family);

    if (response.Id == 0) {
      let All = JSON.parse(response.Data);
      let rt1 = JSON.parse(All.Myfamilystuffinfo);
      let rt2 = JSON.parse(All.Slots);

      this.setState({
        loading: false,
        mystuff: JSON.parse(rt1.Data),
        fces: JSON.parse(rt2.Data),
      });
    } else if (response.Id == 5) {
      let err = new Object();
      err.Id = response.Id;
      err.Msg = response.Description;
      this.props.onErr(err);
    }
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <div className="d-flex align-items-center loading-screen">
            <Spinner />
          </div>
        ) : (
          <div
            className={`content-area ${this.props.isDesktop && "container"}`}
          >
            <div id="home-session-tabs">
              <div className="container-fluid">
                {this.state.isDetail == 1 && (
                  <MyStuffItem
                    node={this.state.mynode}
                    family={this.state.family}
                    orginfo={this.props.orginfo}
                    mypid={this.state.mypid}
                    onBackClick={this.handleBackClick}
                    isDesktop={this.props.isDesktop}
                  />
                )}
                {this.state.isDetail == 2 && (
                  <MyStuffPassesItem
                    node={this.state.mynode}
                    mypid={this.state.mypid}
                    family={this.state.family}
                    onBackClick={this.handleBackClick}
                    isDesktop={this.props.isDesktop}
                  />
                )}
                {this.state.isDetail == 0 && (
                  <div className="row">
                    <div
                      className={`col-md-12 ${
                        !this.props.isDesktop && "header-top"
                      }`}
                    >
                      <ul
                        className="nav nav-pills nav-justified"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link active rounded-circle mr-2"
                            onClick={this.handleMyStuffClick}
                            id="info-tab"
                            data-toggle="tab"
                            href="#activity-info-tab"
                            role="tab"
                            aria-controls="info"
                            aria-selected="true"
                          >
                            Activities
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link rounded-circle mr-2"
                            onClick={this.handleMyScheduleClick}
                            id="participant-tab"
                            data-toggle="tab"
                            href="#schedule-info-tab"
                            role="tab"
                            aria-controls="participant"
                            aria-selected="false"
                          >
                            Schedule
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link rounded-circle"
                            onClick={this.handleMyCalendarClick}
                            id="schedule-tab"
                            data-toggle="tab"
                            href="#calendar-info-tab"
                            role="tab"
                            aria-controls="schedule"
                            aria-selected="false"
                          >
                            Calendar
                          </a>
                        </li>
                      </ul>
                      <div className="mr-2 ml-2" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="activity-info-tab"
                          role="tabpanel"
                          aria-labelledby="info-tab"
                        >
                          {this.state.tabPos === 1 ? (
                            <MyStuffList
                              mystuff={this.state.mystuff}
                              family={this.state.family}
                              onMyStuffItemClick={this.handleMyStuffItemClick}
                              onMyStuffPassesItemClick={
                                this.handleMyStuffPassesItemClick
                              }
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div
                          id="calendar-info-tab"
                          role="tabpanel"
                          aria-labelledby="schedule-tab"
                        >
                          {this.state.tabPos === 2 ? (
                            <MyCalendar
                              family={this.state.family}
                              fces={this.state.fces}
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="schedule-info-tab"
                          role="tabpanel"
                          aria-labelledby="schedule-tab"
                        >
                          {this.state.tabPos === 3 ? (
                            <MySchedule
                              family={this.state.family}
                              fces={this.state.fces}
                            />
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MyHome;
