import React from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { getMyPerson } from "../../../common/utils/family";

class MyStuffList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mystuff: this.props.mystuff, family: this.props.family };

    this.handleMyStuffItemClick = this.handleMyStuffItemClick.bind(this);
  }

  componentDidMount() {
    for (let i = 0; i < this.state.mystuff.length; i++) {
      this.state.mystuff[i].Groups.sort(function (a, b) {
        if (a.Act == null) return 1;
        if (b.Act == null) return -1;
        return a.Act.AType - b.Act.AType;
      });
    }

    this.setState({ mystuff: this.state.mystuff });
  }

  handleMyStuffItemClick(item, pid) {
    this.props.onMyStuffItemClick(item, pid);
  }

  onMyStuffPassesItemClick(item, pid) {
    this.props.onMyStuffPassesItemClick(item, pid);
  }

  getMyStuffContent(mystuff) {
    const items = [];
    for (let i = 0; i < mystuff.length; i++) {
      items.push(this.getMyStuff4Person(mystuff[i]));
    }
    let uniqueItems = [...new Set(items)];
    return <div>{uniqueItems}</div>;
  }

  getIcon(item) {
    let img = "";
    if (item != null) {
      if (item.AType == 1) img = "/img/folder.png";
      else if (item.AType == 2) img = "/img/sport.png";
      else if (item.AType == 6) img = "/img/event.png";
      else if (item.AType == 4) img = "/img/class.png";
      else if (item.AType == 7) img = "/img/venue.png";
      else if (item.AType == 14) img = "/img/item.png";
    }
    return <img className="nodeicn" src={img} />;
  }

  getPersonAct(groupact, pid) {
    return (
      <div>
        <a
          onClick={() => {
            this.handleMyStuffItemClick(groupact, pid);
          }}
        >
          <div className="d-flex flex-row" id="activity-row">
            <div className="col-10 d-flex flex-row">
              <div>{this.getIcon(groupact.Act)}</div>
              <span id="activity">{groupact.Act.Name}</span>
            </div>
            <div className="col-2 ta-right">
              <img width="24" src="img/arrow-right-icon.svg" alt="see more" />
            </div>
          </div>
        </a>
      </div>
    );
  }

  getPersonPasses(passes, pid) {
    let img = "/img/item.png"
    return (
      <div>
        <a
          onClick={() => {
            this.onMyStuffPassesItemClick(passes, pid);
          }}
        >
          <div className="d-flex flex-row" id="activity-row">
            <div className="col-10 d-flex flex-row">
              <img className="nodeicn" src={img} width="24" height="24"/>
              <span id="activity">{passes.IName}</span>
            </div>
            <div className="col-2 ta-right">
              <img width="24" src="img/arrow-right-icon.svg" alt="see more" />
            </div>
          </div>
        </a>
      </div>
    );
  }

  getMyStuff4Person(personstuff) {
    const items = [];
    for (let i = 0; i < personstuff.Groups.length; i++) {
      if (personstuff.Groups[i].Act != null) {
        items.push(this.getPersonAct(personstuff.Groups[i], personstuff.PId));
      }
    }

    for (let i = 0; i < personstuff.Passes.length; i++) {
      items.push(this.getPersonPasses(personstuff.Passes[i], personstuff.PId));
    }

    let p = getMyPerson(personstuff.PId, this.state.family);
    let name = p.FName + " " + p.LName;

    return (
      <div
        className="tab-pane fade show active"
        id="my-activities"
        role="tabpanel"
        aria-labelledby="activities-tab"
      >
        <div className="mb-1">
          <a
            className="card d-flex flex-row mr-1 ml-1 pt-2"
            data-toggle="collapse"
            href={`#${personstuff.PId}`}
            role="button"
            aria-expanded="false"
            aria-controls={`${personstuff.PId}`}
          >
            <div className="col-10">
              <span>{name}</span>
            </div>
            <div className="col-2">
              <KeyboardArrowDown />
            </div>
          </a>
          <div
            className="card mt-2 mr-1 ml-1 mb-1 collapse"
            id={`${personstuff.PId}`}
          >
            {items.length > 0 ? (
              items
            ) : (
              <div className="d-flex justify-content-center">
                <span style={{ color: "red" }}>No activities</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.getMyStuffContent(this.state.mystuff)}</div>;
  }
}

export default MyStuffList;