import React from "react";
import { Close } from "@mui/icons-material";
import "./styles.css";

const MemberCard = ({type, name, onRemove, show = false}) => {
  return (
    <div className="card member-card d-flex justify-content-between align-items-center flex-row px-3 py-2">
      <div>
        <span className="member-type-text pr-1">{type}:</span>
        <span>{name}</span>
      </div>
      {show && <Close onClick={onRemove} htmlColor="#777777"/>}
    </div>
  );
};

export default MemberCard;
