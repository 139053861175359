import React from "react";
import Spinner from "../../../common/components/Spinner";
import BackTitle from "../../../common/components/back/BackTitle";
import Barcode from "react-barcode";
import { getMyPerson } from "../../../common/utils/family";

class MyStuffPassesItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      node: this.props.node,
      mypid: this.props.mypid,
      family: this.props.family,
      loading: false,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
  }

  handleBackClick(node) {
    this.props.onBackClick(node);
  }

  renderType() {
    let lnode = this.state.node;
    if (lnode.TransType == 2) {
      return "Season Pass";
    } else if (lnode.TransType == 3) {
      return "Punch Pass";
    } else if (lnode.TransType == 4) {
      return "Money Pass";
    } else return "";
  }

  render() {
    let lnode = this.state.node;
    let startDate = new Date(lnode.PStart)
      .toJSON()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("/");
    let endDate = new Date(lnode.PEnd)
      .toJSON()
      .slice(0, 10)
      .split("-")
      .reverse()
      .join("/");
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div
            className={`content-area ${
              !this.props.isDesktop && "header-top-80"
            }`}
          >
            <div className="row pb-1 pt-1 mb-4">
              <div className="col-12">
                <BackTitle
                  title={lnode.IName}
                  onBack={() => {
                    this.handleBackClick(this.state.node);
                  }}
                />
              </div>
            </div>
            <div className="card p-3 mb-3">
              <span className="mb-1">
                <span className="pass-text">Pass Code:</span> {lnode.PCode}
              </span>
              <span className="mb-1">
                <span className="pass-text">Holder Name:</span>{" "}
                {getMyPerson(lnode.PId, this.state.family).FName +
                  " " +
                  getMyPerson(lnode.PId, this.state.family).LName}
              </span>
              <span className="mb-1">
                <span className="pass-text">Pass Name: </span>
                {lnode.IName}
              </span>
              <span className="mb-1">
                <span className="pass-text">Pass Type:</span>{" "}
                {this.renderType()}
              </span>
              <span className="mb-1">
                <span className="pass-text">Start Date:</span> {startDate}
              </span>
              <span className="mb-1">
                <span className="pass-text">Expiration Date:</span> {endDate}
              </span>
              {lnode.TransType == 3 && (
                <span className="mb-1">
                  <span className="pass-text">Punch Left:</span>{" "}
                  {`${lnode.LPunCnt} (Total: ${lnode.PunCnt})`}
                </span>
              )}
              {lnode.TransType == 4 && (
                <span className="mb-1">
                  <span className="pass-text">Money Left:</span>{" "}
                  {`${lnode.LMoney} (Total: ${lnode.Money})`}
                </span>
              )}
            </div>
            <div className="card p-3 mb-5">
              <div className="d-flex justify-content-center">
                <Barcode value={lnode.PCode} height={50} width={3} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default MyStuffPassesItem;