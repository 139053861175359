import React from "react";
import { ssm_getcookie, ssm_setcookie } from "../../../common/utils/ssm_util";
import queryString from "query-string";
import { checkCode } from "../../../services/register/checkCode";

class Prerequisite extends React.Component {
  constructor(props) {
    super(props);

    this.state = { node: this.props.node, code: "", errormsg: "" };

    this.handleOkClick = this.handleOkClick.bind(this);
  }

  orgid = 0;

  componentWillMount() {
    var parsed = queryString.parse(window.location.search);
    if (parsed.orgid != undefined) {
      ssm_setcookie("ssm_orgid", parsed.orgid, 1);
      this.orgid = parsed.orgid;
    } else {
      this.orgid = ssm_getcookie("ssm_orgid");
    }
  }


  async handleOkClick() {
    this.state.node.NdPreq = this.state.code;
    this.setState({ node: this.state.node });
    const response = await checkCode(this.state.code, this.orgid, this.state.node.OId);
    if (response.Id == 0) {
      this.props.onOkClick(this.state.node);
    } else {
      this.setState({ errormsg: response.Description });
    }
  }

  render() {
    return (
      <div className="container d-flex justify-content-center align-items-center pt-4 flex-column px-4">
        <span className="text-md-left font-weight-bold text-center">
          The item requires a Prerequisite Code. Please input below:
        </span>
        <input
          className="form-control login-inputs w-full"
          type="text"
          name="precode"
          placeholder="Prerequisite Code"
          value={this.state.code}
          onChange={(e) => this.setState({ code: e.target.value })}
          maxLength={50}
        />
        <button onClick={this.handleOkClick} className="filled-button mt-3">
          Check
        </button>
        <p style={{color: "red", textAlign: "center"}}>{this.state.errormsg !== "" && this.state.errormsg}</p>
      </div>
    );
  }
}

export default Prerequisite;
