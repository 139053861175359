import React from "react";

export const getStateOptions = () => {
  return (
    <>
      <option value="-1" selected>
        Select State
      </option>
      <option value="AK">Alaska</option>
      <option value="AL">Alabama</option>
      <option value="AR">Arkansas</option>
      <option value="AZ">Arizona</option>
      <option value="CA">California</option>
      <option value="CO">Colorado</option>
      <option value="CT">Connecticut</option>
      <option value="DC">District of Columbia</option>
      <option value="DE">Delaware</option>
      <option value="FL">Florida</option>
      <option value="GA">Georgia</option>
      <option value="HI">Hawaii</option>
      <option value="IA">Iowa</option>
      <option value="ID">Idaho</option>
      <option value="IL">Illinois</option>
      <option value="IN">Indiana</option>
      <option value="KS">Kansas</option>
      <option value="KY">Kentucky</option>
      <option value="LA">Louisiana</option>
      <option value="MA">Massachusetts</option>
      <option value="MD">Marlyand</option>
      <option value="ME">Maine</option>
      <option value="MI">Michigan</option>
      <option value="MN">Minnesota</option>
      <option value="MO">Missouri</option>
      <option value="MS">Mississippi</option>
      <option value="MT">Montana</option>
      <option value="NC">North Carolina</option>
      <option value="ND">North Dakota</option>
      <option value="NE">Nebraska</option>
      <option value="NH">New Hampshire</option>
      <option value="NJ">New Jersey</option>
      <option value="NM">New Mexico</option>
      <option value="NV">Nevada</option>
      <option value="NY">New York</option>
      <option value="OH">Ohio</option>
      <option value="OK">Oklahoma</option>
      <option value="OR">Oregon</option>
      <option value="PA">Pennsylvania</option>
      <option value="RI">Rhode Island</option>
      <option value="SC">South Carolina</option>
      <option value="SD">South Dakota</option>
      <option value="TN">Tennessee</option>
      <option value="TX">Texas</option>
      <option value="UT">Utah</option>
      <option value="VA">Virginia</option>
      <option value="VT">Vermont</option>
      <option value="WA">Washington</option>
      <option value="WI">Wisconsin</option>
      <option value="WV">West Virginia</option>
      <option value="WY">Wyoming</option>
    </>
  );
};

export const getCanadaProvinceOptions = () => {
  return (
    <>
      <option value="-1" selected>
        Select Province
      </option>
      <option value="AB">Alberta</option>
      <option value="BC">British Columbia</option>
      <option value="MB">Manitoba</option>
      <option value="NB">New Brunswick</option>
      <option value="NL">Newfoundland and Labrador</option>
      <option value="NS">Nova Scotia</option>
      <option value="NT">Northwest Territories</option>
      <option value="NU">Nunavut</option>
      <option value="ON">Ontario</option>
      <option value="PE">Prince Edward Island</option>
      <option value="QC">Quebec</option>
      <option value="SK">Saskatchewan</option>
      <option value="YT">Yukon</option>
    </>
  );
};

export const getCarrierOptions = () => {
  return (
    <>
      <option value="-1" selected="selected">
        Select Carrier*
      </option>
      <option value="5">Alltel</option>
      <option value="17">Ameritech</option>
      <option value="25">AT&amp;T Wireless</option>
      <option value="1">Bellsouth</option>
      <option value="15">Boost Mobile</option>
      <option value="9">CellularOne</option>
      <option value="8">CellularOne MMS</option>
      <option value="2">Cingular</option>
      <option value="22">Cricket</option>
      <option value="21">Edge Wireless</option>
      <option value="12">Globalstar (satellite)</option>
      <option value="3">GoPhone (Cingular)</option>
      <option value="28">Inland Cellular</option>
      <option value="13">Iridium (satellite)</option>
      <option value="16">Metro PCS</option>
      <option value="6">Nextel</option>
      <option value="10">O2</option>
      <option value="11">Orange</option>
      <option value="19">Qwest</option>
      <option value="18">Rogers Wireless</option>
      <option value="7">Sprint PCS</option>
      <option value="24">T-Mobile</option>
      <option value="23">Teleflip</option>
      <option value="14">Telus Mobility</option>
      <option value="4">US Cellular</option>
      <option value="27">Verizon</option>
      <option value="26">Virgin Mobile</option>
      <option value="29">Straight Talk</option>
      <option value="30">Consumer Cellular</option>
    </>
  );
};
