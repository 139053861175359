import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { formatDateGB } from "../../../../common/utils/date";

export default function SsRangeCalendar2({ onSEChange, oval, onSEDone }) {
  const [class1, setClass1] = useState("nav-link active");
  const [class2, setClass2] = useState("nav-link");
  const [checkin, setCheckIn] = useState("Check In");
  const [checkout, setCheckOut] = useState("Check Out");
  const [check, setCheck] = useState(1);
  const [start, setStart] = useState("");

  function onClickDay(val) {
    if (check == 1) {
      setClass1("nav-link");
      setClass2("nav-link active");
      setCheck(2);
      setCheckIn("Check In<br/>" + formatDateGB(val));
      setCheckOut("Check Out");
      setStart(val);
    } else if (check == 2) {
      setClass2("nav-link");
      setClass1("nav-link");
      setCheck(1);
      if (val < start) {
        setCheckIn("Check In<br/>" + formatDateGB(val));
        setCheckOut("Check Out<br/>" + formatDateGB(start));
      } else {
        setCheckOut("Check Out<br/>" + formatDateGB(val));
      }
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <ul className="nav nav-pills nav-justified" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className={class1}
                id="info-tab"
                role="tab"
                aria-controls="info"
                aria-selected="true"
                dangerouslySetInnerHTML={{ __html: checkin }}
              ></a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="info-tab"
                role="tab"
                aria-controls="info"
                aria-selected="true"
              >
                <img className="fromtoarrow" src="img/fromto.png" />
              </a>
            </li>
            <li className="nav-item">
              <a
                className={class2}
                id="participant-tab"
                role="tab"
                aria-controls="participant"
                aria-selected="false"
                dangerouslySetInnerHTML={{ __html: checkout }}
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <Calendar
        onChange={onSEChange}
        value={oval}
        selectRange={true}
        onClickDay={onClickDay}
      />
      <div className="form-actions-area">
        <a>
          <button
            onClick={onSEDone}
            className="filled-button-continue2"
            type="button"
          >
            Done
          </button>
        </a>
      </div>
    </div>
  );
}
