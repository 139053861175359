import React from "react";
import Constant from "../../../common/constants/define";
import Config from "../../../config";
import Spinner from "../../../common/components/Spinner";
import MapView from "./mapview";
import ListView from "./listview";
import InputMask from "react-input-mask";
import SsRangeCalendar2 from "./ssrangecalendar2";
import Checkbox from "../../../common/components/register-checkbox";
import { ssm_getcookie } from "../../../common/utils/ssm_util";
import "react-datepicker/dist/react-datepicker.css";
import BackTitle from "../../../common/components/back/BackTitle";
import { getAnnouncementDetail } from "../../../services/common/getAnnouncementMessage";
import Modal, { ModalBody, ModalHeader, ModalFooter } from "../../../common/components/modal";
import Alert from "../../../common/components/modal/alert";
import { addDays, formatDateMoment } from "../../../common/utils/date";
import { searchCampsite } from "../../../services/home/searchCapmsite";
import { formatDateEN, formatDateENGB, getCurrentDate,formatCompleteDateENGB } from "../../../common/utils/date";

class CampSite extends React.Component {
  constructor(props) {
    super(props);
    var uloc = window.location.toString();
    var isnode = uloc.indexOf("/campsite#");
    var oid = uloc.substr(isnode + 10, 36);

    this.state = {
      smallscreen: true,
      calenscreen: false,
      node: this.props.node,
      sh_Infoicon: 0,
      c_MapObjId: oid,
      sh_Infoicon2: 0,
      Filtertext: "Filter",
      sh_Continue: 1,
      campsitenode: {},
      Filter: {},
      sh_Filter: 0,
      PagePos: 0,
      loading: false,
      OSEDate: [addDays(new Date(), 7), addDays(new Date(), 8)],
      OStartDate: formatDateMoment(addDays(new Date(), 7)),
      OEndDate: formatDateMoment(addDays(new Date(), 8)),
      StartDate: formatDateMoment(addDays(new Date(), 7)),
      EndDate: formatDateMoment(addDays(new Date(), 8)),
      c_CR_List: [],
      c_CR_Listall: [],
      c_CR_CampList: [],
      cM_CR_List: [],
      cM_CR_Listall: [],
      cM_CR_CampList: [],
      c_MapInfo: {},
      C_MulSlots: [],
      smallscreen: true,
      height: 0,
      announcements: this.props.announcements,
      annmsg: "",
      modal: false,
      currentView: "map",
      showAlert: false,
      alertMessage: "",
    };

    this.myHeaderRef = React.createRef();

    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSEFocus = this.handleSEFocus.bind(this);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.handleLoopSelChange = this.handleLoopSelChange.bind(this);
    this.handleSeledUpdate = this.handleSeledUpdate.bind(this);
    this.handleStartReserve = this.handleStartReserve.bind(this);
    this.handleRegisterClick = this.handleRegisterClick.bind(this);
    this.handleContinueClick = this.handleContinueClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleListClick = this.handleListClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.handleListBackClick = this.handleListBackClick.bind(this);
    this.handleshInfoClick = this.handleshInfoClick.bind(this);
    this.handleUpClick = this.handleUpClick.bind(this);
    this.handlemaptabClick = this.handlemaptabClick.bind(this);
    this.handleOkClick = this.handleOkClick.bind(this);
    this.renderAnnouncements = this.renderAnnouncements.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  orgid = 0;

  componentWillMount() {
    this.orgid = ssm_getcookie("ssm_orgid");

    var uloc = window.location.toString();
    var isnode = uloc.indexOf("/campsite#");
    var oid = uloc.substr(isnode + 10, 36);

    var lnode = this.g_nodefromstree(oid);
    this.setState({ node: lnode });
  }

  g_nodefromstree(oid) {
    for (var i in this.props.smalltree) {
      if (this.props.smalltree[i].OId == oid) {
        return this.props.smalltree[i];
      }
    }
  }
  g_childfromstree(oid) {
    var nod = [];
    for (var i in this.props.smalltree) {
      if (this.props.smalltree[i].PId == oid) {
        nod.push(this.props.smalltree[i]);
      }
    }
    return nod;
  }

  updateDimensions() {
    if (window.innerWidth < 500) {
      if (this.state.smallscreen == false) this.setState({ smallscreen: true });
    } else {
      if (this.state.smallscreen == true) this.setState({ smallscreen: false });
    }
  }

  handleSEChange = (val) => {
    var s = "";
    var e = "";
    if (val != null) {
      s = formatDateMoment(val[0]);
      e = formatDateMoment(val[1]);
    }
    this.setState({ OSEDate: val, OStartDate: s, OEndDate: e });
  };

  handleSEDone = () => {
    this.setState({ calenscreen: false });
  };

  handleSEFocus = (event) => {
    this.setState({ calenscreen: true });
  };
  handleStartChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;
    this.setState({
      OStartDate: val,
    });
  };
  handleEndChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;
    this.setState({
      OEndDate: val,
    });
  };

  handleBackClick(node) {
    if (node.PId == "00000000-0000-0000-0000-000000000000") {
      this.props.onNavigate("/");
    } else {
      this.props.onNavigate(`/folder#${node.PId}`);
    }
  }

  handleStartReserve(id, type, scheid) {
    this.g_Camp0Slots_dm(id, type, scheid);
  }

  gsid_Campday(vid, day) {
    for (var i in this.state.cM_CR_Listall) {
      if (vid == this.state.cM_CR_Listall[i].VId) {
        var day2 = new Date(this.state.cM_CR_Listall[i].End);
        var d = day.toLocaleDateString();
        var d2 = day2.toLocaleDateString();
        if (d == d2) {
          return this.state.cM_CR_Listall[i].SId;
        }
      }
    }
    return null;
  }

  Del_CSSeled(oid) {
    var start = new Date(this.state.OStartDate);
    var cday = start;
    var end = new Date(this.state.OEndDate);
    var days = Math.round((end - start) / (1000 * 60 * 60 * 24));
    var seled = null;
    for (var j = 0; j < days; j++) {
      var sid = this.gsid_Campday(oid, cday);
      seled = this.Del_CSSeled2(oid, sid);
      cday = addDays(cday, 1);
    }
    return seled;
  }

  Del_CSSeled2(oid, sid) {
    var seled = this.state.C_MulSlots;
    for (var i in seled) {
      if (seled[i].OId == oid && seled[i].Event.OSCId == sid) {
        seled.splice(i, 1);
      }
    }
    return seled;
  }

  exist_seled(slot) {
    for (var i in this.state.C_MulSlots) {
      if (this.state.C_MulSlots[i].Event.OSCId == slot.Event.OSCId) return true;
    }
    return false;
  }

  handleSeledUpdate(slots, cs, oid, sid, flag) {
    if (flag == 1) {
      var slots2 = this.state.C_MulSlots;
      for (var i in slots) {
        if (!this.exist_seled(slots[i])) slots2.push(slots[i]);
      }
      this.setState({ C_MulSlots: slots2, campsitenode: cs });
    } else {
      var slots = null;
      if (sid != 0) slots = this.Del_CSSeled2(oid, sid);
      else slots = this.Del_CSSeled(oid);
      this.setState({ C_MulSlots: slots });
    }
  }

  handleSearchClick() {
    if (this.state.OStartDate == "" || this.state.OEndDate == "") {
      this.setState({showAlert: true, alertMessage: "Please select search criteria."})
      return;
    }
   
    var start = new Date(this.state.OStartDate);
    var end = new Date(this.state.OEndDate);
    var days = Math.round((end - start) / (1000 * 60 * 60 * 24)) + 1;
    if (days > this.props.maxCampsiteReservations) {
      this.setState({showAlert: true, alertMessage: "You can only reserve a campsite for a maximum of " + this.props.maxCampsiteReservations + " days."})
      return;
    }
    
    this.setState({
      StartDate: this.state.OStartDate,
      EndDate: this.state.OEndDate,
    });
    this.do_SearchCamp(this.state.OStartDate, this.state.OEndDate, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.height !== this.state.height) {
      const height = this.divElement.clientHeight;
      this.setState({ height });
    }

    if (prevState.annmsg !== this.state.annmsg) {
      if (this.state.annmsg !== "") {
        this.setState({ modal: true });
      }
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const height = this.divElement.clientHeight;
    this.setState({ height, currentView: "map" });
  }

  handleListBackClick(flag) {
    this.setState({ currentView: "list", sh_Continue: flag, sh_Infoicon: 0 });
  }
  handleshInfoClick() {
    if (this.state.sh_Infoicon2 == 1) this.setState({ sh_Infoicon2: 0 });
    else this.setState({ sh_Infoicon2: 1 });
  }
  handleUpClick(oid) {
    this.setState({ c_MapObjId: oid });
  }
  handlemaptabClick() {
    this.setState({ currentView: "map", sh_Infoicon: 1 });
  }
  handleOkClick(flag) {
    this.setState({ PagePos: 2 });
  }

  handlePrevClick(cday) {
    var start = addDays(cday, -7);
    var end = addDays(start, 7);

    this.setState({
      StartDate: formatDateMoment(start),
      EndDate: formatDateMoment(end),
    });
    this.do_SearchCamp(formatDateMoment(start), formatDateMoment(end), 1);
  }

  handleNextClick(cday) {
    var start = addDays(cday, 7);
    var end = addDays(start, 7);

    this.setState({
      StartDate: formatDateMoment(start),
      EndDate: formatDateMoment(end),
    });
    this.do_SearchCamp(formatDateMoment(start), formatDateMoment(end), 1);
  }
  handleListClick() {
    this.setState({ PagePos: 2 });
  }
  handleMapClick() {
    this.setState({ PagePos: 1 });
  }
  handleContinueClick() {
    if (this.state.C_MulSlots.length == 0) {
      this.setState({
        PagePos: 4,
        ErrMsg: "Please select a site to continue.",
      });
    } else {
      this.setState({ PagePos: 3 });
    }
  }
  handleCancelClick() {
    this.setState({ PagePos: 1 });
  }
  handleFilterClick() {
    if (this.state.sh_Filter == 0)
      this.setState({ sh_Filter: 1, Filter: {}, Filtertext: "Clear" });
    else this.setState({ sh_Filter: 0, Filter: {}, Filtertext: "Filter" });
  }

  handleDeleteClick(fce) {
    var slots = this.Del_CSSeled2(fce.OId, fce.Event.OSCId);
    this.setState({ C_MulSlots: slots });
  }

  handleRegisterClick() {
    if (this.state.C_MulSlots.length > 0) {
      this.fil_VReg();
      this.props.onRegisterClick(
        this.state.campsitenode,
        this.state.C_MulSlots
      );
    } else {
      this.setState({showAlert: true, alertMessage: "Please select campsite first."})
    }
  }

  fil_VReg() {
    for (var i in this.state.C_MulSlots) {
      var Schedules2 = [];
      this.pre_Fce_m4(Schedules2, "Reservation", this.state.C_MulSlots[i]);
      var rq = new Object();
      rq.Type = 1;
      rq.Flag = 2; //co_SType;
      rq.Sches = Schedules2;

      var jsonstr = JSON.stringify(rq);
      this.state.C_MulSlots[i].VR_Rq = new Buffer(jsonstr).toString("base64");

      this.state.C_MulSlots[i].HdFces.length = 0;
    }
  }

  pre_Fce_m4(Schedules, desc, vrinfo) {
    var fce = new Object();
    fce.Start = formatCompleteDateENGB(vrinfo.Event.start)+ " " + formatDateEN(vrinfo.Event.start);
    fce.End = formatCompleteDateENGB(vrinfo.Event.end)+ " " + formatDateEN(vrinfo.Event.end);

    fce.id = vrinfo.Event.id;
    fce.SCId = vrinfo.Event.SCId; //0;//RootId;
    fce.Buf = 0;
    fce.Type = vrinfo.Type;
    fce.VId = this.state.node.OId;
    fce.OrgId = this.orgid;
    fce.SubsId = 0; //c_User==null?0:c_User.PersonInfo.Person.PId;
    fce.Name = ""; //c_User==null?"":c_User.PersonInfo.Person.Emai;
    fce.Desc = desc;
    fce.SubsTp = Constant.TP_PLAYER;
    Schedules.push(fce);
  }

  f_DR_m(drid, Plan) {
    if (Plan.Data_Rngs.length > 0) {
      for (var m in Plan.Data_Rngs) {
        if (Plan.Data_Rngs[m].Id == drid) {
          return Plan.Data_Rngs[m];
        }
      }
    }
    return null;
  }

  g_VRInfo(event, plan, blkid) {
    var l_VRInfo = new Object();
    if (event.Type == Constant.PLAN_SLOT) {
      var c_DR = this.f_DR_m(blkid, plan);
      l_VRInfo.Dr = c_DR;
      l_VRInfo.Event = event;
      l_VRInfo.Type = Constant.PLAN_SLOT;
    } else if (event.Type == Constant.RAND_SLOT) {
      l_VRInfo.Type = Constant.RAND_SLOT;
      l_VRInfo.Event = event;
      l_VRInfo.ExHours = 0;
    }
    return l_VRInfo;
  }

  rs_CampSite(cmfces, vid, plan, campsite) {
    var fces = [];
    var node = this.g_nodefromstree(vid);

    for (var i = 0; i < cmfces.length; i++) {
      var eve = new Object();
      eve.id = i;
      eve.SCId = cmfces[i].SCId;
      eve.OSCId = cmfces[i].OSCId;
      eve.blkid = cmfces[i].BLKId;
      eve.start = new Date(cmfces[i].Start);
      eve.end = new Date(cmfces[i].End);
      eve.Type = Constant.PLAN_SLOT;
      eve.c2 = "v_ply_av";
      eve.CTime = new Date();

      var vr = this.g_VRInfo(eve, plan, eve.blkid);
      vr.HdFces = [];
      vr.AvExtra = 0;
      vr.ExHours = 0;
      vr.ExHoursFee = 0;

      vr.NdName = node.Name; //g_LNName(node);
      vr.OId = vid; //cs_OId;
      vr.Cked = 1;

      fces.push(vr);
    }
    this.handleSeledUpdate(fces, campsite, null, null, 1);
  }

  get_Slot(fce) {
    const item = fce.Event.start;
    let str = formatDateMoment(item);
    return (
      <div id="cart-item" className="row csvseled">
        <div className="col-10">
          <div className="cart-item-name">{fce.NdName + " : " + str}</div>
        </div>
        <div className="col-2 ta-right cart-item-delete">
          <img
            onClick={() => {
              this.handleDeleteClick(fce);
            }}
            width="24"
            src="img/close-icon.svg"
            alt="delete item"
          />
        </div>
      </div>
    );
  }

  getSelectedSlots() {
    const fces = [];
    for (var i in this.state.C_MulSlots) {
      fces.push(this.get_Slot(this.state.C_MulSlots[i]));
    }
    return <div>{fces}</div>;
  }

  async g_Camp0Slots_dm(id, type, scheid) {
    var req = new Object();
    req.Guid = id;
    req.Type = type;

    var req2 = new Object();
    req2.Guid = id;
    if (scheid == 0) req2.Flag = 0;
    else req2.Flag = 1;
    req2.SId = scheid;

    var req3 = new Object();
    req3.OId = id; //c_OId
    req3.OrgId = this.orgid;
    if (scheid == 0) {
      req3.Start = this.state.OStartDate;
      req3.End = this.state.OEndDate;
    } else {
      req3.Start = this.state.StartDate;
      req3.End = this.state.EndDate;
    }

    var c_CR_Para = req3;
    req2.Para = c_CR_Para;

    var Allinfo = new Object();
    Allinfo.Venue = new Buffer(JSON.stringify(req)).toString("base64");
    Allinfo.Slots = new Buffer(JSON.stringify(req2)).toString("base64");

    var jsonstr = new Buffer(JSON.stringify(Allinfo)).toString("base64");
    var sid = ssm_getcookie("ssm_sid"); //'00000000-0000-0000-0000-000000000000';
    const para = { jsonstr, sid };

    const res = await fetch(
      Config.ssm_service + "/ssm_api_node/checkcampsite",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(para),
      }
    );

    var rt = await res.json();
    var All = JSON.parse(rt.Data);

    var Venue = JSON.parse(All.Venue);
    var ce_Node = JSON.parse(Venue.Data);

    var C_Plan = JSON.parse(ce_Node.Plans[0].SPlan);

    var Slots = JSON.parse(All.Slots);
    if (Slots.Data != "") {
      var C_CampFces = JSON.parse(Slots.Data);
      ce_Node.OType = 7;
      this.rs_CampSite(C_CampFces, id, C_Plan, ce_Node);
    } else {
      this.setState({
        PagePos: 4,
        ErrMsg:
          "Some of the selected sites are already in your shopping cart.",
      });
    }
  }

  handleFilterChange = (event) => {
    //const { name } = event.target;
    let name = event.target.id;

    this.setState((prevState) => ({
      Filter: {
        // object that we want to update
        ...prevState.Filter, // keep all other key-value pairs
        //[name]: value       // update the value of specific key
        [name]: !prevState.Filter[name],
      },
    }));
  };
  handleSelChange = (event) => {
    let val = event.target.value;
    let name = event.target.name;
    this.setState((prevState) => ({
      Filter: {
        // object that we want to update
        ...prevState.Filter, // keep all other key-value pairs
        [name]: val, // update the value of specific key
      },
    }));
  };

  bd_CamOpt() {
    const opts = [];
    var uloc = window.location.toString();
    var isnode = uloc.indexOf("/campsite#");
    var oid = uloc.substr(isnode + 10, 36);

    var childs = this.g_childfromstree(oid);
    opts.push(
      <option value={oid} selected>
        All
      </option>
    );
    for (var i = 0; i < childs.length; i++) {
      //var node=this.g_nodefromstree(childs[i].OId);
      opts.push(
        <option value={childs[i].OId} selected>
          {childs[i].Name}
        </option>
      );
    }

    return (
      <div className="row">
        <div className="d-flex justify-content-center w-100">
          <select
            name="Length"
            className="form-control select-inputs-campsite"
            value={this.state.c_MapObjId}
            onChange={this.handleLoopSelChange}
          >
            {opts}
          </select>
        </div>
      </div>
    );
  }

  handleLoopSelChange = (event) => {
    let val = event.target.value;
    this.setState({ c_MapObjId: val });
    // this.handleUpClick(val);
  };

  async sc_CamList_m(start, end, len, pow, wat, sew, tab, fir, flag) {
    this.setState({ loading: true });
    var req = new Object();
    req.OId = this.state.node.OId; //c_OId;
    req.OrgId = this.orgid;
    req.Start = start;
    req.End = end;
    req.Len = len == "" ? 0 : len;
    req.Pow = pow;
    req.Wat = wat;
    req.Sew = sew;
    req.Tab = tab;
    req.Fir = fir;
    req.Flag = 1;

    const response = await searchCampsite(req);
    var cmsinfo = response;

    if (cmsinfo?.OrgCamps.length > 0) {
      if (flag == 1)
        this.setState({
          loading: false,
          PagePos: 2,
          c_CR_List: cmsinfo.Camps,
          c_CR_Listall: cmsinfo.Campsall,
          c_CR_CampList: cmsinfo.OrgCamps,
        });
      else if (flag == 0)
        this.setState({
          loading: false,
          PagePos: 2,
          c_CR_List: cmsinfo.Camps,
          c_CR_Listall: cmsinfo.Campsall,
          c_CR_CampList: cmsinfo.OrgCamps,
          cM_CR_List: cmsinfo.Camps,
          cM_CR_Listall: cmsinfo.Campsall,
          cM_CR_CampList: cmsinfo.OrgCamps,
          c_MapInfo: cmsinfo.OMR,
        });
    } else {
      this.setState({
        loading: false,
        PagePos: 0,
        ErrMsg: "There are no campsites available for your search criteria",
      });
    }
  }

  do_SearchCamp(start, end, flag) {
    this.sc_CamList_m(start, end, "", false, false, false, false, false, flag);
  }

  handleAnnouncementClick(id) {
    getAnnouncementDetail(id).then((response) => {
      this.setState({ annmsg: response });
    });
  }

  toggle() {
    this.setState({ modal: !this.state.modal, annmsg: "" });
  }

  handleAlertClose() {
    this.setState({ showAlert: false, alertMessage: "" });
  }

  getAnnouncementItem(obj) {
    return (
      <div
        className="ann-msg"
        onClick={() => this.handleAnnouncementClick(obj.AId)}
      >
        <a className="card p-2 d-flex flex-row align-items-center mb-2 anou2">
          <img className="annimg" src="/img/mess.gif" />
          <div className="announcement-text">{obj.Name}</div>
        </a>
      </div>
    );
  }

  f_fstPos(list) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].DFlag == 0) return i;
    }
    return 0;
  }

  f_mxPos(list) {
    var num = this.f_fstPos(list);
    var maxpos = list[num].Pos;
    var maxpos2 = num;
    for (var i = 0; i < list.length; i++) {
      if (list[i].Pos > maxpos && list[i].DFlag == 0 && i != maxpos2) {
        maxpos = list[i].Pos;
        maxpos2 = i;
      }
    }

    list[maxpos2].DFlag = 1;
    return list[maxpos2];
  }

  renderAnnouncements(announcements, message) {
    if (announcements?.length == 0) return "";
    const msg = [];
    msg.push(<h8 className="ann-label">ANNOUNCEMENTS</h8>);

    for (var i = 0; i < announcements?.length; i++) {
      announcements[i].DFlag = 0;
    }

    for (var m in announcements) {
      var obj = this.f_mxPos(announcements);
      msg.push(this.getAnnouncementItem(obj));
    }

    return <div className="w-100 ansbox">{msg}</div>;
  }

  render() {
    var seled = 0;
    if (this.state.C_MulSlots != null && this.state.C_MulSlots.length > 0)
      seled = this.state.C_MulSlots.length;
    return (
      <div>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <div className="container">
            <div
              className={`row section-title-container ${
                !this.props.isDesktop && "header-top-80"
              }`}
            >
              <div
                ref={(divElement) => {
                  this.divElement = divElement;
                }}
                className="col-12 header-back-fixed"
              >
                <BackTitle
                  title={this.state.node.Name}
                  onBack={() => {
                    this.handleBackClick(this.state.node);
                  }}
                />
              </div>
            </div>
            {this.state.calenscreen ? (
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <SsRangeCalendar2
                    onSEChange={this.handleSEChange}
                    oval={this.state.OSEDate}
                    onSEDone={this.handleSEDone}
                  />
                </div>
                <div className="col-1"></div>
              </div>
            ) : (
              <div>
                <div className="d-flex justify-content-center mb-4 ml-2">
                  {this.renderAnnouncements(
                    this.state.announcements,
                    this.state.annmsg
                  )}
                </div>
                <div>
                  <div className="tacenter ssdpbox">
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-row">
                          <div
                            className="col ssinfo-label"
                            style={{ paddingLeft: 50 }}
                          >
                            Check In
                          </div>
                          <div className="col ssinfo-label">Check Out</div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div
                            className={
                              this.state.smallscreen == false
                                ? "calendar-date-inputs-large-web"
                                : "calendar-date-inputs"
                            }
                          >
                            <div className="d-flex flex-row">
                              <InputMask
                                name="BDay"
                                mask="99/99/9999"
                                placeholder="MM/DD/YYYY"
                                value={this.state.OStartDate}
                                className="form-control calendar-inputs-campsite border text-left pl-3"
                                onFocus={this.handleSEFocus}
                              />
                              <InputMask
                                name="BDay"
                                mask="99/99/9999"
                                placeholder="MM/DD/YYYY"
                                value={this.state.OEndDate}
                                className="form-control calendar-inputs-campsite border text-right pr-3"
                                onFocus={this.handleSEFocus}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <p className="col-7 ssinfo-label" style={{ marginLeft: 8 }}>
                        Location:
                      </p>
                    </div>
                    {this.bd_CamOpt()}
                  </div>
                  <div className="d-flex justify-content-center">
                    <a className="d-flex justify-content-center w-100">
                      <button
                        onClick={() => {
                          this.handleSearchClick();
                        }}
                        className="filled-button-campsite"
                      >
                        Search
                      </button>
                    </a>
                  </div>
                </div>
                <div>
                  {this.state.PagePos == 0 ? (
                    <div className="row">
                      <div className="ss_col_1"></div>
                      <div className="ss_col_8">
                        <div className="mx-3 my-2 text-center">
                          {this.state.ErrMsg}
                        </div>
                      </div>
                      <div className="ss_col_1"></div>
                    </div>
                  ) : this.state.PagePos == 3 ? (
                    <div>
                      <div>
                        <div>
                          <div className={this.props.isDesktop ? "campsite-selected" : "campsite-selected-mb"}>
                            <div className="row csvseled">
                              selected ( {this.state.C_MulSlots.length} )
                            </div>
                            {this.getSelectedSlots()}
                          </div>
                          <div className="tacenter">
                            <a>
                              <button
                                onClick={() => {
                                  this.handleRegisterClick(this.state.node);
                                }}
                                className="filled-button-campsite"
                              >
                                Reserve
                              </button>
                            </a>
                          </div>
                          <div className="tacenter">
                            <a>
                              <button
                                onClick={() => {
                                  this.handleCancelClick();
                                }}
                                className="border-button-campsite"
                              >
                                Back
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div id="cps_Control">
                        <div>
                          <div>
                            <div className="d-flex justify-content-center mt-2 mb-2">
                              <div className="ss_col_6">
                                <div className="slotseltxt">Selected({seled})</div>
                              </div>
                              <div className="ss_col_4">
                                <div className="slotfilter">
                                  <a>
                                    <button
                                      onClick={() => {
                                        this.handleFilterClick();
                                      }}
                                      className="filterbtn border-button"
                                    >
                                      {this.state.Filtertext}
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {this.state.sh_Filter == 0 ? (
                              <div></div>
                            ) : (
                              <div className="card ml-3 p-2">
                                <div className="row align-self-center">
                                  <div className="d-flex justify-content-center flex-column">
                                    <div className="ml-3">
                                      <Checkbox
                                        id="Power"
                                        label="Power Required"
                                        isSelected={this.state.Filter.Power}
                                        onCheckboxChange={
                                          this.handleFilterChange
                                        }
                                        key="Power"
                                      />
                                      <Checkbox
                                        id="Water"
                                        label="Water Required"
                                        isSelected={this.state.Filter.Water}
                                        onCheckboxChange={
                                          this.handleFilterChange
                                        }
                                        key="Water"
                                      />
                                      <Checkbox
                                        id="Sewer"
                                        label="Sewer Required"
                                        isSelected={this.state.Filter.Sewer}
                                        onCheckboxChange={
                                          this.handleFilterChange
                                        }
                                        key="Sewer"
                                      />
                                      <Checkbox
                                        id="Table"
                                        label="Table Required"
                                        isSelected={this.state.Filter.Table}
                                        onCheckboxChange={
                                          this.handleFilterChange
                                        }
                                        key="Table"
                                      />
                                      <Checkbox
                                        id="Fire"
                                        label="Fire Pit Required"
                                        isSelected={this.state.Filter.Fire}
                                        onCheckboxChange={
                                          this.handleFilterChange
                                        }
                                        key="Fire"
                                      />
                                    </div>
                                    <InputMask
                                      mask="999"
                                      className="form-control login-inputs"
                                      name="Length"
                                      placeholder="Minimum Length(feet)"
                                      value={this.state.Filter.Length}
                                      onChange={this.handleSelChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <ul
                                className="nav nav-pills nav-justified ml-3"
                                id="myTab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    onClick={() => {
                                      this.handlemaptabClick();
                                    }}
                                    className={`nav-link ${this.state.currentView === "map" && "active"}`}
                                    id="schedule-tab"
                                    data-toggle="tab"
                                    href="#map-info-tab"
                                    role="tab"
                                    aria-controls="schedule"
                                    aria-selected="true"
                                  >
                                    Map View
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    onClick={() => {
                                      this.handleListBackClick(1);
                                    }}
                                    className={`nav-link ${this.state.currentView === "list" && "active"}`}
                                    id="info-tab"
                                    data-toggle="tab"
                                    href="#list-info-tab"
                                    role="tab"
                                    aria-controls="info"
                                    aria-selected="false"
                                  >
                                    List View
                                  </a>
                                </li>
                              </ul>
                              <div className="tab-content" id="myTabContent">
                                <div
                                  className={`tab-pane fade ${this.state.currentView === "list" && "show active"}`}
                                  id="list-info-tab"
                                  role="tabpanel"
                                  aria-labelledby="info-tab"
                                >
                                  <ListView
                                    c_MapObjId={this.state.c_MapObjId}
                                    onUpClick={this.handleUpClick}
                                    sh_Continue={this.state.sh_Continue}
                                    Filter={this.state.Filter}
                                    C_MulSlots={this.state.C_MulSlots}
                                    onStartReserve={this.handleStartReserve}
                                    onSeledUpdate={this.handleSeledUpdate}
                                    smalltree={this.props.smalltree}
                                    OStartDate={this.state.OStartDate}
                                    OEndDate={this.state.OEndDate}
                                    StartDate={this.state.StartDate}
                                    EndDate={this.state.EndDate}
                                    c_CR_List={this.state.c_CR_List}
                                    c_CR_Listall={this.state.c_CR_Listall}
                                    c_CR_CampList={this.state.c_CR_CampList}
                                    c_MapInfo={this.state.c_MapInfo}
                                    onNextClick={this.handleNextClick}
                                    onPrevClick={this.handlePrevClick}
                                    onListBackClick={this.handleListBackClick}
                                  ></ListView>
                                </div>
                                <div
                                  className={`tab-pane fade ${this.state.currentView === "map" && "show active"}`}
                                  id="map-info-tab"
                                  role="tabpanel"
                                  aria-labelledby="schedule-tab"
                                >
                                  <MapView
                                    c_MapObjId={this.state.c_MapObjId}
                                    onUpClick={this.handleUpClick}
                                    sh_Infoicon2={this.state.sh_Infoicon2}
                                    Filter={this.state.Filter}
                                    C_MulSlots={this.state.C_MulSlots}
                                    onStartReserve={this.handleStartReserve}
                                    onSeledUpdate={this.handleSeledUpdate}
                                    smalltree={this.props.smalltree}
                                    StartDate={this.state.OStartDate}
                                    EndDate={this.state.OEndDate}
                                    c_CR_List={this.state.cM_CR_List}
                                    c_CR_Listall={this.state.cM_CR_Listall}
                                    c_CR_CampList={this.state.cM_CR_CampList}
                                    c_MapInfo={this.state.c_MapInfo}
                                    isDesktop={this.props.isDesktop}
                                  ></MapView>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.sh_Continue == 0 ? (
                            <div></div>
                          ) : (
                            <div>
                              <a className="d-flex justify-content-center mt-3 mb-3">
                                <button
                                  onClick={() => {
                                    this.handleContinueClick();
                                  }}
                                  className="filled-button-campsite"
                                >
                                  Continue
                                </button>
                              </a>
                            </div>
                          )}
                        </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <Modal isOpen={this.state.modal}>
              <ModalHeader>
                <h5>Announcement</h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.toggle}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <div className="anbox">
                  <div
                    className="orgwel"
                    dangerouslySetInnerHTML={{ __html: this.state.annmsg }}
                  ></div>
                </div>
              </ModalBody>
            </Modal>

            <Modal isOpen={this.state.PagePos == 4 ? true : false}>
              <ModalHeader>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => {
                    this.handleOkClick();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody scroll={false}>
                <div className="text-center p-2 mb-2">
                  {this.state.ErrMsg}
                </div>
              </ModalBody>
              <ModalFooter>
                <a
                  className={
                    "d-flex justify-content-center modal-buttom"
                  }
                 >
                  <button
                    onClick={() => {
                      this.handleOkClick();
                    }}
                    className="filled-button"
                  >
                    Okay
                  </button>
                </a>
              </ModalFooter>
            </Modal>
            <Alert
              message={this.state.alertMessage}
              show={this.state.showAlert}
              onClose={this.handleAlertClose}
            />
          </div>
        )}
      </div>
    );
  }
}

export default CampSite;
